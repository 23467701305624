// Соответствие демо-странцы Styleguidist к борду из Styleguide.
const figmaBoardToStyleguidistComponentMap: Record<string, string[]> = {
    //// Раздел Web
    // AccordionForm ru/en.
    '/#!/Components/AccordionForm?styleguideLive=true': ['37916:39291', '73747:3338'],
    // AccordionView.
    '/#!/Components/AccordionView?styleguideLive=true': ['35237:8', '73747:4829'],
    // Alerts.
    '/#!/Components/Alerts?styleguideLive=true': ['14376:31266', '73747:6112'],
    // Buttons.
    '/#!/Components/Buttons?styleguideLive=true': ['74225:10269', '73747:9097'],
    // Calendar.
    '/#!/Components/Calendar?styleguideLive=true': ['37926:51369', '73747:14198'],
    // Cards.
    '/#!/Components/Cards?styleguideLive=true': ['37926:57695', '73747:17338'],
    // Checkboxes.
    '/#!/Components/Checkboxes?styleguideLive=true': ['37934:63837', '73747:19502'],
    // Date Picker.
    '/#!/Components/DatePicker?styleguideLive=true': ['37934:66332', '73747:21955'],
    // Date Range.
    '/#!/Components/DateRange?styleguideLive=true': ['71620:40887', '73747:20679'],
    // Divider.
    '/#!/Components/Divider?styleguideLive=true': ['37937:68968', '73755:22921'],
    // Inputs.
    '/#!/Components/Inputs?styleguideLive=true': ['14699:34852', '73756:25564'],
    // Labels.
    '/#!/Components/Labels?styleguideLive=true': ['32949:1020', '73757:27986'],
    // LightBox.
    '/#!/Components/LightBox/LightBox?styleguideLive=true': ['72283:43721', '73757:29265'],
    // Links.
    '/#!/Components/Link?styleguideLive=true': ['15930:28210', '73757:30439'],
    // MarkerStatus.
    '/#!/Components/MarkerStatus?styleguideLive=true': ['34708:1', '73757:31045'],
    // ModalWindow.
    '/#!/Components/ModalWindow/ModalWindow?styleguideLive=true': ['24603:39596', '73758:32280'],
    // Multiselect.
    '/#!/Components/Multiselect?styleguideLive=true': ['35665:25153', '73758:34460'],
    // Notification Business. Notification System. ru, en.
    '/#!/Components/Notification?styleguideLive=true': ['73038:19648', '15442:1157', '73759:36739'],
    // Page.
    '/#!/Components/Page?styleguideLive=true': ['37197:37261', '73759:38849'],
    // PageHeader.
    '/#!/Components/HeaderPage?styleguideLive=true': ['14389:31970', '73756:26865'],
    // PageFooter.
    '/#!/Components/FooterPage?styleguideLive=true': ['14962:33211', '73756:24524'],
    // Radio Group.
    '/#!/Components/Radios?styleguideLive=true': ['30932:2890', '73759:39382'],
    // SegmentedControl.
    '/#!/Components/SegmentedControl?styleguideLive=true': ['72985:766', '73759:40250'],
    // Selects.
    '/#!/Components/Selects?styleguideLive=true': ['34678:19084', '73759:41407'],
    // Spoiler.
    '/#!/Components/Spoiler?styleguideLive=true': ['34733:20225', '73759:42923'],
    // Suggest.
    '/#!/Components/Suggest?styleguideLive=true': ['34698:1', '73759:47036'],
    // Table.
    '/#!/Components/TableBasic?styleguideLive=true': ['32879:0'],
    // Tabs.
    '/#!/Components/Tabs?styleguideLive=true': ['38038:37281', '73759:48735'],
    // TabsFolder.
    '/#!/Components/TabsFolder?styleguideLive=true': ['37903:38724', '73759:49603'],
    // Tag.
    '/#!/Components/Tag?styleguideLive=true': ['34676:1', '73759:51281'],
    // TextField.
    '/#!/Components/TextField?styleguideLive=true': ['72168:47453', '73759:52707'],
    // Tooltip.
    '/#!/Components/Tooltip?styleguideLive=true': ['14964:33366', '73759:53976'],
    // Widget.
    '/#!/Components/Widget?styleguideLive=true': ['74121:11063', '73782:40102'],
    // Stepper.
    '/#!/Components/Stepper?styleguideLive=true': ['70226:38416', '73759:45652'],
    // StatusTracker.
    '/#!/Components/StatusTracker?styleguideLive=true': ['70446:45635', '73759:43907'],
    // UploadZone.
    '/#!/Components/UploadZone?styleguideLive=true': ['36264:0', '73777:3917'],
    //// Раздел Super
    // Illustrations.
    '/#!/Icons%20and%20Illustrations/Illustrations?styleguideLive=true': ['34939:12018'],
    // Icon Set. Design, Types, Principles.
    '/#!/Icons%20and%20Illustrations/Icons?styleguideLive=true': ['34751:0', '34866:11691', '34864:2'],
    //
    // Skeleton.
    '/#!/Components/Skeleton?styleguideLive=true': ['36285:12082'],
    // Sliders.                       ? / web ru / web rn
    '/#!/Slider?styleguideLive=true': ['37099:32657', '73759:41957']
}

const LivePreviewUtils = {
    styleguidistHost: process.env.REACT_APP_STYLEGUIDIST_HOST,

    // Возвращает путь демо-страницы для борда.
    getLiveDemoPathFromBoardId: (boardId: string): string | undefined => {
        let path;
        Object.keys(figmaBoardToStyleguidistComponentMap).some(key => {
            if (figmaBoardToStyleguidistComponentMap[key].includes(boardId)) {
                path = key;
                return true;
            }
        })

        return path;
    }
}

export default LivePreviewUtils;
