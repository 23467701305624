import React from 'react';

export const SearchIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
    <svg width="20" height="20" {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0114 14.6333L19.7192 18.3139C20.0936 18.7016 20.0936 19.3165 19.7192 19.7042C19.5315 19.8935 19.2761 20 19.0096 20C18.7431 20 18.4877 19.8935 18.3 19.7042L14.6222 16.0235C10.8961 19.0143 5.48392 18.5687 2.29619 15.0089C-0.891539 11.4491 -0.742999 6.01654 2.63449 2.63649C6.01198 -0.743563 11.4404 -0.892216 14.9975 2.29794C18.5546 5.48809 18.9998 10.9044 16.0114 14.6333ZM2.01971 9.0224C2.01971 12.889 5.15186 16.0235 9.01555 16.0235C10.871 16.0235 12.6504 15.2859 13.9624 13.973C15.2743 12.66 16.0114 10.8792 16.0114 9.0224C16.0114 5.15577 12.8792 2.02125 9.01555 2.02125C5.15186 2.02125 2.01971 5.15577 2.01971 9.0224Z"
        />
    </svg>
);
