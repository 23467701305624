import React from 'react';
import {useHistory} from 'react-router-dom';
import {TabItem} from './TabItem';
import {EChannel} from '../../types/types';
import {useTranslation} from 'react-i18next';
import {EAppLanguage} from "../LanguageControl/LanguageControl";
import './channelSwitcher.scss';

const options = [
    {id: EChannel.super, label: EChannel.super},
    {id: EChannel.web, label: EChannel.web},
    {id: EChannel.mobile, label: EChannel.mobile},
    {id: EChannel.desktop, label: EChannel.desktop},
];

/**
 * @prop {StyleguideActions} actions Экшны для управления стором стайлгайда.
 */
interface IChannelSwitcherProps {
    routeTitle: string;
}

const ChannelSwitcher: React.FC<IChannelSwitcherProps> = ({routeTitle}) => {
    const history = useHistory();
    const {i18n} = useTranslation();

    const handleSelect = (nextChannelTitle: string) => () => {
        let pathname = ``;
        // Постфикс для непереведенных разделов.
        let postfix = '';

        switch (nextChannelTitle) {
            case EChannel.web:
                pathname = `/${EChannel.web}`;
                break;
            case EChannel.super:
                pathname = `/${EChannel.super}`;
                break;
            case EChannel.mobile:
                pathname = `/${EChannel.mobile}`;
                break;
            case EChannel.desktop: {
                if (i18n.language === EAppLanguage.en) {
                    pathname = `/${EChannel.desktop}`;
                    postfix = `/sectionNotTranslated`;
                } else {
                    pathname = `/${EChannel.desktop}/Hello!/release notes`;
                }
                break;
            }
        }

        history.push({
            pathname: `/${i18n.language}${pathname}${postfix}`,
            search: document.location.search,
        });
    };

    return (
        <div className="channelSwitcher">
            <div className="tabs">
                <div className="inlineTabs">
                    {options.map((option) => {
                        const isSelected = option.id === routeTitle;
                        return (
                            <TabItem
                                key={option.id}
                                isSelected={isSelected}
                                id={option.id}
                                label={option.label}
                                onSelect={handleSelect(option.id)}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ChannelSwitcher;
