import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "footer contacts": "Contact us",

            "inner page copyright": "© 1997—{{dateEnd}} PJSC Sberbank.",

            "main h1": "Experience.<br />Design.<br />Platform.",
            "main description": "Welcome to the Triplex Design System.<br />This is where your ideas will<br />take shape.",
            "main copyright": "© 1997—{{dateEnd}} PJSC Sberbank.<br />General license for banking operations<br />dated August 11, 2015. Registration number - 1481.",

            "menu to home": "Home",

            "page not found": "Page not found",
            "page not found link": "Home",

            "section not translated": "This page has not been translated yet",
            "section not translated link": "Back",

            "search not found": "Nothing found. <br />Try editing your query.",
            "search placeholder": "Search",
            "search result item nodes count pre": "And",
            "search result item nodes count": "(1)[more match];(2-inf)[more matches];",
            "search result item nodes count post": "on the page.",
            "search two characters": "Type in at least two characters",
        }
    },
    ru: {
        translation: {
            "footer contacts": "Написать нам",

            "inner page copyright": "© 1997—{{dateEnd}} ПАО Сбербанк.",

            "main h1": "Опыт.<br />Дизайн.<br />Платформа.",
            "main description": "Добро пожаловать<br />в Дизайн-систему Триплекс.<br />Здесь твои идеи<br />обретут форму.",
            "main copyright": "© 1997—{{dateEnd}} ПАО Сбербанк.<br />Генеральная лицензия на осуществление банковских операций<br />от 11 августа 2015 года. Регистрационный номер — 1481.",

            "menu to home": "Главная",

            "page not found": "Такой страницы не существует",
            "page not found link": "На главную",

            "section not translated": "Эта страница в процессе перевода",
            "section not translated link": "Назад",

            "search not found": "Ничего не найдено.<br />Попробуйте изменить запрос.",
            "search placeholder": "Что вы ищете?",
            "search result item nodes count pre": "И еще",
            "search result item nodes count": "(0)[совпадений];(1)[совпадение];(2-4)[совпадения];(5-inf)[совпадений];",
            "search result item nodes count post": "на странице.",
            "search two characters": "Введите более двух символов",
        }
    }
};

i18n
    .use(intervalPlural)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
