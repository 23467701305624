// Тип ресурса Triplex.
export enum ETriplexResource {
    demoUi = 'demoUi',
    figma = 'figma',
    pixso = 'pixso',
    github = 'github'
}

// Ссылка на ресурс Triplex.
export interface ITriplexResource {
    href: string;
    title: string;
    type: ETriplexResource;
}