import React, {useContext, useEffect, useState} from 'react';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {useLocation} from 'react-router-dom';
import {Logo} from '../Logo/Logo';
import LogoIcon from '../Logo/LogoIcon';
import {EAppTheme, ThemeContext} from '../ThemeSwitcher/ThemeSwitcher';
import {observer} from 'mobx-react';
import Footer from '../Footer/Footer';
import Burger from '../Burger/Burger';
import LogoSberIcon from '../Logo/LogoSberIcon';
import BackTop from '../BackTop/BackTop';
import ThemeControl from '../ThemeControl/ThemeControl';
import Search from "../Search/Search";
import LanguageControl, {ELanguageControlTheme} from "../LanguageControl/LanguageControl";
import {EChannel} from "../../types/types";
import './layout.scss';

interface ILayoutProps {
    // Название файла.
    fileTitle?: EChannel;
    menu?: React.ReactElement;
}

const Layout: React.FC<ILayoutProps> = observer(({children, fileTitle, menu}) => {
    const themeContext = useContext(ThemeContext);
    // Сайдбар на разрешении мобильных устройств скрыт.
    const [isMobileSidebarHidden, setIsMobileSidebarHidden] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setIsMobileSidebarHidden(true);
    }, [location]);

    useEffect(() => {
        if (isMobileSidebarHidden) {
            enableBodyScroll(document.body);
        } else {
            disableBodyScroll(document.body);
        }
    }, [isMobileSidebarHidden, setIsMobileSidebarHidden])

    return (
        <div className={`layout ${isMobileSidebarHidden ? '' : 'layout-mobile-menu-open'}`}>
            <aside className="sidebar">
                <div className={'burger-logo'}>
                    <Burger isMobileSidebarHidden={isMobileSidebarHidden}
                            setIsMobileSidebarHidden={setIsMobileSidebarHidden}/>
                    <Logo isLink logoIcon={<LogoIcon className="logo-icon" theme={themeContext.theme}/>}/>

                    <div className="sidebar-theme-control">
                        <ThemeControl/>
                    </div>
                </div>

                <div className={`sidebar-content ${isMobileSidebarHidden ? 'hidden' : ''}`}>
                    {menu}
                </div>

                <div className={`sidebar-mobile-lang-switcher ${isMobileSidebarHidden ? 'hidden' : ''}`}>
                    <LanguageControl theme={ELanguageControlTheme.inner} />
                </div>

                <div className="sidebar-logo-wrapper">
                    <LogoSberIcon theme={EAppTheme.DEFAULT}/>
                </div>
            </aside>
            <main className="content">
                <div className="content-head">
                    <Search/>

                    <LanguageControl theme={ELanguageControlTheme.inner} />

                    <div className="content-head-controls">
                        <ThemeControl/>
                    </div>
                </div>

                <div className="content-inner">{children}</div>

                <BackTop/>

                <Footer/>
            </main>
        </div>
    );
});

export default Layout;


