import {action, makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {IRoute, IStyleguideRoute} from '../types/types';
import {IRootStore} from './RootStore';
import {channelMainPageFigmaIds} from './consts';

/** Возвращает карту роутов и идентификаторов досок из Figma. */
export const getMapRoutAndBoardId = (routes: IStyleguideRoute[], lang?: string): {
    [route: string]: IRoutesAndBoardsMapItem
} => {
    const map: {
        [route: string]: IRoutesAndBoardsMapItem
    } = {};
    const stack = [...routes];

    const langPrefix = lang ? '/' + lang.toLowerCase() : '';

    while (stack.length > 0) {
        const currentObj: IStyleguideRoute = stack.shift()!;
        if (currentObj.ID) {
            map[langPrefix + '/' + currentObj.FULL_PATH] = {
                id: currentObj.ID,
                figmaId: currentObj.FIGMA_ID!,
                routeLangId: currentObj.LANG_ID,
            };

            /**
             * Кастомное добавление заглавных страниц раздела.
             * "214:137" = Desktop.
             * "70611:37008" = Web.
             * "69567:19401" = Super Ru.
             * "71037:18891" = Super En.
             * "42875:17109" = Mobile.
             */
            if (["214:137", ...channelMainPageFigmaIds].includes(currentObj.FIGMA_ID!)) {

                map[langPrefix + '/' + currentObj.FULL_PATH.split('/')[0]] = {
                    id: currentObj.ID,
                    figmaId: currentObj.FIGMA_ID!,
                    routeLangId: currentObj.LANG_ID,
                };

                // Добавление в роуты.
               routes.push({
                   "ID": currentObj.ID,
                   "FIGMA_ID": currentObj.FIGMA_ID,
                   "LANG_ID": currentObj.LANG_ID,
                   "NAME": "",
                   "PATH": "",
                   // Название канала, например Web.
                   "FULL_PATH": currentObj.FULL_PATH.split('/')[0]
               });
            }
        }
        if (currentObj.CHILDREN) {
            stack.unshift(...currentObj.CHILDREN);
        }
    }
    return map;
}

interface IRoutesAndBoardsMapItem {
    id: string,
    figmaId: string,
    routeLangId?: string,
}

export interface IRoutesStore {
    routes: {
        [channelId: string]: IRoute;
    };
    /**
     * Карта роутов и идентификаторов досок из Figma.
     */
    routesAndBoardsMap: {
        [route: string]: IRoutesAndBoardsMapItem
    };
    /**
     * Загружает роуты.
     */
    loadRoute: (id: string, lang?: string) => void;
    /**
     * Обработчик успешной загрузки роутов.
     */
    loadRouteSuccess: (id: string, lang?: string) => (route: IRoute) => void;
    rootStore: IRootStore;
}

class RoutesStore implements IRoutesStore {
    routes: {
        [id: string]: IRoute;
    } = {};
    routesAndBoardsMap: {
        [route: string]: IRoutesAndBoardsMapItem
    }= {};
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    loadRoute = (id: string, lang?: string): void => {
        api.getRoute(id)
            .then(action(this.loadRouteSuccess(id, lang)))
            .catch(e => this.rootStore.errorsStore.addError(e));
    };

    loadRouteSuccess = (id: string, lang?: string) => (route: IRoute): void => {
        const routesMap = getMapRoutAndBoardId(route.routes, lang);

        this.routes[id] = route;
        this.routesAndBoardsMap = Object.assign(this.routesAndBoardsMap, routesMap);
    };
}

export default RoutesStore;
