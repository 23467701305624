import React from 'react';

export interface IRUInnerIconProps {
    className: string;
}

export const RUInnerIcon: React.FC<IRUInnerIconProps> = ({className}) => (
    <svg className={className} width="30" height="22" viewBox="0 0 30 22" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="28.5" height="20.5" rx="3.25" strokeWidth="1.5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8.74219 6.99219H10.3332C10.9663 6.99219 11.7764 7.14649 12.4544 7.57024C13.1651 8.01446 13.735 8.76671 13.735 9.86359C13.735 10.9605 13.1651 11.7127 12.4544 12.1569C12.3384 12.2294 12.2186 12.294 12.0965 12.3512L13.2219 13.7579C13.4806 14.0814 13.4282 14.5533 13.1048 14.8121C12.7813 15.0709 12.3093 15.0184 12.0506 14.695L10.4804 12.7323C10.4304 12.7341 10.3813 12.735 10.3332 12.735H9.49219V14.2245C9.49219 14.6387 9.1564 14.9745 8.74219 14.9745C8.32797 14.9745 7.99219 14.6387 7.99219 14.2245V11.985V7.74219C7.99219 7.32797 8.32797 6.99219 8.74219 6.99219ZM9.49219 8.49219V11.235H10.3332C10.7609 11.235 11.2767 11.1241 11.6594 10.8849C12.0093 10.6662 12.235 10.3578 12.235 9.86359C12.235 9.36942 12.0093 9.06096 11.6594 8.84224C11.2767 8.60306 10.7609 8.49219 10.3332 8.49219H9.49219ZM16.4668 7.00391C16.881 7.00391 17.2168 7.33969 17.2168 7.75391V11.9427C17.2168 12.8076 17.9179 13.5087 18.7828 13.5087C19.6476 13.5087 20.3487 12.8076 20.3487 11.9427V7.75391C20.3487 7.33969 20.6845 7.00391 21.0987 7.00391C21.513 7.00391 21.8487 7.33969 21.8487 7.75391V11.9427C21.8487 13.636 20.4761 15.0087 18.7828 15.0087C17.0895 15.0087 15.7168 13.636 15.7168 11.9427V7.75391C15.7168 7.33969 16.0526 7.00391 16.4668 7.00391Z"
        />
    </svg>
);
