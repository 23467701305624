import {makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {IInnerLink} from '../types/types';
import {IRootStore} from './RootStore';

export interface IInnerLinksStore {
    innerLinks: IInnerLink[];
    loadInnerLinks: () => void;
    loadInnerLinksSuccess: (innerLinks: IInnerLink[]) => void;
    rootStore: IRootStore;
}

class InnerLinksStore implements IInnerLinksStore {
    innerLinks: IInnerLink[]= [];
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    loadInnerLinks = (): void => {
        api.getInnerLinks()
            .then(this.loadInnerLinksSuccess)
            .catch(e => this.rootStore.errorsStore.addError(e));
    }

    loadInnerLinksSuccess = (innerLinks: IInnerLink[]): void => {
        this.innerLinks = innerLinks;
    }
}

export default InnerLinksStore;
