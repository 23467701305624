import React from 'react';
import {useTranslation} from "react-i18next";
import {EAppLanguage} from "../LanguageControl/LanguageControl";

export const LogoSberThemeWhite = (): React.ReactElement => {
        const {i18n} = useTranslation();

        return i18n.language === EAppLanguage.ru ? (
            <svg width="89" height="24" viewBox="0 0 89 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M39.2522 16.7728C40.2124 16.7728 41.1433 16.5391 41.9423 16.0978L42.6333 15.7202L44.6499 17.2076C43.3203 18.6346 41.3585 19.4184 39.1116 19.4184C36.9639 19.4184 35.0053 18.6592 33.5963 17.2807C32.1921 15.9071 31.4185 14.0031 31.4185 11.9203C31.4185 9.84706 32.1944 7.97095 33.6042 6.6378C35.0053 5.3118 36.9687 4.58203 39.1314 4.58203C41.125 4.58203 42.8692 5.18859 44.1741 6.33651L44.3219 6.46609L42.0868 8.11484C41.268 7.52577 40.3149 7.22687 39.2522 7.22687C36.4429 7.22687 34.4033 9.23414 34.4033 11.9998C34.4033 14.7655 36.4421 16.7728 39.2522 16.7728ZM54.2015 10.4063H50.409V7.17797H56.4635L59.7263 4.77082H47.2923V19.2272H53.7996C57.4492 19.2272 59.5429 17.5721 59.5429 14.6856C59.5429 11.9263 57.6454 10.4063 54.2015 10.4063ZM53.659 16.8201H50.409V12.8127H53.659C55.6375 12.8127 56.5588 13.4828 56.5588 14.816C56.558 16.1785 55.5827 16.8201 53.659 16.8201ZM73.3652 4.77082L70.1025 7.17797H65.5609V10.7124H72.2176V13.1195H65.5609V16.8201H73.3652V19.2272H62.4443V4.77082H73.3652ZM76.2665 4.77082H82.2123C86.0072 4.77082 88.3638 6.75346 88.3638 9.94443C88.3638 13.1354 86.0064 15.1324 82.2123 15.1324H79.3832V19.2272H76.2665V4.77082ZM79.3832 12.7252H82.1456C84.1431 12.7252 85.2424 11.7403 85.2424 9.95159C85.2424 8.16292 84.1423 7.17797 82.1456 7.17797H79.3832V12.7252Z" fill="#7D838A"/>
                <path d="M2.77149 12C2.77149 11.8451 2.77546 11.6918 2.7834 11.5386L0.0150885 11.4004C0.00555906 11.5989 0 11.799 0 12C0 15.3138 1.34286 18.314 3.51479 20.4852L5.47786 18.5221C3.80623 16.8513 2.77149 14.5443 2.77149 12Z" fill="#7D838A"/>
                <path d="M11.9994 2.77229C12.1542 2.77229 12.3075 2.77784 12.4608 2.78499L12.5989 0.0150885C12.4004 0.00555906 12.2003 0 11.9994 0C8.6855 0 5.68529 1.34286 3.51416 3.51479L5.47723 5.47786C7.14807 3.80703 9.4558 2.77229 11.9994 2.77229Z" fill="#7D838A"/>
                <path d="M11.9995 21.2283C11.8446 21.2283 11.6913 21.2244 11.5381 21.2164L11.3999 23.9847C11.5984 23.9943 11.7986 23.9998 11.9995 23.9998C15.3133 23.9998 18.3135 22.657 20.4847 20.485L18.5216 18.522C16.8508 20.1928 14.5438 21.2283 11.9995 21.2283Z" fill="#7D838A"/>
                <path d="M17.2013 4.38277L19.5353 2.6627C17.4753 0.998213 14.8539 0 11.999 0V2.77229C13.9279 2.77229 15.7195 3.36708 17.2013 4.38277Z" fill="#7D838A"/>
                <path d="M23.9992 11.9998C23.9992 11.266 23.9333 10.5473 23.807 9.8501L21.2237 11.7536C21.2261 11.8354 21.2269 11.9172 21.2269 11.9998C21.2269 14.7093 20.0532 17.1497 18.187 18.8396L20.0492 20.8979C22.4753 18.703 23.9992 15.5297 23.9992 11.9998Z" fill="#7D838A"/>
                <path d="M11.9992 21.2284C9.28968 21.2284 6.84934 20.0547 5.15944 18.1885L3.10107 20.0507C5.29683 22.4759 8.47015 23.9999 11.9992 23.9999V21.2284Z" fill="#7D838A"/>
                <path d="M5.8114 5.16042L3.94918 3.10205C1.52392 5.2978 0 8.47112 0 12.0002H2.77229C2.77149 9.29066 3.94521 6.85031 5.8114 5.16042Z" fill="#7D838A"/>
                <path d="M21.4951 4.66406C22.0621 5.39624 22.5465 6.19593 22.9332 7.04961L11.999 15.1076L7.43115 12.244V8.79906L11.999 11.6627L21.4951 4.66406Z" fill="#7D838A"/>
            </svg>
        ) : (
            <svg width="91" height="24" viewBox="0 0 91 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.9871 10.7474C36.2954 10.3555 34.8346 10.0164 34.8346 8.85724C34.8346 7.30303 36.7807 7.13508 37.6173 7.13508C38.7772 7.13508 39.4366 7.292 40.3006 7.67759C40.7201 7.374 42.6324 5.98854 42.6324 5.98854C41.3714 5.19921 39.4783 4.58179 37.6382 4.58179C33.2512 4.58179 31.6877 6.87565 31.6877 9.02205C31.6877 12.1131 34.4223 12.7566 36.8344 13.3251C38.5294 13.7241 39.9925 14.0687 39.9925 15.2468C39.9925 15.8516 39.6259 16.8649 37.1689 16.8649C35.5381 16.8649 33.7525 16.3018 32.4843 15.3927L31.4182 17.7898C32.7105 18.7802 34.9517 19.4182 37.1489 19.4182C40.7883 19.4182 43.1402 17.6834 43.1402 14.9984C43.1386 11.9412 40.4016 11.3072 37.9871 10.7474Z" fill="#7D838A"/>
                <path d="M56.4665 11.7749L56.3157 11.726L56.4552 11.6519C57.6561 11.0116 58.3171 9.9013 58.3171 8.52688C58.3171 6.21252 56.3213 4.83099 52.9787 4.83099H46.0688V19.1706H53.3862C57.0264 19.1706 59.0302 17.7922 59.0302 15.2886C59.031 13.5846 58.0965 12.304 56.4665 11.7749ZM49.2165 7.2179H52.5704C54.6849 7.2179 55.1285 8.17598 55.1285 8.97082C55.1285 10.1245 54.2437 10.7238 52.5704 10.7238H49.2165V7.2179ZM49.2165 10.7238H52.5704H49.2165ZM53.1423 16.7821H49.2173V13.1107H53.1423C54.9343 13.1107 55.8432 13.7218 55.8432 14.9464C55.8432 16.1749 54.9592 16.7821 53.1423 16.7821Z" fill="#7D838A"/>
                <path d="M69.6957 7.2179L72.9918 4.8302H61.962V19.1698H72.9918V16.7821H65.1097V13.1114H71.8326V10.7237H65.1097V7.2179H69.6957Z" fill="#7D838A"/>
                <path d="M88.3636 19.1698L85.159 14.5111C87.0497 13.7005 88.139 12.1029 88.139 9.96201C88.139 6.79682 85.7582 4.8302 81.9262 4.8302H75.9213V19.1698H79.0689V15.108H81.9262C82.0161 15.108 82.1043 15.1057 82.1926 15.1033L84.9897 19.1698H88.3636ZM79.0698 7.2179H81.8597C83.8763 7.2179 84.9873 8.1949 84.9873 9.96911C84.9873 11.7433 83.8763 12.7203 81.8597 12.7203H79.0698V7.2179Z" fill="#7D838A"/>
                <path d="M11.9999 11.6625L7.43213 8.79888V12.2438L11.9999 15.1074L22.9342 7.04943C22.5475 6.19654 22.0631 5.39685 21.4961 4.66388L11.9999 11.6625Z" fill="#7D838A"/>
                <path d="M24 12C24 11.2662 23.9341 10.5475 23.8078 9.85031L21.2245 11.7538C21.2269 11.8356 21.2277 11.9174 21.2277 12C21.2277 17.0879 17.0879 21.2277 12 21.2277C6.91205 21.2277 2.77229 17.0879 2.77229 12C2.77229 6.91205 6.91205 2.77229 12 2.77229C13.9281 2.77229 15.7205 3.36708 17.2023 4.38277L19.5362 2.6627C17.4763 0.998213 14.8549 0 12 0C5.37225 0 0 5.37225 0 12C0 18.6278 5.37225 24 12 24C18.6278 24 24 18.6278 24 12Z" fill="#7D838A"/>
            </svg>
        );
}
