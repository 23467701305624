import React from 'react';

export interface IChannelIconProps {
    className: string;
}

export const ChannelSuperIcon: React.FC<IChannelIconProps> = ({className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#FF6464" />
        <path
            d="M11.9015 17.182C14.2535 17.182 15.3035 16.132 15.3035 14.242C15.3035 10.63 10.7115 11.736 10.7115 9.72C10.7115 9.048 11.0615 8.684 12.3215 8.684C13.2735 8.684 14.2675 8.978 14.8835 9.454V7.508C14.2955 7.228 13.3155 7.018 12.2375 7.018C9.87145 7.018 8.86345 8.026 8.86345 9.818C8.86345 13.276 13.4555 12.212 13.4555 14.34C13.4555 15.068 13.0075 15.516 11.8175 15.516C10.4595 15.516 9.43745 14.97 8.93345 14.522V16.468C9.56345 16.86 10.6555 17.182 11.9015 17.182Z"
            fill="#1F1F22"
        />
    </svg>
);
