import React from 'react';
import {observer} from 'mobx-react';
import {ETriplexResource, ITriplexResource} from "./types";
import GitHubIcon from "./icons/GitHubIcon";
import PixsoIcon from "./icons/PixsoIcon";
import FigmaIcon from "./icons/FigmaIcon";
import DemoUIIcon from "./icons/DemoUIIcon";

interface ITriplexResourceProps extends React.HTMLAttributes<HTMLAnchorElement>{
    item: ITriplexResource;
}

/**
 * Возвращает иконку внешнего ресурса.
 */
const getResourceIcon = (iconType: ETriplexResource) => {
    switch (iconType) {
        case ETriplexResource.demoUi:
            return <DemoUIIcon/>;
        case ETriplexResource.figma:
            return <FigmaIcon/>;
        case ETriplexResource.github:
            return <GitHubIcon/>;
        case ETriplexResource.pixso:
            return <PixsoIcon/>;
    }
}

/**
 * Ссылка на ресурс Triplex.
 */
const TriplexResource: React.FC<ITriplexResourceProps> = observer(({item, ...rest}) => (
    <a className="triplex-resource" href={item.href} title={item.title} target="_blank" rel="noreferrer" {...rest}>
        {getResourceIcon(item.type)}
    </a>
));

export default TriplexResource;
