import React from 'react';

export interface IENInnerIconProps {
    className: string;
}

export const ENInnerIcon: React.FC<IENInnerIconProps> = ({className}) => (
    <svg className={className} width="30" height="22" viewBox="0 0 30 22" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="28.5" height="20.5" rx="3.25" strokeWidth="1.5"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M16.8549 7.27159C16.6532 7.02811 16.3205 6.93727 16.0231 7.04446C15.7256 7.15166 15.5273 7.43387 15.5273 7.75004V14.241C15.5273 14.6552 15.8631 14.991 16.2773 14.991C16.6916 14.991 17.0273 14.6552 17.0273 14.241V9.83107L21.0769 14.7195C21.2786 14.9629 21.6113 15.0538 21.9087 14.9466C22.2062 14.8394 22.4044 14.5572 22.4044 14.241V7.75004C22.4044 7.33583 22.0686 7.00004 21.6544 7.00004C21.2402 7.00004 20.9044 7.33583 20.9044 7.75004V12.16L16.8549 7.27159ZM8.75 7.00004C8.55109 7.00004 8.36032 7.07906 8.21967 7.21971C8.07902 7.36036 8 7.55113 8 7.75004V10.9955V14.241C8 14.6552 8.33579 14.991 8.75 14.991H13.0517C13.4659 14.991 13.8017 14.6552 13.8017 14.241C13.8017 13.8268 13.4659 13.491 13.0517 13.491H9.5V11.7455H10.9008C11.3151 11.7455 11.6508 11.4097 11.6508 10.9955C11.6508 10.5813 11.3151 10.2455 10.9008 10.2455H9.5V8.50004H13.0517C13.4659 8.50004 13.8017 8.16425 13.8017 7.75004C13.8017 7.33583 13.4659 7.00004 13.0517 7.00004H8.75Z"
        />
    </svg>
);
