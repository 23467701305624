import React from 'react';

const PixsoIcon: React.FC = () => (
    <svg width="30.000000" height="30.000000" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip71252_90188">
                <rect id="Frame 505" rx="15.000000" width="30.000000" height="30.000000" fill="white" fillOpacity="0"/>
            </clipPath>
        </defs>
        <rect id="Frame 505" rx="15.000000" width="30.000000" height="30.000000" fill="#2E2F36"
              fillOpacity="1.000000"/>
        <g clipPath="url(#clip71252_90188)">
            <path id="Vector"
                  d="M14.31 13.45L11.48 10.32C11.75 9.76 12.13 9.24 12.6 8.76C13.76 7.58 15.17 7 16.81 7C18.46 7 19.86 7.58 21.02 8.76C22.17 9.93 22.75 11.34 22.75 13C22.75 14.65 22.17 16.06 21.02 17.23C19.86 18.41 18.46 19 16.81 19L16.81 15.55C17.5 15.55 18.08 15.31 18.57 14.81C19.06 14.31 19.31 13.71 19.31 13C19.31 12.28 19.06 11.68 18.57 11.18C18.08 10.68 17.5 10.44 16.81 10.44C16.13 10.44 15.54 10.68 15.05 11.18C14.56 11.68 14.31 12.28 14.31 13L14.31 13.45Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M10.87 15.93L10.87 23L11.13 23C12.89 23 14.31 21.57 14.31 19.81L14.31 18.87C13.86 18.68 13.4 18.38 12.92 17.97C12.07 17.25 11.39 16.56 10.87 15.93Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M7.59 10.51L7 11.05L11.83 16.38L12.58 17.21L12.6 17.23C13.76 18.41 15.17 19 16.81 19C18.46 19 19.86 18.41 21.02 17.23C22.17 16.06 22.75 14.65 22.75 13L19.31 13C19.31 13.71 19.06 14.32 18.57 14.82C18.08 15.31 17.5 15.56 16.81 15.56C16.14 15.56 15.56 15.32 15.07 14.84L12.77 12.29L12.77 12.29L11.32 10.69C10.34 9.61 8.67 9.53 7.59 10.51Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
        </g>
    </svg>
);

export default PixsoIcon;