import React from 'react';
import {EAppTheme} from '../ThemeSwitcher/ThemeSwitcher';
import {LogoThemeDark} from './LogoThemeDark';
import {LogoThemeWhite} from './LogoThemeWhite';

interface ILogoIconProps {
    className: string;
    theme: EAppTheme;
}

const LogoIcon: React.FC<ILogoIconProps> = ({className, theme}) => theme === EAppTheme.DEFAULT ? <LogoThemeWhite className={className} /> : <LogoThemeDark className={className} />;

export default LogoIcon;
