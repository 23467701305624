import React from 'react';
import {IChannelIconProps} from './ChannelSuperIcon';

export const ChannelWebIcon: React.FC<IChannelIconProps> = ({className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#22FF88" />
        <path
            d="M8.03527 17H10.2613L11.6473 10.966C11.8293 10.21 11.9553 9.468 11.9553 9.468H11.9973C11.9973 9.468 12.1513 10.21 12.3193 10.966L13.7333 17H15.9593L17.9473 7.2H16.1133L14.9653 13.01C14.8533 13.612 14.7973 13.892 14.7273 14.494H14.6853C14.5593 13.892 14.5173 13.612 14.3773 13.01L13.0193 7.2H10.9893L9.65927 13.01C9.51927 13.612 9.46327 13.892 9.37927 14.494H9.32327C9.23927 13.892 9.21127 13.612 9.08527 13.01L7.95127 7.2H6.04727L8.03527 17Z"
            fill="#1F1F22"
        />
    </svg>
);
