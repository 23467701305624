import React from 'react';
import {Node} from 'figma-api';
import {convertColor, convertStyles} from '../../../utils/StyleguideUtils';
import {IFigmaLink} from '../../../types/types';
import {observer} from 'mobx-react';
import {FigmaLink} from './FigmaLink';
import {toJS} from 'mobx';
import {SEARCH_QUERY_PARAM_TITLE} from "../../Search/consts";

export interface IBoardTextProps {
    id: string;
    textBlock: Node<'TEXT'>;
    // Возвращает только текст, без css стилей.
    pureText?: boolean;
}

// Выделяет часть текста, на основании query параметра.
const highlightSearchText = (text: string): React.ReactNode => {
    const queryParams = new URLSearchParams(document.location.search);
    // Значение параметра query. Если существует, этот текст выделяется цветом.
    const queryParam = queryParams.get(SEARCH_QUERY_PARAM_TITLE);

    // Есть параметр со значением, которое нужно выделить в тексте.
    if (queryParam) {
        const regex = new RegExp(`(${queryParam})`, 'gi');

        // Выделяемое значение содержится в текущем тексте.
        if (text.search(regex) !== -1) {
            const nextText: string | JSX.Element = text.replace(regex, '<span class="search-result-item-selection">$1</span>');
            return (
                <span className="search-result-item-part" dangerouslySetInnerHTML={{
                    __html: nextText
                }}/>
            )
        }
    }

    return text;
}

const styleSubstring = (start: number, end: number, text: string, styles: React.CSSProperties, color: any, link: IFigmaLink | null) => {
    const newText = text.substring(start, end);
    //не склеиваем пробелы
    newText.replace(/ /g, '\u00a0');

    return link ? (
        <FigmaLink key={start} style={{...styles, color}} link={link}>
            {highlightSearchText(newText)}
        </FigmaLink>
    ) : (
        <span className="display-linebreak" key={start} style={{...styles, color}}>
             {highlightSearchText(newText)}
        </span>
    );
};

export const BoardText: React.FC<IBoardTextProps> = observer(({id, pureText, textBlock}) => {
    try {
        const {style, fills, characters, characterStyleOverrides, styleOverrideTable} = textBlock;
        const styles = convertStyles(style);
        const color = convertColor(fills[0].color!);
        // Если в Figma создали сложную текстовую ноду с разными стилями шрифта, цветами и ссылками
        const nodeHasStyles = characterStyleOverrides?.length > 0;
        const textElementContent: React.ReactNode[] = [];
        if (nodeHasStyles) {
            let tempStyle;
            let tempColor;
            let startPosition = 0;

            for (let index = 0; index <= characters.length; index++) {
                const styleIndex = toJS(characterStyleOverrides)[index] || 0;
                const prevStyleIndex = toJS(characterStyleOverrides)[index - 1] || 0;

                /**
                 * Фиксируем моменты изменения стиля в строке (prevStyleIndex != styleIndex) или это последняя группа
                 * [bold]Foo[normal]bar - подстрока Foo одним стилем, bar - другим. Рисуем для них соответственно span или a
                 */
                const isChangedStyle = index > 0 && prevStyleIndex !== styleIndex;
                const isLastGroup = index === characters.length;

                if (isChangedStyle || isLastGroup) {
                    let figmaLink: IFigmaLink | null = null;

                    // Конвертация нулевого стиля всей ноды или из override списка.
                    if (prevStyleIndex === 0) {
                        tempStyle = styles;
                        tempColor = color;
                    } else {
                        tempStyle = convertStyles(styleOverrideTable[prevStyleIndex]);
                        tempColor =
                            styleOverrideTable[prevStyleIndex].fills && convertColor(styleOverrideTable[prevStyleIndex].fills[0].color!);
                        figmaLink = (styleOverrideTable[prevStyleIndex] as any).hyperlink;
                    }

                    textElementContent.push(styleSubstring(startPosition, index, characters, tempStyle, tempColor, figmaLink));
                    startPosition = index;
                    tempStyle = {};
                    tempColor = '';
                }
            }
        } else {
            textElementContent.push(highlightSearchText(characters));
        }

        if (pureText) {
            return <span id={id}>{textElementContent}</span>
        }

        return (
            <div id={id} className="board-text" style={{...styles, color}}>
                {textElementContent}
            </div>
        );
    } catch (e) {
        console.error(`Ошибка разбора node в BoardText. NodeId - ${id}.`);
        console.error(e);
        return null;
    }
});
