import React, {useEffect, useState} from 'react';

export enum EAppTheme {
    DEFAULT = 'default',
    DARK = 'dark',
}

export interface IThemeContext {
    changeTheme: (newTheme: EAppTheme) => void;
    theme: EAppTheme;
}

export const ThemeContext = React.createContext<IThemeContext>({
    changeTheme: () => undefined,
    theme: EAppTheme.DEFAULT,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IThemeSwitcherProps {}

const ThemeSwitcher: React.FC<IThemeSwitcherProps> = ({children}) => {
    const [theme, changeTheme] = useState((localStorage.getItem('triplex-theme-2.1.0') as EAppTheme) || EAppTheme.DARK);

    useEffect(() => {
        switch (theme) {
            case EAppTheme.DEFAULT: {
                document.documentElement.classList.remove(`theme-${EAppTheme.DARK}`);
                // 2.1.0 - текущая версия пакета.
                localStorage.setItem('triplex-theme-2.1.0', EAppTheme.DEFAULT);
                break;
            }
            case EAppTheme.DARK: {
                document.documentElement.classList.add(`theme-${EAppTheme.DARK}`);
                // 2.1.0 - текущая версия пакета.
                localStorage.setItem('triplex-theme-2.1.0', EAppTheme.DARK);
                break;
            }
        }
    }, [theme]);

    return <ThemeContext.Provider value={{changeTheme, theme}}>{children}</ThemeContext.Provider>;
};

export default ThemeSwitcher;
