import React from 'react';

/** Большая круглая иконка загрузки. */
export const LoaderCircleBigIcon = (): React.ReactElement => (
    <svg className="icon-spin" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24H48C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C25.6569 0 27 1.34315 27 3C27 4.65685 25.6569 6 24 6Z"
                fill="url(#paint0_radial)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(45.5924 22.4133) rotate(116.792) scale(59.139 29.5879)"
                >
                    <stop offset="0.140625" stopColor="#107F8C" stopOpacity="0" />
                    <stop offset="1" stopColor="#107F8C" />
                </radialGradient>
            </defs>
        </svg>
);
