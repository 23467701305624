import React from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';
import NodeUtils from '../../../utils/NodeUtils';
import {useHistory} from 'react-router-dom';

interface IBoardContentsItemProps {
    boardNode: IBoard;
    node: INode;
    children?: never;
}

const BoardContentsItem: React.FC<IBoardContentsItemProps> = observer(({boardNode, node}) => {
    const history = useHistory();

    const handleClick = (event: React.MouseEvent) => {
        const DOMNode = document.getElementById(node.nodeFigmaId);
        if (DOMNode) {
            DOMNode.scrollIntoView({behavior: 'smooth'});

            history.push({
                hash: node.nodeFigmaId,
            });
        }

        event.preventDefault();
    };

    return (
        <li>
            <a href={`#${node.nodeFigmaId}`} onClick={handleClick} className="board-link" id={node.nodeFigmaId}>
                {NodeUtils.getChildrenNodes(boardNode, node).map((k) => (
                    <BoardNode pureText key={k.id} boardNode={boardNode} node={k} />
                ))}
            </a>
        </li>
    )
});

export default BoardContentsItem;
