import React from 'react';
import {IStyleguideRoute} from '../../types/types';
import {useTranslation} from 'react-i18next';

interface IMenuLevelBackProps {
    // Текущий роут меню.
    route?: IStyleguideRoute;
    // По клику передается родительский роут меню.
    onClick: () => void;
}

/** Пункт меню "Назад". */
export const MenuBackLink = React.forwardRef<HTMLDivElement, IMenuLevelBackProps>(({onClick, route}, ref) => {
    const {t} = useTranslation();

    return (
        <div className="menu-back" ref={ref}>
            <span className="menu-back-link" onClick={onClick}>
                <svg className="menu-back-icon" width="7" height="14" viewBox="0 0 7 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 1L2 7L6 13" strokeWidth="2" strokeLinecap="round"/>
                </svg>

                {route ? route.NAME : t('menu to home')}
            </span>
        </div>
    )
});

MenuBackLink.displayName = 'MenuBackLink';
