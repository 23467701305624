import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {CloseIcon} from './CloseIcon';
import './LivePreview.scss'

interface ILivePreviewProps {
    url: string;
    isOpen: boolean;
    onClose: () => void;
}

const overflowHiddenClassName = 'overflow-hidden';

export const LivePreview: React.FC<ILivePreviewProps> = ({url, isOpen, onClose}) => {
    const mountNode = document.querySelector('#live-demo')

    // Убирается класс при размонтировании компонента.
    useEffect(() => {

        return () => {
            document.body.classList.remove(overflowHiddenClassName)
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add(overflowHiddenClassName)
        } else {
            document.body.classList.remove(overflowHiddenClassName)
        }
    }, [isOpen])

    if (!isOpen || !mountNode) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="live-preview">
            <div className="frame-container">
                <iframe
                    className="frame"
                    src={url}
                    title="Live demo"
                />
            </div>
            <span className="close" onClick={onClose}>
                <CloseIcon />
            </span>
        </div>,
        mountNode
    );
}