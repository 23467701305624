/**
 * Заглавные страницы разделов. boardFigmaId
 * "73714:68" = Web en.
 * "74121:6609" = Web ru.
 * "69567:19401" = Super ru.
 * "71037:18891" = Super en.
 * "42875:17109" = Mobile ru.
 * "45689:242" = Mobile en.
 */
export const channelMainPageFigmaIds = ["74121:6609", "73714:68", "69567:19401", "71037:18891", "42875:17109", "45689:242"];
