import React from 'react';
import {IChannelIconProps} from './ChannelSuperIcon';

export const ChannelDesktopIcon: React.FC<IChannelIconProps> = ({className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#BB64FF" />
        <path
            d="M11.3022 17.084C14.5222 17.084 16.1322 15.474 16.1322 12.002C16.1322 8.516 14.7322 7.116 11.4002 7.116C10.0422 7.116 8.48819 7.27 8.48819 7.27V16.93C8.48819 16.93 10.0422 17.084 11.3022 17.084ZM14.2842 12.002C14.2842 14.48 13.3462 15.418 11.3442 15.418C10.7982 15.418 10.2802 15.362 10.2802 15.362V8.838C10.2802 8.838 10.8542 8.782 11.4422 8.782C13.4722 8.782 14.2842 9.594 14.2842 12.002Z"
            fill="#1F1F22"
        />
    </svg>
);
