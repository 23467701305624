import React from 'react';
import {Color, TypeStyle} from 'figma-api';

/** Название ноды бокового меню. */
export const sideMenuNodeName = 'XX.G.Side Menu';

/** Шаблон для генерации регулярного выражения для парсинга названий контентных блоков. */
export const ContentTpl = /(\d+).(G).(Header|Content|Warning|Col-)([\d])*(\.)*([\d])*/;

/** Шаблон для генерации регулярного выражения для парсинга названий элементов в контентных блоках. */
export const ElementTpl = /^(\d+)\.(.+)\.(.+)|(\d+)\.(.+)$/;

/** Регулярное выражение для парсинга названий контентных блоков. */
export const groupNodeRegExp = new RegExp(ContentTpl);

/** Регулярное выражение для парсинга названий элементов в контентных блоках. */
export const elementRegExp = new RegExp(ElementTpl);

/**
 * Функция конвертации стилей.
 * @param {Partial<TypeStyle>} styles Объект стилей блока из Figma.
 */
export function convertStyles(styles: Partial<TypeStyle>): Partial<React.CSSProperties> {
    const converted: React.CSSProperties = {};

    if (!styles) {
        console.error('Не удалось получить текстовые стили объекта');
        return converted;
    }

    if (styles.fontFamily) {
        converted.fontFamily = styles.fontFamily;
    }
    if (styles.fontSize) {
        converted.fontSize = `${styles.fontSize}px`;
    }
    if (styles.fontWeight) {
        converted.fontWeight = styles.fontWeight as 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
    }
    if (styles.lineHeightPx) {
        converted.lineHeight = `${styles.lineHeightPx}px`;
    }
    if (styles.textDecoration) {
        converted.textDecoration = `${styles.textDecoration}`;
    }

    return converted;
}

/**
 * Функция конвертации цветов.
 * @param {Color} color Объект цветов блока из Figma.
 */
export function convertColor(color: Color): string {
    return `rgba(${Math.round(color.r * 255)},${Math.round(color.g * 255)},${Math.round(color.b * 255)},${color.a})`;
}
