import React, {useEffect, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {EChannel, IBoard, INode} from '../../types/types';
import LivePreviewUtils from '../LivePreview/LivePreviewUtils';
import {LivePreview} from '../LivePreview/LivePreview';
import {sideMenuNodeName} from '../../utils/StyleguideUtils';
import NodeUtils from '../../utils/NodeUtils';
import {ETriplexResource, ITriplexResource} from './types';
import TriplexResource from "./TriplexResource";
import './triplexResources.scss';

interface ITriplexResourcesProps {
    boardNode: IBoard;
    children?: never;
    // Название канала Web/Mob.
    fileTitle: EChannel;
}

// Ссылка на GitHub.
const githubLink: ITriplexResource = {
    href: 'https://github.com/SberBusiness/triplex',
    title: 'GitHub',
    type: ETriplexResource.github,
};

/**
 * Ссылки на ресурсы Triplex(Figma/Pixso/Github/DemoUI). Отображаются в правой панели.
 */
const TriplexResources: React.FC<ITriplexResourcesProps> = observer(({boardNode, fileTitle}) => {
    const [resourcesNode, setResourcesNode] = useState<INode>();
    // Внешние ресурсы - Figma или Pixso. Эти ресурсы парсятся из макетов Figma.
    const [externalResources, setExternalResources] = useState<ITriplexResource[]>([]);
    const [liveDemoPath, setLiveDemoPath] = useState<string | undefined>();
    const [isOpenLiveDemo, setIsOpenLiveDemo] = useState(false);

    useEffect(() => {
        const findHeaderLinksNode = (node: INode) => {
            if (node.content.children) {
                if (!node.content.visible === false) {
                    return;
                }
                if (node.content.name === sideMenuNodeName) {
                    setResourcesNode(node);
                }

                NodeUtils.getChildrenNodes(boardNode, node).forEach(findHeaderLinksNode)
            }
        }

        boardNode.children.forEach(nodeId => {
            const node = boardNode.nodes.filter(n => n.nodeFigmaId === nodeId)[0];
            findHeaderLinksNode(node);
        });
    }, []);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLiveDemoPath(LivePreviewUtils.getLiveDemoPathFromBoardId(boardNode.boardFigmaId));
    }, [boardNode.boardFigmaId]);

    useLayoutEffect(() => {
        if (!resourcesNode) {
            return;
        }

        const resources: ITriplexResource[] = [];

        resourcesNode.content.children.forEach((linkNodeId) => {
            const linkNode = boardNode.nodes.filter((n) => n.nodeFigmaId === linkNodeId)[0];
            // linkNode.name - это что-то типа - "XX.T.Link-02.Confluence | https://sbtatlas.sigma.sbrf.ru/wiki/pages/viewpage.action?pageId=1569564009"
            const linkNodeArr = linkNode.content.name.split('|');
            const link = linkNodeArr[1] ? linkNodeArr[1].trim() : undefined;

            if (!link) {
                return;
            }

            const titleArr = linkNodeArr[0].split('.');
            const title = titleArr[titleArr.length - 1];

            if (link.includes(ETriplexResource.figma)) {
                resources.unshift({href: link, title, type: ETriplexResource.figma});
            } else if (link.includes(ETriplexResource.pixso)) {
                resources.unshift({href: link, title, type: ETriplexResource.pixso});
            }
        });

        setExternalResources(resources);
    }, [resourcesNode]);

    const handleClickLiveDemoResource = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        setIsOpenLiveDemo(true);
    };

    if ((!resourcesNode || !externalResources.length || resourcesNode.content.visible === false) && !liveDemoPath) {
        return null;
    }

    return (
        <>
            <div className="triplex-resources">
                {liveDemoPath && (
                    <TriplexResource item={{href: liveDemoPath, title: 'Demo UI', type: ETriplexResource.demoUi}} onClick={handleClickLiveDemoResource} />
                )}

                {externalResources.map((resource) => <TriplexResource key={resource.href} item={resource} />)}

                {fileTitle === EChannel.web ? <TriplexResource item={githubLink} /> : null}
            </div>

            {liveDemoPath && (
                <LivePreview
                    isOpen={isOpenLiveDemo}
                    url={LivePreviewUtils.styleguidistHost + liveDemoPath}
                    onClose={() => setIsOpenLiveDemo(false)}
                />
            )}
        </>
    );
});

export default TriplexResources;
