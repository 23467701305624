import React from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';

interface IBoardContentProps {
    boardNode: IBoard;
    node: INode;
    children?: never;
}

const BoardContent: React.FC<IBoardContentProps> = observer(({boardNode, node}) => (
    <div className="board-content" id={node.nodeFigmaId}>
        <BoardNode boardNode={boardNode} node={node} />
    </div>
));

export default BoardContent;
