import {makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {IFile} from '../types/types';
import {IRootStore} from './RootStore';

export interface IFilesStore {
    isFilesLoaded: boolean;
    files: IFile[];
    loadFiles: () => void;
    loadFilesSuccess: (files: IFile[]) => void;
    rootStore: IRootStore;
}

class FilesStore implements IFilesStore {
    files: IFile[] = [];
    isFilesLoaded = false;
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    loadFiles = (): void => {
        api.getFiles()
            .then(this.loadFilesSuccess)
            .catch(e => this.rootStore.errorsStore.addError(e))
    };

    loadFilesSuccess = (files: IFile[]): void => {
        this.files = files;
        this.isFilesLoaded = true;
    };
}

export default FilesStore;
