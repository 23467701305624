import React, {useState, useEffect} from 'react';
import throttle from 'lodash-es/throttle';
import icon from './to-top.svg';
import './backTop.scss';

const minVisibleHeight = 400;

const BackTop: React.FC = () => {
    const [isHidden, setIsHidden] = useState(true);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const toggleVisibilityWithThrottle = throttle(function() {
            if (window.pageYOffset > minVisibleHeight) {
                setIsHidden(() => false);
            } else {
                setIsHidden(() => true);
            }
        }, 100);

        window.addEventListener('scroll', toggleVisibilityWithThrottle);

        return () => {
            window.removeEventListener('scroll', toggleVisibilityWithThrottle);
        };
    }, []);

    return (
        <div onClick={handleClick} className={'back-top'} style={{visibility: (isHidden ? 'hidden' : 'visible')}}>
            <img className={'back-top-image'} src={icon} />
        </div>);
};

export default BackTop;