import React from 'react';

interface IBurgerLogoOpenProps {
    fill: string;
}

export const BurgerLogoOpen: React.FC<IBurgerLogoOpenProps> = ({fill}) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 10C4 9.44772 4.44772 9 5 9H27C27.5523 9 28 9.44772 28 10C28 10.5523 27.5523 11 27 11H5C4.44772 11 4 10.5523 4 10Z" fill={fill} />
        <path d="M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z" fill={fill} />
        <path d="M5 21C4.44772 21 4 21.4477 4 22C4 22.5523 4.44772 23 5 23H27C27.5523 23 28 22.5523 28 22C28 21.4477 27.5523 21 27 21H5Z" fill={fill} />
    </svg>
);
