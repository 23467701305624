import React, {useContext} from 'react';
import {BurgerLogoClose} from './BurgerLogoClose';
import {BurgerLogoOpen} from './BurgerLogoOpen';
import {EAppTheme, ThemeContext} from '../ThemeSwitcher/ThemeSwitcher';
import './burger.scss';

interface IBurgerProps {
    isMobileSidebarHidden: boolean;
    setIsMobileSidebarHidden: (isMobileSidebarHidden: boolean) => void;
}

const Burger: React.FC<IBurgerProps> = ({isMobileSidebarHidden, setIsMobileSidebarHidden}) => {
    const {theme} = useContext(ThemeContext);

    const handleClick = () => {
        setIsMobileSidebarHidden(!isMobileSidebarHidden)
    };

    return (
        <div className={'burger' + (isMobileSidebarHidden ? '' : ' cross')} onClick={handleClick}>
            {isMobileSidebarHidden ? (
                <BurgerLogoOpen fill={theme === EAppTheme.DEFAULT ? '#1F1F22' : '#FFFFFF'} />
            ) : (
                <BurgerLogoClose fill={theme === EAppTheme.DEFAULT ? '#1F1F22' : '#FFFFFF'} />
            )}
        </div>
    );
};

export default Burger;