import React from 'react';
import {Link} from 'react-router-dom';
import './Logo.scss';

export interface ILogoProps {
    logoIcon: JSX.Element;
    // Логотип является ссылкой на главную.
    isLink?: boolean;
}

export const Logo = ({isLink, logoIcon}: ILogoProps): React.ReactElement => (
    <div className="logo-wrapper">
        {isLink ? (
            <Link className="logo-link" to={(location: any) => ({...location, pathname: '/'})}>
                {logoIcon}
            </Link>
        ) : (
            <span className="logo-link">
                {logoIcon}
            </span>
        )}
    </div>
);
