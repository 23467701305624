import React from 'react';
import {useTranslation} from "react-i18next";
import {EAppLanguage} from "../LanguageControl/LanguageControl";

export interface ILogoThemeDarkProps {
    className?: string;
}

export const LogoThemeDark = ({className}: ILogoThemeDarkProps): React.ReactElement => {
    const {i18n} = useTranslation();

    return i18n.language === EAppLanguage.ru ? (
        <svg className={className} width="217" height="70" viewBox="0 0 217 70" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.2461 15.3143C21.2461 6.77689 28.6886 0 37.6848 0C39.9555 0 42.1225 0.429311 44.0949 1.20789C44.6749 1.43683 44.9595 2.09261 44.7306 2.67261C44.5016 3.2526 43.8459 3.53719 43.2659 3.30824C41.5556 2.63316 39.6698 2.25806 37.6848 2.25806C29.7704 2.25806 23.5042 8.1831 23.5042 15.3143V54.6857C23.5042 61.8169 29.7704 67.7419 37.6848 67.7419C39.6698 67.7419 41.5556 67.3668 43.2659 66.6918C43.8459 66.4628 44.5016 66.7474 44.7306 67.3274C44.9595 67.9074 44.6749 68.5632 44.0949 68.7921C42.1225 69.5707 39.9555 70 37.6848 70C28.6886 70 21.2461 63.2231 21.2461 54.6857V15.3143Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M50.4296 7.43273C50.6895 7.26053 51.0398 7.33162 51.212 7.59152C52.699 9.83586 53.5589 12.4814 53.5589 15.3144V54.6857C53.5589 57.5187 52.699 60.1643 51.2119 62.4087C51.0397 62.6686 50.6894 62.7397 50.4295 62.5675C50.1696 62.3953 50.0985 62.045 50.2707 61.7851C51.6418 59.7158 52.4299 57.2854 52.4299 54.6857V15.3144C52.4299 12.7147 51.6418 10.2843 50.2708 8.21512C50.0986 7.95522 50.1697 7.60493 50.4296 7.43273Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 15.3143C0 6.77689 7.44253 0 16.4388 0C18.7094 0 20.8764 0.429311 22.8488 1.20789C23.4288 1.43683 23.7134 2.09261 23.4845 2.67261C23.2555 3.2526 22.5998 3.53718 22.0198 3.30824C20.3095 2.63316 18.4237 2.25806 16.4388 2.25806C8.52433 2.25806 2.25806 8.1831 2.25806 15.3143V54.6857C2.25806 61.8169 8.52433 67.7419 16.4388 67.7419C18.4237 67.7419 20.3095 67.3668 22.0198 66.6918C22.5998 66.4628 23.2555 66.7474 23.4845 67.3274C23.7134 67.9074 23.4288 68.5632 22.8489 68.7921C20.8764 69.5707 18.7094 70 16.4388 70C7.44253 70 0 63.2231 0 54.6857V15.3143Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.1835 7.43273C29.4434 7.26053 29.7937 7.33162 29.9659 7.59152C31.4529 9.83586 32.3128 12.4814 32.3128 15.3144V54.6857C32.3128 57.5187 31.4529 60.1643 29.9658 62.4087C29.7936 62.6686 29.4433 62.7397 29.1834 62.5675C28.9235 62.3953 28.8524 62.045 29.0246 61.7851C30.3957 59.7158 31.1838 57.2854 31.1838 54.6857V15.3144C31.1838 12.7147 30.3957 10.2843 29.0247 8.21512C28.8525 7.95522 28.9236 7.60493 29.1835 7.43273Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M42.4922 16.4388C42.4922 7.35988 49.8521 0 58.9309 0C68.0098 0 75.3697 7.35988 75.3697 16.4387V53.5612C75.3697 62.6401 68.0098 70 58.9309 70C49.8521 70 42.4922 62.6401 42.4922 53.5612L42.4922 16.4388ZM58.9309 2.25806C51.0992 2.25806 44.7503 8.60697 44.7503 16.4388L44.7503 53.5612C44.7503 61.393 51.0992 67.7419 58.9309 67.7419C66.7627 67.7419 73.1116 61.393 73.1116 53.5612V16.4387C73.1116 8.60697 66.7627 2.25806 58.9309 2.25806Z"
                  fill="white"/>
            <path d="M94.6151 23.1784H96.2607V14.7321H99.552V13.2649H91.304V14.7321H94.6151V23.1784Z" fill="white"/>
            <path
                d="M101.035 26.7472H102.681V21.6517C103.236 22.5836 104.406 23.3965 106.071 23.3965C108.907 23.3965 110.592 21.9095 110.592 18.0036C110.592 14.5338 108.907 13.0667 106.27 13.0667C104.267 13.0667 103.097 14.0183 102.602 15.1683V13.2649H101.035V26.7472ZM102.681 17.6863C102.721 15.7036 103.772 14.5537 105.834 14.5537C107.697 14.5537 108.927 15.4459 108.927 18.1027C108.927 20.8586 107.995 21.9095 105.814 21.9095C103.95 21.9095 102.681 20.7 102.681 18.638V17.6863Z"
                fill="white"/>
            <path
                d="M113.835 23.1784L114.013 22.9405L119.406 15.7433V23.1784H121.052V13.2649H119.605L114.013 20.6405V13.2649H112.368V23.1784H113.835Z"
                fill="white"/>
            <path d="M130.344 23.1784H131.99V13.2649H123.623V23.1784H125.268V14.7321H130.344V23.1784Z" fill="white"/>
            <path
                d="M141.031 23.1784H142.677V13.2649H135.698L135.658 14.633C135.46 21.2155 134.687 21.4931 133.675 21.4931C133.537 21.4931 133.358 21.4733 133.18 21.4336V23.1982C133.378 23.2577 133.656 23.2973 133.973 23.2973C135.42 23.2973 136.907 22.881 137.165 15.4855L137.185 14.7321H141.031V23.1784Z"
                fill="white"/>
            <path
                d="M149.405 13.0667C146.609 13.0667 144.508 14.7519 144.508 18.182C144.508 21.6517 146.312 23.3766 149.445 23.3766C151.388 23.3766 152.597 22.7818 153.093 22.1672V20.244C152.557 21.1957 151.348 21.9491 149.445 21.9491C147.502 21.9491 146.352 20.9578 146.193 18.8165H153.787V17.7458C153.787 14.8114 152.141 13.0667 149.405 13.0667ZM149.405 14.4942C150.971 14.4942 152.141 15.4855 152.141 17.3889V17.4087H146.213C146.431 15.4855 147.541 14.4942 149.405 14.4942Z"
                fill="white"/>
            <path
                d="M155.606 23.1784H157.252V18.8165H159.036L162.605 23.1784H164.687L160.404 17.9441L164.032 13.2649H162.149L159.036 17.4087H157.252V13.2649H155.606V23.1784Z"
                fill="white"/>
            <path
                d="M173.192 22.1672V20.244C172.696 21.1957 171.606 21.9491 169.96 21.9491C167.858 21.9491 166.689 20.7793 166.689 18.2415V18.182C166.689 15.7433 167.838 14.4942 169.92 14.4942C171.269 14.4942 172.419 15.2476 173.093 16.3579V14.0183C172.438 13.5227 171.249 13.0667 169.92 13.0667C167.125 13.0667 165.023 14.7519 165.023 18.182C165.023 21.6517 166.827 23.3766 169.96 23.3766C171.645 23.3766 172.736 22.7818 173.192 22.1672Z"
                fill="white"/>
            <path
                d="M175.274 22.1077C175.274 22.8017 175.571 23.3766 176.563 23.3766C177.574 23.3766 177.871 22.8017 177.871 22.1077C177.871 21.394 177.574 20.819 176.563 20.819C175.571 20.819 175.274 21.394 175.274 22.1077Z"
                fill="white"/>
            <path
                d="M90.9141 39.5323V41.8322H91.5221V40.1139H99.8626V41.8322H100.471V39.5323H99.2017V30.8613H93.0686L93.0422 32.7779C93.0025 35.633 92.5531 38.2237 92.0244 39.5323H90.9141ZM93.6237 32.8176L93.6502 31.4429H98.5672V39.5323H92.6456C93.1875 38.0254 93.5709 35.4215 93.6237 32.8176Z"
                fill="white"/>
            <path
                d="M102.398 30.8613H101.763V40.1139H102.464L108.993 31.7072V40.1139H109.628V30.8613H108.927L102.398 39.2415V30.8613Z"
                fill="white"/>
            <path
                d="M111.411 32.8969C111.953 31.9584 113.169 31.2975 114.557 31.3107C115.905 31.3239 117.108 31.932 117.108 33.2141C117.108 34.2847 116.394 35.091 114.808 35.091H113.354V35.6462H114.821C116.725 35.6462 117.571 36.3335 117.571 37.4967C117.571 38.8185 116.487 39.638 114.782 39.6644C112.997 39.6644 111.755 38.911 111.213 38.0254V39.0432C111.702 39.5984 112.997 40.246 114.716 40.246C116.95 40.2328 118.232 39.0696 118.232 37.5099C118.232 36.2674 117.439 35.4611 116.038 35.2893C117.214 34.9456 117.756 34.1261 117.756 33.0819C117.756 31.4958 116.394 30.7423 114.557 30.7291C113.037 30.7291 111.887 31.2975 111.411 31.7998V32.8969Z"
                fill="white"/>
            <path
                d="M122.919 30.8613L118.795 40.1139H119.469L120.658 37.4042H125.826L127.043 40.1139H127.73L123.593 30.8613H122.919ZM123.236 31.5883L125.575 36.8226H120.923L123.236 31.5883Z"
                fill="white"/>
            <path
                d="M129.451 30.8613H128.817V40.1139H129.517L136.047 31.7072V40.1139H136.681V30.8613H135.981L129.451 39.2415V30.8613ZM134.58 28.4821C134.5 29.2223 133.985 29.6849 132.808 29.6849C131.632 29.6849 131.064 29.2487 130.984 28.4821H130.363C130.482 29.936 131.698 30.2136 132.835 30.2136C134.104 30.2136 135.055 29.6981 135.161 28.4821H134.58Z"
                fill="white"/>
            <path
                d="M146.065 40.1139H146.699V30.8613H146.065V35.0646H139.562V30.8613H138.927V40.1139H139.562V35.6462H146.065V40.1139Z"
                fill="white"/>
            <path d="M148.644 36.5186H152.027V35.9106H148.644V36.5186Z" fill="white"/>
            <path
                d="M161.348 38.0254C160.833 38.911 159.643 39.6644 157.951 39.6644C155.426 39.5719 153.973 37.88 153.973 35.4876C153.973 32.9497 155.651 31.3107 158.03 31.3107C159.418 31.3107 160.634 31.9584 161.176 32.8969V31.7998C160.7 31.2975 159.55 30.7291 158.03 30.7291C155.175 30.7291 153.312 32.7382 153.312 35.4744C153.312 38.3823 155.07 40.1932 158.004 40.246C159.643 40.246 160.885 39.5984 161.348 39.0432V38.0254Z"
                fill="white"/>
            <path
                d="M163.44 30.8613H162.805V40.1139H163.506L170.036 31.7072V40.1139H170.67V30.8613H169.969L163.44 39.2415V30.8613Z"
                fill="white"/>
            <path
                d="M180.292 38.0254C179.776 38.911 178.586 39.6644 176.895 39.6644C174.37 39.5719 172.916 37.88 172.916 35.4876C172.916 32.9497 174.595 31.3107 176.974 31.3107C178.362 31.3107 179.578 31.9584 180.12 32.8969V31.7998C179.644 31.2975 178.494 30.7291 176.974 30.7291C174.119 30.7291 172.255 32.7382 172.255 35.4744C172.255 38.3823 174.013 40.1932 176.947 40.246C178.586 40.246 179.829 39.5984 180.292 39.0432V38.0254Z"
                fill="white"/>
            <path d="M188.411 30.8613H180.559V31.4429H184.168V40.1139H184.802V31.4429H188.411V30.8613Z" fill="white"/>
            <path
                d="M195.142 35.0646H190.106V31.4429H195.644V30.8613H189.471V40.1139H195.79V39.5323H190.106V35.6462H195.142V35.0646Z"
                fill="white"/>
            <path
                d="M206.072 40.1139H206.706V30.8613H205.953L202.107 37.0341L198.168 30.8613H197.388V40.1139H198.022V31.8526L201.948 37.9197H202.226L206.072 31.7601V40.1139Z"
                fill="white"/>
            <path
                d="M211.905 30.8613L207.781 40.1139H208.455L209.644 37.4042H214.813L216.029 40.1139H216.716L212.579 30.8613H211.905ZM212.222 31.5883L214.561 36.8226H209.909L212.222 31.5883Z"
                fill="white"/>
            <path
                d="M99.5057 54.9609C98.9902 55.8465 97.8006 56.5999 96.1087 56.5999C93.5841 56.5074 92.1301 54.8155 92.1301 52.4231C92.1301 49.8852 93.8088 48.2462 96.188 48.2462C97.5759 48.2462 98.792 48.8939 99.3339 49.8323V48.7353C98.858 48.233 97.7081 47.6646 96.188 47.6646C93.3329 47.6646 91.4692 49.6737 91.4692 52.4098C91.4692 55.3178 93.2272 57.1287 96.1616 57.1815C97.8006 57.1815 99.0431 56.5338 99.5057 55.9787V54.9609Z"
                fill="white"/>
            <path
                d="M100.963 57.0493H103.646C106.171 57.0493 107.559 56.1241 107.559 54.0885C107.559 52.0133 106.237 51.207 103.646 51.207H101.597V48.3784H107.083V47.7968H100.963V57.0493ZM103.659 51.7886C105.787 51.7886 106.898 52.3438 106.898 54.0489C106.898 55.7936 105.761 56.4678 103.606 56.4678H101.597V51.7886H103.659Z"
                fill="white"/>
            <path
                d="M114.924 52.0001H109.888V48.3784H115.426V47.7968H109.254V57.0493H115.572V56.4678H109.888V52.5817H114.924V52.0001Z"
                fill="white"/>
            <path
                d="M119.853 53.7977C122.378 53.7977 123.766 52.7932 123.766 50.7576C123.766 48.6824 122.444 47.7968 120.104 47.7968H117.17V57.0493H117.804V53.7977H119.853ZM120.038 48.3784C122.034 48.3784 123.105 49.0128 123.105 50.7179C123.105 52.4627 121.968 53.2161 119.813 53.2161H117.804V48.3784H120.038Z"
                fill="white"/>
            <path
                d="M125.435 57.0493H128.118C130.643 57.0493 132.03 56.1241 132.03 54.0885C132.03 52.0133 130.709 51.207 128.118 51.207H126.069V48.3784H131.555V47.7968H125.435V57.0493ZM128.131 51.7886C130.259 51.7886 131.37 52.3438 131.37 54.0489C131.37 55.7936 130.233 56.4678 128.078 56.4678H126.069V51.7886H128.131Z"
                fill="white"/>
            <path
                d="M134.36 47.7968H133.725V57.0493H134.426L140.956 48.6427V57.0493H141.59V47.7968H140.889L134.36 56.177V47.7968Z"
                fill="white"/>
            <path
                d="M143.373 49.8323C143.915 48.8939 145.131 48.233 146.519 48.2462C147.867 48.2594 149.07 48.8674 149.07 50.1496C149.07 51.2202 148.357 52.0265 146.77 52.0265H145.316V52.5817H146.784C148.687 52.5817 149.533 53.269 149.533 54.4322C149.533 55.754 148.449 56.5735 146.744 56.5999C144.96 56.5999 143.717 55.8465 143.175 54.9609V55.9787C143.664 56.5338 144.96 57.1815 146.678 57.1815C148.912 57.1683 150.194 56.0051 150.194 54.4454C150.194 53.2029 149.401 52.3966 148 52.2248C149.176 51.8811 149.718 51.0616 149.718 50.0174C149.718 48.4312 148.357 47.6778 146.519 47.6646C144.999 47.6646 143.849 48.233 143.373 48.7353V49.8323Z"
                fill="white"/>
            <path
                d="M159.071 57.0493H159.705V47.7968H159.071V52.0001H152.567V47.7968H151.933V57.0493H152.567V52.5817H159.071V57.0493Z"
                fill="white"/>
            <path
                d="M167.624 52.0001H162.588V48.3784H168.126V47.7968H161.953V57.0493H168.272V56.4678H162.588V52.5817H167.624V52.0001Z"
                fill="white"/>
            <path
                d="M177.245 54.9609C176.73 55.8465 175.54 56.5999 173.848 56.5999C171.324 56.5074 169.87 54.8155 169.87 52.4231C169.87 49.8852 171.548 48.2462 173.928 48.2462C175.315 48.2462 176.532 48.8939 177.073 49.8323V48.7353C176.598 48.233 175.448 47.6646 173.928 47.6646C171.072 47.6646 169.209 49.6737 169.209 52.4098C169.209 55.3178 170.967 57.1287 173.901 57.1815C175.54 57.1815 176.783 56.5338 177.245 55.9787V54.9609Z"
                fill="white"/>
        </svg>
    ) : (
        <svg className={className} width="202" height="65" viewBox="0 0 202 65" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6786 14.2204C19.6786 6.29283 26.572 0 34.9046 0C37.0077 0 39.0149 0.398646 40.8418 1.12161C41.379 1.3342 41.6426 1.94314 41.4305 2.4817C41.2184 3.02027 40.611 3.28453 40.0738 3.07194C38.4898 2.44508 36.7431 2.09677 34.9046 2.09677C27.574 2.09677 21.7701 7.5986 21.7701 14.2204V50.7796C21.7701 57.4014 27.574 62.9032 34.9046 62.9032C36.7431 62.9032 38.4898 62.5549 40.0738 61.9281C40.6111 61.7155 41.2184 61.9797 41.4305 62.5183C41.6426 63.0569 41.379 63.6658 40.8418 63.8784C39.0149 64.6014 37.0077 65 34.9046 65C26.572 65 19.6786 58.7072 19.6786 50.7796V14.2204Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.7093 6.90178C46.95 6.74188 47.2745 6.8079 47.434 7.04924C48.8113 9.13327 49.6077 11.5899 49.6077 14.2204V50.7796C49.6077 53.4102 48.8113 55.8668 47.4339 57.9509C47.2744 58.1922 46.9499 58.2582 46.7092 58.0983C46.4685 57.9384 46.4026 57.6131 46.5621 57.3718C47.832 55.4504 48.562 53.1936 48.562 50.7796V14.2204C48.562 11.8065 47.832 9.5497 46.5622 7.62829C46.4027 7.38696 46.4686 7.06169 46.7093 6.90178Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 14.2204C0 6.29283 6.89346 0 15.226 0C17.3291 0 19.3363 0.398646 21.1632 1.12161C21.7004 1.3342 21.964 1.94314 21.7519 2.4817C21.5399 3.02027 20.9325 3.28453 20.3953 3.07194C18.8112 2.44508 17.0645 2.09677 15.226 2.09677C7.89545 2.09677 2.09148 7.59859 2.09148 14.2204V50.7796C2.09148 57.4014 7.89544 62.9032 15.226 62.9032C17.0645 62.9032 18.8112 62.5549 20.3953 61.9281C20.9325 61.7155 21.5399 61.9797 21.7519 62.5183C21.964 63.0569 21.7004 63.6658 21.1632 63.8784C19.3363 64.6014 17.3292 65 15.226 65C6.89346 65 0 58.7072 0 50.7796V14.2204Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.0304 6.90178C27.2712 6.74188 27.5956 6.8079 27.7551 7.04924C29.1324 9.13327 29.9289 11.5899 29.9289 14.2204V50.7796C29.9289 53.4102 29.1324 55.8668 27.7551 57.9509C27.5956 58.1922 27.2711 58.2582 27.0304 58.0983C26.7897 57.9384 26.7238 57.6131 26.8833 57.3718C28.1532 55.4504 28.8832 53.1936 28.8832 50.7796V14.2204C28.8832 11.8065 28.1532 9.5497 26.8834 7.62829C26.7239 7.38696 26.7897 7.06169 27.0304 6.90178Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.3573 15.2646C39.3573 6.83417 46.1742 0 54.5833 0C62.9924 0 69.8093 6.83417 69.8093 15.2646V49.7354C69.8093 58.1658 62.9924 65 54.5833 65C46.1742 65 39.3573 58.1658 39.3573 49.7354L39.3573 15.2646ZM54.5833 2.09677C47.3293 2.09677 41.4488 7.99219 41.4488 15.2646L41.4488 49.7354C41.4488 57.0078 47.3293 62.9032 54.5833 62.9032C61.8373 62.9032 67.7178 57.0078 67.7178 49.7354V15.2646C67.7178 7.99219 61.8373 2.09677 54.5833 2.09677Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6786 14.2204C19.6786 6.29283 26.572 0 34.9046 0C37.0077 0 39.0149 0.398646 40.8418 1.12161C41.379 1.3342 41.6426 1.94314 41.4305 2.4817C41.2184 3.02027 40.6111 3.28453 40.0738 3.07194C38.4898 2.44508 36.7431 2.09677 34.9046 2.09677C27.574 2.09677 21.7701 7.5986 21.7701 14.2204V50.7796C21.7701 57.4014 27.574 62.9032 34.9046 62.9032C36.7431 62.9032 38.4898 62.5549 40.0739 61.9281C40.6111 61.7155 41.2185 61.9797 41.4305 62.5183C41.6426 63.0569 41.379 63.6658 40.8418 63.8784C39.0149 64.6014 37.0077 65 34.9046 65C26.572 65 19.6786 58.7072 19.6786 50.7796V14.2204Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.7092 6.90178C46.9499 6.74188 47.2743 6.8079 47.4338 7.04924C48.8112 9.13327 49.6076 11.5899 49.6076 14.2204V50.7796C49.6076 53.4102 48.8111 55.8668 47.4338 57.9509C47.2743 58.1922 46.9498 58.2582 46.7091 58.0983C46.4684 57.9384 46.4025 57.6132 46.562 57.3718C47.8319 55.4504 48.5619 53.1936 48.5619 50.7796V14.2204C48.5619 11.8065 47.8319 9.5497 46.5621 7.62829C46.4026 7.38696 46.4684 7.06169 46.7092 6.90178Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 14.2204C0 6.29283 6.89346 0 15.226 0C17.3292 0 19.3363 0.398646 21.1632 1.12161C21.7004 1.3342 21.964 1.94314 21.7519 2.4817C21.5399 3.02027 20.9325 3.28453 20.3953 3.07194C18.8112 2.44508 17.0645 2.09677 15.226 2.09677C7.89545 2.09677 2.09148 7.59859 2.09148 14.2204V50.7796C2.09148 57.4014 7.89545 62.9032 15.226 62.9032C17.0645 62.9032 18.8112 62.5549 20.3953 61.9281C20.9325 61.7155 21.5399 61.9797 21.7519 62.5183C21.964 63.0569 21.7004 63.6658 21.1632 63.8784C19.3363 64.6014 17.3292 65 15.226 65C6.89346 65 0 58.7072 0 50.7796V14.2204Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.0304 6.90178C27.2712 6.74188 27.5956 6.8079 27.7551 7.04924C29.1324 9.13327 29.9289 11.5899 29.9289 14.2204V50.7796C29.9289 53.4102 29.1324 55.8668 27.7551 57.9509C27.5956 58.1922 27.2711 58.2582 27.0304 58.0983C26.7897 57.9384 26.7238 57.6132 26.8833 57.3718C28.1532 55.4504 28.8832 53.1936 28.8832 50.7796V14.2204C28.8832 11.8065 28.1532 9.5497 26.8834 7.62829C26.7239 7.38696 26.7897 7.06169 27.0304 6.90178Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.3573 15.2646C39.3573 6.83417 46.1742 0 54.5833 0C62.9924 0 69.8093 6.83417 69.8093 15.2646V49.7354C69.8093 58.1658 62.9924 65 54.5833 65C46.1742 65 39.3573 58.1658 39.3573 49.7354L39.3573 15.2646ZM54.5833 2.09677C47.3293 2.09677 41.4488 7.99219 41.4488 15.2646L41.4488 49.7354C41.4488 57.0078 47.3293 62.9032 54.5833 62.9032C61.8373 62.9032 67.7178 57.0078 67.7178 49.7354V15.2646C67.7178 7.99219 61.8373 2.09677 54.5833 2.09677Z"
                  fill="white"/>
            <path
                d="M86.182 10.7454V12.4347H84.2952V13.7934H86.182V18.9347C86.182 21.0095 87.1895 21.7991 89.2412 21.7991C90.0289 21.7991 90.9815 21.5053 91.3845 21.1748V19.8344C90.8532 20.1649 90.0838 20.422 89.3145 20.422C88.0688 20.422 87.7024 19.9629 87.7024 18.5491V13.7934H91.0181V12.4347H87.7024V9.64373L86.182 10.7454Z"
                fill="white"/>
            <path
                d="M96.2873 13.6282C96.9284 13.6466 97.4047 13.8485 97.6978 14.1607V12.5816C97.5146 12.4163 97.075 12.2878 96.6536 12.2694C95.3347 12.2694 94.4004 13.1691 94.1989 14.381V12.4347H92.7518V21.6155H94.2722V16.6762C94.2173 14.7482 94.8401 13.6282 96.2873 13.6282Z"
                fill="white"/>
            <path
                d="M99.1611 8.56039C99.1611 9.14796 99.5641 9.53356 100.187 9.53356C100.791 9.53356 101.194 9.14796 101.194 8.56039C101.194 7.99118 100.791 7.60559 100.187 7.60559C99.5641 7.60559 99.1611 7.99118 99.1611 8.56039ZM99.4176 21.6155H100.938V12.4347H99.4176V21.6155Z"
                fill="white"/>
            <path
                d="M103.585 24.9206H105.105V20.2016C105.618 21.0646 106.699 21.8175 108.238 21.8175C110.857 21.8175 112.414 20.4403 112.414 16.8231C112.414 13.6098 110.857 12.2511 108.421 12.2511C106.571 12.2511 105.49 13.1324 105.032 14.1974V12.4347H103.585V24.9206ZM105.105 16.5293C105.142 14.6932 106.113 13.6282 108.018 13.6282C109.74 13.6282 110.876 14.4545 110.876 16.9149C110.876 19.4672 110.015 20.4403 108 20.4403C106.278 20.4403 105.105 19.3203 105.105 17.4107V16.5293Z"
                fill="white"/>
            <path
                d="M118.305 20.1098C118.012 20.22 117.517 20.3485 116.968 20.3485C115.942 20.3485 115.575 20.0547 115.575 18.8429V7.84429H114.055V19.4121C114.055 21.1197 115.063 21.7991 116.638 21.7991C117.316 21.7991 118.012 21.6338 118.305 21.4686V20.1098Z"
                fill="white"/>
            <path
                d="M123.327 12.2511C120.744 12.2511 118.802 13.8118 118.802 16.9884C118.802 20.2016 120.469 21.7991 123.364 21.7991C125.159 21.7991 126.276 21.2482 126.734 20.679V18.898C126.24 19.7793 125.122 20.4771 123.364 20.4771C121.568 20.4771 120.506 19.559 120.359 17.5759H127.375V16.5844C127.375 13.8669 125.855 12.2511 123.327 12.2511ZM123.327 13.5731C124.774 13.5731 125.855 14.4912 125.855 16.2539V16.2723H120.378C120.579 14.4912 121.605 13.5731 123.327 13.5731Z"
                fill="white"/>
            <path
                d="M127.774 21.6155H129.551L132.097 17.9615L134.643 21.6155H136.512L133.068 16.7864L136.292 12.4347H134.515L132.189 15.7214L129.862 12.4347H127.994L131.181 16.9149L127.774 21.6155Z"
                fill="white"/>
            <path
                d="M137.795 20.624C137.795 21.2666 138.07 21.7991 138.986 21.7991C139.92 21.7991 140.195 21.2666 140.195 20.624C140.195 19.9629 139.92 19.4304 138.986 19.4304C138.07 19.4304 137.795 19.9629 137.795 20.624Z"
                fill="white"/>
            <path
                d="M90.6212 30.6035V29.5875C90.1815 29.1223 89.1191 28.5959 87.7513 28.5959C86.0782 28.5959 84.8203 29.3426 84.8203 30.7504C84.8203 33.8841 90.2548 32.5376 90.2548 35.1082C90.2548 36.32 89.2412 36.8831 87.9345 36.8831C86.3346 36.8831 85.1867 36.1854 84.686 35.3652V36.3078C85.1378 36.8219 86.3346 37.4217 87.8734 37.4217C89.7419 37.4217 90.8654 36.4914 90.8654 35.0959C90.8654 31.901 85.4187 33.2598 85.4187 30.7259C85.4187 29.7344 86.2858 29.159 87.7513 29.1345C88.9969 29.1345 90.1205 29.7344 90.6212 30.6035Z"
                fill="white"/>
            <path
                d="M95.8219 37.2993C97.7515 37.2993 98.9361 36.5771 98.9361 34.8022C98.9361 33.6882 98.3499 33.0027 97.2264 32.8313C98.1667 32.5376 98.6796 31.8521 98.6796 30.8116C98.6796 29.3549 97.7149 28.7306 96.0418 28.7306H92.488V37.2993H95.8219ZM93.0742 36.7607V33.1496H96.0906C97.5805 33.1496 98.3499 33.7127 98.3499 34.8144C98.3499 36.1364 97.4706 36.7607 95.883 36.7607H93.0742ZM93.0742 32.6477V29.2692H95.9196C97.3851 29.2692 98.0935 29.7711 98.0935 30.9095C98.0935 31.8765 97.3607 32.6477 96.0173 32.6477H93.0742Z"
                fill="white"/>
            <path
                d="M105.793 32.6232H101.14V29.2692H106.257V28.7306H100.553V37.2993H106.391V36.7607H101.14V33.1618H105.793V32.6232Z"
                fill="white"/>
            <path
                d="M113.729 37.2993H114.45L112.093 34.0432C113.278 33.6515 113.925 32.8069 113.925 31.4726C113.925 29.5507 112.704 28.7306 110.542 28.7306H107.867V37.2993H108.454V34.288H110.31C110.737 34.288 111.128 34.2635 111.482 34.2023L113.729 37.2993ZM110.481 29.2692C112.325 29.2692 113.314 29.8568 113.314 31.4359C113.314 33.0517 112.264 33.7494 110.273 33.7494H108.454V29.2692H110.481Z"
                fill="white"/>
            <path
                d="M119.148 37.2993C121.077 37.2993 122.262 36.5771 122.262 34.8022C122.262 33.6882 121.676 33.0027 120.552 32.8313C121.492 32.5376 122.005 31.8521 122.005 30.8116C122.005 29.3549 121.041 28.7306 119.367 28.7306H115.814V37.2993H119.148ZM116.4 36.7607V33.1496H119.416C120.906 33.1496 121.676 33.7127 121.676 34.8144C121.676 36.1364 120.796 36.7607 119.209 36.7607H116.4ZM116.4 32.6477V29.2692H119.245C120.711 29.2692 121.419 29.7711 121.419 30.9095C121.419 31.8765 120.686 32.6477 119.343 32.6477H116.4Z"
                fill="white"/>
            <path
                d="M124.404 28.7306H123.818V33.8841C123.818 36.2344 124.978 37.4217 127.262 37.4217C129.277 37.4217 130.657 36.4425 130.657 33.8963V28.7306H130.071V33.933C130.071 36.0997 128.947 36.8831 127.323 36.8831C125.503 36.8831 124.404 36.0507 124.404 33.7861V28.7306Z"
                fill="white"/>
            <path
                d="M138.143 30.6035V29.5875C137.703 29.1223 136.641 28.5959 135.273 28.5959C133.6 28.5959 132.342 29.3426 132.342 30.7504C132.342 33.8841 137.777 32.5376 137.777 35.1082C137.777 36.32 136.763 36.8831 135.456 36.8831C133.857 36.8831 132.709 36.1854 132.208 35.3652V36.3078C132.66 36.8219 133.857 37.4217 135.395 37.4217C137.264 37.4217 138.387 36.4914 138.387 35.0959C138.387 31.901 132.941 33.2598 132.941 30.7259C132.941 29.7344 133.808 29.159 135.273 29.1345C136.519 29.1345 137.642 29.7344 138.143 30.6035Z"
                fill="white"/>
            <path d="M140.596 28.7306H140.01V37.2993H140.596V28.7306Z" fill="white"/>
            <path
                d="M149.353 37.2993H149.976V28.7306H149.39V36.3812L143.296 28.7306H142.673V37.2993H143.259V29.6487L149.353 37.2993Z"
                fill="white"/>
            <path
                d="M157.289 32.6232H152.636V29.2692H157.753V28.7306H152.05V37.2993H157.888V36.7607H152.636V33.1618H157.289V32.6232Z"
                fill="white"/>
            <path
                d="M164.835 30.6035V29.5875C164.396 29.1223 163.333 28.5959 161.965 28.5959C160.292 28.5959 159.034 29.3426 159.034 30.7504C159.034 33.8841 164.469 32.5376 164.469 35.1082C164.469 36.32 163.455 36.8831 162.149 36.8831C160.549 36.8831 159.401 36.1854 158.9 35.3652V36.3078C159.352 36.8219 160.549 37.4217 162.088 37.4217C163.956 37.4217 165.08 36.4914 165.08 35.0959C165.08 31.901 159.633 33.2598 159.633 30.7259C159.633 29.7344 160.5 29.159 161.965 29.1345C163.211 29.1345 164.335 29.7344 164.835 30.6035Z"
                fill="white"/>
            <path
                d="M172.054 30.6035V29.5875C171.614 29.1223 170.552 28.5959 169.184 28.5959C167.511 28.5959 166.253 29.3426 166.253 30.7504C166.253 33.8841 171.688 32.5376 171.688 35.1082C171.688 36.32 170.674 36.8831 169.367 36.8831C167.767 36.8831 166.619 36.1854 166.119 35.3652V36.3078C166.571 36.8219 167.767 37.4217 169.306 37.4217C171.175 37.4217 172.298 36.4914 172.298 35.0959C172.298 31.901 166.852 33.2598 166.852 30.7259C166.852 29.7344 167.719 29.159 169.184 29.1345C170.43 29.1345 171.553 29.7344 172.054 30.6035Z"
                fill="white"/>
            <path
                d="M85.15 44.4144V52.9832H87.7635C90.3159 52.9832 92.4164 51.7468 92.4164 48.5274C92.4164 45.8956 90.8044 44.4144 87.9345 44.4144H85.15ZM85.7362 52.4446V44.953H87.8245C90.4014 44.953 91.8058 46.2016 91.8058 48.5886C91.8058 51.1348 90.267 52.4446 87.7024 52.4446H85.7362Z"
                fill="white"/>
            <path
                d="M99.1224 48.3071H94.4695V44.953H99.5865V44.4144H93.8833V52.9832H99.7209V52.4446H94.4695V48.8457H99.1224V48.3071Z"
                fill="white"/>
            <path
                d="M106.669 46.2873V45.2713C106.229 44.8062 105.166 44.2798 103.799 44.2798C102.126 44.2798 100.868 45.0265 100.868 46.4342C100.868 49.5679 106.302 48.2214 106.302 50.792C106.302 52.0039 105.289 52.567 103.982 52.567C102.382 52.567 101.234 51.8692 100.733 51.0491V51.9916C101.185 52.5058 102.382 53.1056 103.921 53.1056C105.789 53.1056 106.913 52.1753 106.913 50.7798C106.913 47.5849 101.466 48.9436 101.466 46.4097C101.466 45.4182 102.333 44.8429 103.799 44.8184C105.044 44.8184 106.168 45.4182 106.669 46.2873Z"
                fill="white"/>
            <path d="M109.122 44.4144H108.535V52.9832H109.122V44.4144Z" fill="white"/>
            <path
                d="M114.947 53.1056C116.388 53.1056 117.707 52.4935 118.159 51.8937V48.6743H114.862V49.2129H117.61V51.6734C117.182 52.1263 116.266 52.5547 115.045 52.567C112.615 52.5547 111.198 50.9756 111.198 48.6988C111.198 46.3608 112.737 44.8429 114.923 44.8306C116.205 44.8306 117.329 45.4304 117.83 46.2996V45.2836C117.39 44.8184 116.327 44.292 114.923 44.292C112.297 44.3043 110.588 46.1649 110.588 48.6866C110.588 51.3918 112.224 53.0689 114.947 53.1056Z"
                fill="white"/>
            <path
                d="M126.54 52.9832H127.163V44.4144H126.577V52.0651L120.483 44.4144H119.86V52.9832H120.446V45.3325L126.54 52.9832Z"
                fill="white"/>
            <path d="M128.956 49.6536H132.083V49.0905H128.956V49.6536Z" fill="white"/>
            <path
                d="M139.351 46.2873V45.2713C138.911 44.8062 137.849 44.2798 136.481 44.2798C134.808 44.2798 133.55 45.0265 133.55 46.4342C133.55 49.5679 138.985 48.2214 138.985 50.792C138.985 52.0039 137.971 52.567 136.664 52.567C135.064 52.567 133.916 51.8692 133.416 51.0491V51.9916C133.868 52.5058 135.064 53.1056 136.603 53.1056C138.472 53.1056 139.595 52.1753 139.595 50.7798C139.595 47.5849 134.149 48.9436 134.149 46.4097C134.149 45.4182 135.016 44.8429 136.481 44.8184C137.727 44.8184 138.85 45.4182 139.351 46.2873Z"
                fill="white"/>
            <path
                d="M147.174 44.4144H146.502L143.486 48.9804L140.408 44.4144H139.725L143.168 49.5557V52.9832H143.755V49.5557L147.174 44.4144Z"
                fill="white"/>
            <path
                d="M153.419 46.2873V45.2713C152.979 44.8062 151.917 44.2798 150.549 44.2798C148.876 44.2798 147.618 45.0265 147.618 46.4342C147.618 49.5679 153.052 48.2214 153.052 50.792C153.052 52.0039 152.039 52.567 150.732 52.567C149.132 52.567 147.984 51.8692 147.483 51.0491V51.9916C147.935 52.5058 149.132 53.1056 150.671 53.1056C152.539 53.1056 153.663 52.1753 153.663 50.7798C153.663 47.5849 148.216 48.9436 148.216 46.4097C148.216 45.4182 149.083 44.8429 150.549 44.8184C151.794 44.8184 152.918 45.4182 153.419 46.2873Z"
                fill="white"/>
            <path d="M161.13 44.4144H153.876V44.953H157.21V52.9832H157.796V44.953H161.13V44.4144Z" fill="white"/>
            <path
                d="M167.35 48.3071H162.697V44.953H167.814V44.4144H162.111V52.9832H167.948V52.4446H162.697V48.8457H167.35V48.3071Z"
                fill="white"/>
            <path
                d="M177.448 52.9832H178.034V44.4144H177.338L173.784 50.131L170.145 44.4144H169.425V52.9832H170.011V45.3325L173.638 50.9512H173.894L177.448 45.2468V52.9832Z"
                fill="white"/>
        </svg>
    );
}
