import React from 'react';
import {NavLink} from 'react-router-dom';
import {TMenuLevel} from './Menu';
import {IStyleguideRoute} from '../../types/types';
import {useTranslation} from 'react-i18next';

/**
 * Свойства компонента.
 *
 * @prop {string} label Лэйбл.
 * @prop {string} path Роут куда перебросит при нажатии на пункт меню.
 * @prop {IStyleguideRoute[]} children масив пунктов, которые являются подменю.
 * @prop {number} menuLevel Уровень вложенности меню.
 */
interface IMenuListLinkProps {
    label: string;
    path: string;
    children?: IStyleguideRoute[];
    menuLevel?: TMenuLevel;
}

/**
 * Ссылка пункта меню.
 */
const MenuListLink: React.FC<IMenuListLinkProps> = ({label, path, menuLevel}) => {
    const {i18n} = useTranslation();

    return (
        <li className="menu-list-item"  data-label={label}>
            <NavLink
                to={(location: any) => '/' + i18n.language + '/' + path + location.search}
                className="menu-list-link"
                activeClassName="active"
                isActive={(match) => Boolean(match)}
            >
                <span>{label}</span>
            </NavLink>
        </li>
    );
}

export default MenuListLink;
