import React from 'react';
import {EAppTheme} from '../ThemeSwitcher/ThemeSwitcher';
import {LogoSberThemeWhite} from './LogoSberThemeWhite';
import {LogoSberThemeDark} from './LogoSberThemeDark';

interface ILogoSberIconProps {
    theme: EAppTheme;
}

const LogoSberIcon: React.FC<ILogoSberIconProps> = ({theme}) => theme === EAppTheme.DEFAULT ? <LogoSberThemeWhite /> : <LogoSberThemeDark />;

export default LogoSberIcon;
