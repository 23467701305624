import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {SectionNotTranslatedIcon} from "./SectionNotTranslatedIcon";
import './sectionNotTranslated.scss';

const SectionNotTranslated: React.FC = () => {
    const {t} = useTranslation();
    const history = useHistory();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <div className="section-not-translated">
            <div className="section-not-translated-icon-wrapper">
                <SectionNotTranslatedIcon />
            </div>

            <div className="section-not-translated-label">
                {t('section not translated')}
            </div>

            <a
                href="#"
                className="section-not-translated-link"
                onClick={handleClick}
            >
                {t('section not translated link')}
            </a>
        </div>
    );
}

export default observer(SectionNotTranslated);
