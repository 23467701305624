import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

/** Компонент авто-скролла к указанной в адресе node. */
export const ScrollToAnchor = (): null => {
    const location = useLocation();

    useEffect(() => {
        const {hash} = location;

        if (hash) {
            const xPathSelector = `[id='${hash.substring(1)}']`;
            const node = document.querySelector(xPathSelector);
            if (node) {
                node.scrollIntoView({behavior: 'smooth'});
            } else {
                console.warn(`Не был найден элемент '${xPathSelector}' из якорной ссылки '${hash}'.`);
            }
        }
    }, [location]);

    return null;
};
