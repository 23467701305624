import React, {useContext, useEffect} from 'react';
import {observer} from 'mobx-react';
import {TImageNode} from '../../../types/types';
import {StoreContext} from '../../StoreContext/StoreContext';
import { Skeleton } from '../../Skeleton/Skeleton';

export interface IImageProps {
    children?: never;
    node: TImageNode;
}

const Image: React.FC<IImageProps> = ({node}) => {
    const {imagesStore} = useContext(StoreContext);

    useEffect(() => {
        if (!imagesStore.images[node.image.id]) {
            imagesStore.loadImage(node.image.id);
        }

    }, [imagesStore.images[node.image.id]])

    if (!imagesStore.images[node.image.id]) {
        return (
            <Skeleton style={{width: node.content.absoluteBoundingBox.width, height: node.content.absoluteBoundingBox.height}} />
        )
    }

    return (
        <img
            id={node.nodeFigmaId}
            className="board-image"
            style={{maxWidth: node.content.absoluteBoundingBox.width + 'px'}}
            src={`data:image/jpeg;base64,${imagesStore.images[node.image.id]}`}
            alt=""
        />
    );
}

export default observer(Image);
