import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import {elementRegExp} from '../../../utils/StyleguideUtils';
import NodeUtils from '../../../utils/NodeUtils';
import BoardContentsItem from './BoardContentsItem';

interface IBoardContentsProps {
    boardNode: IBoard;
    children?: never;
}

/**
 * Содержание страницы.
 * Рендерит якорные ссылки на разделы страницы.
 */
const BoardContents: React.FC<IBoardContentsProps> = observer(({boardNode}) => {
    const [subHeaderNodes, setSubHeaderNodes] = useState<INode[]>([]);

    useEffect(() => {
        const findSubHeaders = (node: INode) => {
            if (node.content.children) {
                const match = node.content.name.match(elementRegExp);

                if (match) {
                    const nodeName = match[3] || match[5];
                    if (nodeName === 'Sub Header L') {
                        setSubHeaderNodes(prevSubHeaderNodes => {
                            const nextSubHeaderNodes = [...prevSubHeaderNodes, node];
                            nextSubHeaderNodes.sort((node1, node2) => {
                                return node1.content.absoluteBoundingBox.y - node2.content.absoluteBoundingBox.y
                            });
                            return nextSubHeaderNodes;
                        });
                    }
                }

                NodeUtils.getChildrenNodes(boardNode, node).forEach(findSubHeaders)
            }
        }

        boardNode.children.forEach(nodeId => {
            const node = boardNode.nodes.filter(n => n.nodeFigmaId === nodeId)[0];
            findSubHeaders(node);
        })
    }, []);

    if (!subHeaderNodes.length) {
        return null;
    }

    return (
        <ul className="board-list">
            {subHeaderNodes.map(node => <BoardContentsItem boardNode={boardNode} node={node} key={node.nodeFigmaId} />)}
        </ul>
    );
});

export default BoardContents;
