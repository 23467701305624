import React from 'react';

export const NotFoundBoardIcon: React.FC = () => (
    <svg width="211" height="74" viewBox="0 0 211 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M50.5156 49.707V53.2715H0.710938V51.0742L34.2559 1.02539H37.6738L31.2773 11.7676L6.0332 49.707H50.5156ZM38.6992 1.02539V72.1191H34.793V1.02539H38.6992Z"
            fill="#7D838A"/>
        <path
            d="M210.516 49.707V53.2715H160.711V51.0742L194.256 1.02539H197.674L191.277 11.7676L166.033 49.707H210.516ZM198.699 1.02539V72.1191H194.793V1.02539H198.699Z"
            fill="#7D838A"/>
        <path
            d="M101.484 30.2246V42.8711C101.484 47.7214 101.012 52.0345 100.068 55.8105C99.1244 59.554 97.7409 62.7116 95.918 65.2832C94.0951 67.8548 91.849 69.8079 89.1797 71.1426C86.543 72.4447 83.4994 73.0957 80.0488 73.0957C77.3145 73.0957 74.8242 72.6725 72.5781 71.8262C70.332 70.9798 68.3301 69.7266 66.5723 68.0664C64.8145 66.4062 63.3333 64.3555 62.1289 61.9141C60.9245 59.4727 60.013 56.6569 59.3945 53.4668C58.776 50.2767 58.4668 46.7448 58.4668 42.8711V30.2246C58.4668 25.3092 58.9388 20.9961 59.8828 17.2852C60.8594 13.5417 62.2591 10.3841 64.082 7.8125C65.9049 5.24089 68.151 3.30404 70.8203 2.00195C73.4896 0.667318 76.5332 0 79.9512 0C82.6855 0 85.1758 0.423177 87.4219 1.26953C89.7005 2.11589 91.7025 3.36914 93.4277 5.0293C95.1855 6.68945 96.6504 8.74023 97.8223 11.1816C99.0267 13.623 99.9381 16.4388 100.557 19.6289C101.175 22.7865 101.484 26.3184 101.484 30.2246ZM97.5781 43.0664V29.8828C97.5781 26.6602 97.334 23.6816 96.8457 20.9473C96.39 18.2129 95.6901 15.7715 94.7461 13.623C93.8346 11.4746 92.6628 9.65169 91.2305 8.1543C89.8307 6.6569 88.1869 5.51758 86.2988 4.73633C84.4108 3.95508 82.2949 3.56445 79.9512 3.56445C77.0215 3.56445 74.4499 4.18294 72.2363 5.41992C70.0228 6.62435 68.1836 8.36589 66.7188 10.6445C65.2865 12.9232 64.196 15.6901 63.4473 18.9453C62.7311 22.2005 62.373 25.8464 62.373 29.8828V43.0664C62.373 46.2565 62.6009 49.2188 63.0566 51.9531C63.5124 54.6549 64.2122 57.0964 65.1562 59.2773C66.1003 61.4583 67.2721 63.3138 68.6719 64.8438C70.1042 66.3411 71.7643 67.4967 73.6523 68.3105C75.5404 69.1244 77.6725 69.5312 80.0488 69.5312C83.0111 69.5312 85.5827 68.9128 87.7637 67.6758C89.9772 66.4062 91.8001 64.6159 93.2324 62.3047C94.6973 59.9935 95.7878 57.2103 96.5039 53.9551C97.2201 50.6999 97.5781 47.0703 97.5781 43.0664Z"
            fill="#7D838A"/>
        <path
            d="M126.484 30.2246V42.8711C126.484 47.7214 126.012 52.0345 125.068 55.8105C124.124 59.554 122.741 62.7116 120.918 65.2832C119.095 67.8548 116.849 69.8079 114.18 71.1426C111.543 72.4447 108.499 73.0957 105.049 73.0957C102.314 73.0957 99.8242 72.6725 97.5781 71.8262C95.332 70.9798 93.3301 69.7266 91.5723 68.0664C89.8145 66.4062 88.3333 64.3555 87.1289 61.9141C85.9245 59.4727 85.013 56.6569 84.3945 53.4668C83.776 50.2767 83.4668 46.7448 83.4668 42.8711V30.2246C83.4668 25.3092 83.9388 20.9961 84.8828 17.2852C85.8594 13.5417 87.2591 10.3841 89.082 7.8125C90.9049 5.24089 93.151 3.30404 95.8203 2.00195C98.4896 0.667318 101.533 0 104.951 0C107.686 0 110.176 0.423177 112.422 1.26953C114.701 2.11589 116.702 3.36914 118.428 5.0293C120.186 6.68945 121.65 8.74023 122.822 11.1816C124.027 13.623 124.938 16.4388 125.557 19.6289C126.175 22.7865 126.484 26.3184 126.484 30.2246ZM122.578 43.0664V29.8828C122.578 26.6602 122.334 23.6816 121.846 20.9473C121.39 18.2129 120.69 15.7715 119.746 13.623C118.835 11.4746 117.663 9.65169 116.23 8.1543C114.831 6.6569 113.187 5.51758 111.299 4.73633C109.411 3.95508 107.295 3.56445 104.951 3.56445C102.021 3.56445 99.4499 4.18294 97.2363 5.41992C95.0228 6.62435 93.1836 8.36589 91.7188 10.6445C90.2865 12.9232 89.196 15.6901 88.4473 18.9453C87.7311 22.2005 87.373 25.8464 87.373 29.8828V43.0664C87.373 46.2565 87.6009 49.2188 88.0566 51.9531C88.5124 54.6549 89.2122 57.0964 90.1562 59.2773C91.1003 61.4583 92.2721 63.3138 93.6719 64.8438C95.1042 66.3411 96.7643 67.4967 98.6523 68.3105C100.54 69.1244 102.673 69.5312 105.049 69.5312C108.011 69.5312 110.583 68.9128 112.764 67.6758C114.977 66.4062 116.8 64.6159 118.232 62.3047C119.697 59.9935 120.788 57.2103 121.504 53.9551C122.22 50.6999 122.578 47.0703 122.578 43.0664Z"
            fill="#7D838A"/>
        <path
            d="M153.484 30.2246V42.8711C153.484 47.7214 153.012 52.0345 152.068 55.8105C151.124 59.554 149.741 62.7116 147.918 65.2832C146.095 67.8548 143.849 69.8079 141.18 71.1426C138.543 72.4447 135.499 73.0957 132.049 73.0957C129.314 73.0957 126.824 72.6725 124.578 71.8262C122.332 70.9798 120.33 69.7266 118.572 68.0664C116.814 66.4062 115.333 64.3555 114.129 61.9141C112.924 59.4727 112.013 56.6569 111.395 53.4668C110.776 50.2767 110.467 46.7448 110.467 42.8711V30.2246C110.467 25.3092 110.939 20.9961 111.883 17.2852C112.859 13.5417 114.259 10.3841 116.082 7.8125C117.905 5.24089 120.151 3.30404 122.82 2.00195C125.49 0.667318 128.533 0 131.951 0C134.686 0 137.176 0.423177 139.422 1.26953C141.701 2.11589 143.702 3.36914 145.428 5.0293C147.186 6.68945 148.65 8.74023 149.822 11.1816C151.027 13.623 151.938 16.4388 152.557 19.6289C153.175 22.7865 153.484 26.3184 153.484 30.2246ZM149.578 43.0664V29.8828C149.578 26.6602 149.334 23.6816 148.846 20.9473C148.39 18.2129 147.69 15.7715 146.746 13.623C145.835 11.4746 144.663 9.65169 143.23 8.1543C141.831 6.6569 140.187 5.51758 138.299 4.73633C136.411 3.95508 134.295 3.56445 131.951 3.56445C129.021 3.56445 126.45 4.18294 124.236 5.41992C122.023 6.62435 120.184 8.36589 118.719 10.6445C117.286 12.9232 116.196 15.6901 115.447 18.9453C114.731 22.2005 114.373 25.8464 114.373 29.8828V43.0664C114.373 46.2565 114.601 49.2188 115.057 51.9531C115.512 54.6549 116.212 57.0964 117.156 59.2773C118.1 61.4583 119.272 63.3138 120.672 64.8438C122.104 66.3411 123.764 67.4967 125.652 68.3105C127.54 69.1244 129.673 69.5312 132.049 69.5312C135.011 69.5312 137.583 68.9128 139.764 67.6758C141.977 66.4062 143.8 64.6159 145.232 62.3047C146.697 59.9935 147.788 57.2103 148.504 53.9551C149.22 50.6999 149.578 47.0703 149.578 43.0664Z"
            fill="#7D838A"/>
    </svg>
);


