import React, {useLayoutEffect} from 'react';
import {Logo} from '../Logo/Logo';
import {ChannelSuperIcon} from './icons/ChannelSuperIcon';
import {ChannelMobileIcon} from './icons/ChannelMobileIcon';
import {ChannelDesktopIcon} from './icons/ChannelDesktopIcon';
import {ChannelWebIcon} from './icons/ChannelWebIcon';
import {ChannelTextIcon} from './icons/ChannelTextIcon';
import {LogoThemeDark} from '../Logo/LogoThemeDark';
import ChannelButton from './components/ChannelButton/ChannelButton';
import {LogoSberMainPage} from '../Logo/LogoSberMainPage';
import {Helmet} from 'react-helmet';
import {EChannel} from "../../types/types";
import {Trans, useTranslation} from 'react-i18next';
import LanguageControl, {EAppLanguage, ELanguageControlTheme } from "../LanguageControl/LanguageControl";
import './styles/startPage.scss';

const imageUrl = `${process.env.PUBLIC_URL}/images/start-page-background.jpg`;
const videoUrl = `${process.env.PUBLIC_URL}/resources/video/start-page-background.mp4`;

const StartPage: React.FC = () => {
    const {i18n} = useTranslation();

    useLayoutEffect(() => {
        document.body.classList.add('start-page-body');

        return () => document.body.classList.remove('start-page-body');
    });

    return (
        <div className="start-page">

            <Helmet>
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type"               content="website" />
            </Helmet>

            <video autoPlay loop muted playsInline poster={imageUrl}>
                <source src={videoUrl} type="video/mp4" />
            </video>

            <div className="gradient left" />
            <div className="gradient right" />

            <header className="start-page-header">
                <Logo logoIcon={<LogoThemeDark className="logo-icon-start-page" />} />
                <LogoSberMainPage className="logo-sber" />
            </header>

            <main>
                <h1>
                    <Trans i18nKey="main h1" />
                </h1>
                <p>
                    <Trans i18nKey="main description" />
                </p>
                <div className="button-group">
                    <ChannelButton pathname={`/${i18n.language}/${EChannel.super}`} icon={<ChannelSuperIcon className="channel-icon" />} label="Super" />
                    <ChannelButton pathname={`/${i18n.language}/${EChannel.web}`} icon={<ChannelWebIcon className="channel-icon" />} label="Web" />
                    <ChannelButton pathname={`/${i18n.language}/${EChannel.mobile}`} icon={<ChannelMobileIcon className="channel-icon" />} label="Mobile" />

                    {i18n.language === EAppLanguage.ru ? (
                        <>
                            {/** Раздел, для которых еще нет перевода. */}
                            <ChannelButton pathname={`/${i18n.language}/${EChannel.desktop}/Hello!/release notes`} icon={<ChannelDesktopIcon className="channel-icon" />} label="Desktop" />
                        </>
                    ) : (
                        <>
                            {/** Раздел, для которых еще нет перевода. */}
                            <ChannelButton pathname={`/${i18n.language}/${EChannel.desktop}/sectionNotTranslated`} icon={<ChannelDesktopIcon className="channel-icon" />} label="Desktop" />
                        </>
                    )}

                    <ChannelButton pathname={`/${i18n.language}/${EChannel.super}/Text/release%20notes`} icon={<ChannelTextIcon className="channel-icon" />} label="Text" />
                </div>
            </main>

            <footer>
                <LanguageControl theme={ELanguageControlTheme.main} />
                <Trans i18nKey="main copyright" values={{dateEnd: new Date().getFullYear()}} />
            </footer>
        </div>
    );
}

export default StartPage;
