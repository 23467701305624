import React from 'react';
import {IChannelIconProps} from './ChannelSuperIcon';

export const ChannelMobileIcon: React.FC<IChannelIconProps> = ({className}) => (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
        <circle cx="12" cy="12" r="12" fill="#64E3FF" />
        <path
            d="M7.29873 17H9.02073V11.106C9.02073 10.49 9.00673 10.21 8.96473 9.608H9.00673C9.23073 10.21 9.32873 10.476 9.59473 11.106L11.2607 14.886H12.7447L14.4107 11.106C14.6767 10.476 14.7887 10.21 14.9987 9.608H15.0407C14.9847 10.21 14.9847 10.49 14.9847 11.106V17H16.7067V7.2H14.5087L12.0447 12.87L9.56673 7.2H7.29873V17Z"
            fill="#1F1F22"
        />
    </svg>
);
