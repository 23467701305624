import React, {useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import BoardNode from "./BoardNode";
import {IBoard, INode} from "../../../types/types";
import NodeUtils from "../../../utils/NodeUtils";

interface IBoardSpoilerProps {
    boardNode: IBoard;
    node: INode;
    children?: never;
}

const BoardSpoiler: React.FC<IBoardSpoilerProps> = ({boardNode, node}) => {
    const [labelNode, setLabelNode] = useState<INode>();
    const [contentNode, setContentNode] = useState<INode>();
    const [open, setOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        for (const childId of node.content.children) {
            const node = NodeUtils.getBoardNodeByNodeId(boardNode, childId);

            if (node) {
                if (node.content.name.includes('Label')) {
                    setLabelNode(node);
                } else if (node.content.name.includes('Content')) {
                    setContentNode(node);
                }
            }
        }
    }, []);

    const handleClick = () => setOpen(!open);

    const renderLabel = () => {
        if (labelNode && labelNode.content.characters) {
            const [closedLabel, openLabel] = labelNode.content.characters.split('/');

            return (
                <button className="board-spoiler-target" onClick={handleClick}>
                    {open ? openLabel : closedLabel}
                </button>
            );
        }
    };

    const renderContent = () => {
        if (contentNode) {
            const maxHeight = open ? contentRef.current?.scrollHeight : 0;

            return (
                <div className="board-spoiler-content" style={{maxHeight}} ref={contentRef}>
                    <BoardNode key={contentNode.id} boardNode={boardNode} node={contentNode} />
                </div>
            );
        }
    }

    return (
        <div id={node.nodeFigmaId} className="board-spoiler">
            {renderContent()}
            {renderLabel()}
        </div>
    );
}

export default observer(BoardSpoiler);
