import React from 'react';
import {useTranslation} from "react-i18next";
import {EAppLanguage} from "../LanguageControl/LanguageControl";

export interface ILogoSberMainPageProps {
    className?: string;
}

export const LogoSberMainPage: React.FC<ILogoSberMainPageProps> = ({className}) => {
    const {i18n} = useTranslation();

    return i18n.language === EAppLanguage.ru ? (
        <svg width="127" height="50" className={className} viewBox="0 0 127 50" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M45.625 35H55.7554V36.6874H47.5263V40.0623H50.4258C54.5849 40.0623 56.6852 41.5451 56.6852 44.8547C56.6852 48.1169 54.4661 49.7747 50.4258 49.7747H45.625V35ZM47.5263 41.7557V48.0843H50.3634C53.1738 48.0843 54.6978 47.1946 54.6978 44.7302C54.6978 42.5119 53.3431 41.7646 50.298 41.7646L47.5263 41.7557Z"
                fill="white"/>
            <path
                d="M59.5342 49.7747V39.2201H61.2899V47.073L67.2552 39.2201H68.8001V49.7747H67.0414V41.8565L61.2899 49.5226L61.0998 49.7747H59.5342Z"
                fill="white"/>
            <path
                d="M71.542 46.6519C72.1362 47.6632 73.4879 48.4668 75.404 48.4668C76.8627 48.4461 77.9203 47.853 77.9203 46.6875C77.9203 45.5665 77.0528 45.1661 75.404 45.1661H73.8147V43.7723H75.0832C76.4171 43.7723 77.3677 43.2652 77.3677 42.0848C77.3677 41.0914 76.8597 40.5843 75.5051 40.5427C74.7484 40.5361 74.0015 40.7133 73.3288 41.0589C72.656 41.4045 72.0774 41.9083 71.643 42.5267V40.0356C72.8138 39.3529 74.1494 39.0032 75.5051 39.0243H75.5467C78.2204 39.0243 79.1443 40.1009 79.1443 41.7675C79.1443 43.0991 78.5501 43.6892 77.5163 44.1756C78.9126 44.4484 79.6731 45.1869 79.6731 46.5807C79.6731 48.9532 77.748 50.0001 75.4011 50.0001C73.4344 50.0001 72.0797 49.3684 71.5391 48.713L71.542 46.6519Z"
                fill="white"/>
            <path
                d="M89.664 45.2166H84.079V49.7747H82.3232V39.2201H84.079V43.6329H89.664V39.2201H91.4198V49.7747H89.664V45.2166Z"
                fill="white"/>
            <path
                d="M103.891 43.9888V45.1305H95.7988C95.9681 47.4111 97.1951 48.4669 99.2687 48.4669C101.342 48.4669 102.59 47.6632 103.16 46.6489V48.6982C102.632 49.3536 101.339 49.9852 99.2687 49.9852C95.9265 49.9852 94.0015 48.1495 94.0015 44.4544C94.0015 40.7592 96.2444 39.0095 99.2241 39.0095C102.144 39.0095 103.9 40.866 103.9 43.9888H103.891ZM95.7988 43.6329H102.124V43.6003C102.124 41.5748 100.876 40.519 99.2033 40.519C97.2159 40.519 96.0335 41.5748 95.7988 43.624"
                fill="white"/>
            <path
                d="M110.929 49.9851C107.587 49.9851 105.662 48.1494 105.662 44.4543C105.662 40.7591 107.905 39.0094 110.887 39.0094C112.092 38.9935 113.273 39.3464 114.271 40.0207V42.5118C113.928 41.9163 113.436 41.4202 112.842 41.0724C112.249 40.7245 111.575 40.5368 110.887 40.5278C108.665 40.5278 107.438 41.8564 107.438 44.4543V44.5195C107.438 47.2212 108.686 48.4668 110.929 48.4668C112.685 48.4668 113.846 47.6631 114.375 46.6518V48.6981C113.891 49.3535 112.726 49.9851 110.929 49.9851Z"
                fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M56.2201 24.0232C57.5955 24.0232 58.9288 23.6884 60.0732 23.0565L61.0629 22.5157L63.9513 24.646C62.0469 26.6898 59.237 27.8125 56.0188 27.8125C52.9427 27.8125 50.1374 26.7251 48.1193 24.7508C46.108 22.7832 45 20.0562 45 17.0731C45 14.1036 46.1114 11.4164 48.1307 9.50696C50.1374 7.60775 52.9495 6.5625 56.0472 6.5625C58.9026 6.5625 61.4008 7.43127 63.2698 9.07543L63.4814 9.26102L60.2802 11.6225C59.1074 10.7788 57.7422 10.3507 56.2201 10.3507C52.1965 10.3507 49.2751 13.2257 49.2751 17.1869C49.2751 21.1482 52.1953 24.0232 56.2201 24.0232ZM77.6321 14.9046H72.2001V10.2807H80.872L85.5452 6.83297H67.7361V27.5387H77.0565C82.2837 27.5387 85.2825 25.1681 85.2825 21.0338C85.2825 17.0817 82.5647 14.9046 77.6321 14.9046ZM76.8551 24.091H72.2001V18.3512H76.8551C79.6889 18.3512 81.0085 19.3111 81.0085 21.2205C81.0073 23.1721 79.6104 24.091 76.8551 24.091ZM105.08 6.83297L100.407 10.2807H93.9021V15.343H103.436V18.7907H93.9021V24.091H105.08V27.5387H89.4381V6.83297H105.08ZM109.236 6.83297H117.752C123.187 6.83297 126.562 9.67268 126.562 14.2431C126.562 18.8135 123.186 21.6737 117.752 21.6737H113.7V27.5387H109.236V6.83297ZM113.7 18.226H117.656C120.517 18.226 122.092 16.8152 122.092 14.2533C122.092 11.6914 120.516 10.2807 117.656 10.2807H113.7V18.226Z"
                  fill="white"/>
            <path
                d="M17.1874 16.7041L10.645 12.6026V17.5367L17.1874 21.6382L32.8485 10.0969C32.2946 8.87527 31.6008 7.72989 30.7887 6.68005L17.1874 16.7041Z"
                fill="white"/>
            <path
                d="M34.375 17.1875C34.375 16.1365 34.2806 15.1072 34.0998 14.1085L30.3997 16.8349C30.4031 16.9521 30.4043 17.0692 30.4043 17.1875C30.4043 24.4749 24.4749 30.4043 17.1875 30.4043C9.90008 30.4043 3.97072 24.4749 3.97072 17.1875C3.97072 9.90007 9.90008 3.97072 17.1875 3.97072C19.9492 3.97072 22.5163 4.82265 24.6387 6.2774L27.9816 3.81376C25.0311 1.42973 21.2765 0 17.1875 0C7.69463 0 0 7.69462 0 17.1875C0 26.6804 7.69463 34.375 17.1875 34.375C26.6804 34.375 34.375 26.6804 34.375 17.1875Z"
                fill="white"/>
        </svg>

    ) : (
        <svg width="151" height="60" className={className} viewBox="0 0 151 60" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M58.7676 59.5935C63.0207 59.5935 65.5676 58.1346 65.5676 54.5491C65.5676 52.2989 64.5786 50.8647 62.2789 50.568C64.1335 49.9746 65.0484 48.5898 65.0484 46.488C65.0484 43.5455 62.9466 42.2844 59.5342 42.2844H52.3633V59.5935H58.7676ZM54.5887 57.6153V51.7055H59.1633C61.5866 51.7055 63.3175 52.3484 63.3175 54.5738C63.3175 56.7004 61.8091 57.6153 59.0891 57.6153H54.5887ZM54.5887 49.9251V44.2626H59.0396C61.6607 44.2626 62.8229 45.1527 62.8229 46.9084C62.8229 48.8618 61.092 49.9251 58.9655 49.9251H54.5887Z"
                fill="white"/>
            <path
                d="M73.9556 57.9862C71.8291 57.9862 71.0131 57.0713 71.0131 54.8706V47.2298H68.9607V55.3651C68.9607 58.3818 70.3949 59.7666 73.2138 59.816H73.2632H73.3127C75.6123 59.7913 77.1949 58.6044 77.64 56.9724V59.5935H79.5934V47.2298H77.5411V53.8815C77.5411 56.4531 76.4778 57.9615 73.9556 57.9862Z"
                fill="white"/>
            <path
                d="M91.5869 51.0873V48.1695C90.7709 47.5513 89.2873 46.9826 87.6306 46.9826C84.4902 46.9826 83.0313 48.4909 83.0313 50.4691C83.0313 54.7469 89.9549 53.7331 89.9549 56.2306C89.9549 57.368 89.0153 58.0604 87.3338 58.0604C85.232 58.0604 83.6495 57.1207 82.9324 55.9338V58.3324C83.6 59.0989 85.1578 59.8407 87.3338 59.8407C90.3753 59.816 92.0073 58.4066 92.0073 56.1564C92.0073 51.8786 85.0836 52.9913 85.0836 50.3949C85.0836 49.2822 86.1964 48.7629 87.5811 48.7629H87.6306C89.312 48.7629 90.7462 49.7026 91.5869 51.0873Z"
                fill="white"/>
            <path
                d="M95.2139 42.0124C95.2139 42.8037 95.7579 43.3229 96.5986 43.3229C97.4146 43.3229 97.9586 42.8037 97.9586 42.0124C97.9586 41.2458 97.4146 40.7266 96.5986 40.7266C95.7579 40.7266 95.2139 41.2458 95.2139 42.0124ZM95.5601 59.5935H97.6124V47.2298H95.5601V59.5935Z"
                fill="white"/>
            <path
                d="M110.656 59.5935H112.708V51.4582C112.708 48.4415 111.224 47.032 108.406 47.0073H108.356C105.982 47.0073 104.326 48.2189 103.88 49.8509V47.2298H101.927V59.5935H103.979V52.9418C103.979 50.3455 105.092 48.8371 107.713 48.8371H107.787C109.864 48.8618 110.656 49.7767 110.656 51.9527V59.5935Z"
                fill="white"/>
            <path
                d="M121.632 46.9826C118.145 46.9826 115.524 49.0844 115.524 53.3622C115.524 57.6895 117.774 59.8407 121.681 59.8407C124.105 59.8407 125.613 59.0989 126.231 58.3324V55.9338C125.564 57.1207 124.055 58.0604 121.681 58.0604C119.258 58.0604 117.824 56.824 117.626 54.1535H127.097V52.8182C127.097 49.1586 125.044 46.9826 121.632 46.9826ZM121.632 48.7629C123.585 48.7629 125.044 49.9993 125.044 52.3731V52.3978H117.651C117.923 49.9993 119.308 48.7629 121.632 48.7629Z"
                fill="white"/>
            <path
                d="M138.144 51.0873V48.1695C137.328 47.5513 135.844 46.9826 134.187 46.9826C131.047 46.9826 129.588 48.4909 129.588 50.4691C129.588 54.7469 136.512 53.7331 136.512 56.2306C136.512 57.368 135.572 58.0604 133.891 58.0604C131.789 58.0604 130.206 57.1207 129.489 55.9338V58.3324C130.157 59.0989 131.715 59.8407 133.891 59.8407C136.932 59.816 138.564 58.4066 138.564 56.1564C138.564 51.8786 131.64 52.9913 131.64 50.3949C131.64 49.2822 132.753 48.7629 134.138 48.7629H134.187C135.869 48.7629 137.303 49.7026 138.144 51.0873Z"
                fill="white"/>
            <path
                d="M149.807 51.0873V48.1695C148.991 47.5513 147.507 46.9826 145.851 46.9826C142.71 46.9826 141.251 48.4909 141.251 50.4691C141.251 54.7469 148.175 53.7331 148.175 56.2306C148.175 57.368 147.235 58.0604 145.554 58.0604C143.452 58.0604 141.87 57.1207 141.153 55.9338V58.3324C141.82 59.0989 143.378 59.8407 145.554 59.8407C148.595 59.816 150.227 58.4066 150.227 56.1564C150.227 51.8786 143.304 52.9913 143.304 50.3949C143.304 49.2822 144.417 48.7629 145.801 48.7629H145.851C147.532 48.7629 148.966 49.7026 149.807 51.0873Z"
                fill="white"/>
            <path
                d="M63.3115 17.9127C60.4919 17.2595 58.0573 16.6944 58.0573 14.7625C58.0573 12.1721 61.3007 11.8922 62.6951 11.8922C64.6283 11.8922 65.7273 12.1537 67.1672 12.7964C67.8664 12.2904 71.0537 9.98131 71.0537 9.98131C68.952 8.66576 65.7968 7.63672 62.7299 7.63672C55.4182 7.63672 52.8125 11.4598 52.8125 15.0372C52.8125 20.1889 57.3701 21.2614 61.3903 22.2089C64.2152 22.8739 66.6538 23.4482 66.6538 25.4117C66.6538 26.4197 66.0428 28.1085 61.9478 28.1085C59.2298 28.1085 56.2538 27.1701 54.1401 25.6548L52.3633 29.6501C54.5171 31.3008 58.2525 32.364 61.9144 32.364C67.98 32.364 71.8999 29.4727 71.8999 24.9977C71.8973 19.9024 67.3356 18.8458 63.3115 17.9127Z"
                fill="white"/>
            <path
                d="M94.1106 19.6239L93.8593 19.5424L94.0919 19.4189C96.0933 18.3517 97.1949 16.5013 97.1949 14.2106C97.1949 10.3533 93.8686 8.05078 88.2976 8.05078H76.7812V31.9501H88.9768C95.0438 31.9501 98.3835 29.6528 98.3835 25.4801C98.3848 22.6401 96.8273 20.5058 94.1106 19.6239ZM82.0274 12.029H87.6171C91.1413 12.029 91.8806 13.6258 91.8806 14.9505C91.8806 16.8732 90.406 17.872 87.6171 17.872H82.0274V12.029ZM82.0274 17.872H87.6171H82.0274ZM88.5704 27.9693H82.0287V21.8502H88.5704C91.5571 21.8502 93.0718 22.8687 93.0718 24.9098C93.0718 26.9573 91.5985 27.9693 88.5704 27.9693Z"
                fill="white"/>
            <path
                d="M116.159 12.0303L121.652 8.05078H103.27V31.9501H121.652V27.9706H108.516V21.8528H119.721V17.8734H108.516V12.0303H116.159Z"
                fill="white"/>
            <path
                d="M147.272 31.9501L141.931 24.1856C145.082 22.8346 146.898 20.1719 146.898 16.6038C146.898 11.3285 142.93 8.05078 136.543 8.05078H126.535V31.9501H131.781V25.1805H136.543C136.693 25.1805 136.84 25.1765 136.987 25.1726L141.649 31.9501H147.272ZM131.783 12.0303H136.433C139.794 12.0303 141.645 13.6586 141.645 16.6156C141.645 19.5726 139.794 21.201 136.433 21.201H131.783V12.0303Z"
                fill="white"/>
            <path
                d="M19.9997 19.4378L12.3867 14.6651V20.4066L19.9997 25.1793L38.2235 11.7493C37.579 10.3279 36.7716 8.99506 35.8266 7.77344L19.9997 19.4378Z"
                fill="white"/>
            <path
                d="M40 20C40 18.7771 39.8901 17.5792 39.6797 16.4172L35.3742 19.5897C35.3782 19.726 35.3795 19.8624 35.3795 20C35.3795 28.4799 28.4799 35.3795 20 35.3795C11.5201 35.3795 4.62048 28.4799 4.62048 20C4.62048 11.5201 11.5201 4.62048 20 4.62048C23.2136 4.62048 26.2008 5.61181 28.6705 7.30461L32.5604 4.43783C29.1271 1.66369 24.7581 0 20 0C8.95374 0 0 8.95374 0 20C0 31.0463 8.95374 40 20 40C31.0463 40 40 31.0463 40 20Z"
                fill="white"/>
        </svg>
    )
}
