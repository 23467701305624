import React from 'react';
import {observer} from 'mobx-react';
import {ISearchItem} from '../Search/Search';
import SearchResultItem from "./SearchResultItem";
import './SearchResult.scss';

interface ISearchResultProps extends React.HTMLAttributes<HTMLDivElement> {
    query: string;
    searchList: ISearchItem[];
}

const SearchResult = React.forwardRef<HTMLDivElement, ISearchResultProps>(({searchList, query, ...rest}, ref) => (
        <div className="search-result" {...rest} ref={ref}>
            {searchList.map(item => <SearchResultItem key={item.fullPath} item={item} query={query} />)}
        </div>
    )
);

SearchResult.displayName = 'SearchResult';

export default observer(SearchResult);
