import React from 'react';
import {LoaderCircleBigIcon} from './LoaderCircleBigIcon';
import './Spinner.scss';

/**
 * Интерфейс для свойств компонента Spinner.
 * @prop {string} [message] Сообщение под спинером.
 * @prop {React.ReactNode} [children] Сообщение-потомки под спинером.
 */
interface ISpinnerProps {
    message?: string;
    children?: React.ReactNode;
}

/**
 * Стандартный спиннер.
 *
 * @param {ISpinnerProps} props Параметры спиннера.
 */
export const Spinner: React.SFC<ISpinnerProps> = (props: ISpinnerProps = {}) => {
    const {message, children} = props;

    return (
        <div className="spinner">
            <LoaderCircleBigIcon />
            {children || <div className="text-wrapper">{message}</div>}
        </div>
    );
};

Spinner.displayName = 'Spinner';

export default Spinner;
