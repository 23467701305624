import React from 'react';
import {convertColor} from '../../../utils/StyleguideUtils';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';

interface IBoardWarningProps {
    boardNode: IBoard;
    node: INode;
    children?: never;
}

const BoardWarning: React.FC<IBoardWarningProps> = observer(({boardNode, node}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {backgroundColor, cornerRadius, paddingTop, paddingRight, paddingBottom, paddingLeft} = node.content;
    const styles = {
        borderRadius: `${cornerRadius}px`,
        backgroundColor: convertColor(backgroundColor),
        padding: `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`,
    };

    return (
        <div className="board-warning" style={{...styles}} id={node.nodeFigmaId}>
            <BoardNode boardNode={boardNode} node={node} />
        </div>
    );
});

export default BoardWarning;
