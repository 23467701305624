import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {Player, Controls} from '@lottiefiles/react-lottie-player';
import throttle from "lodash-es/throttle";

export interface IAnimationProps {
    fileName: string;
}

/**
 * Компонент воспроизведение Lottiе анимации.
 */
const Animation: React.FC<IAnimationProps> = ({fileName}) => {
    // Анимация проигрывается.
    const [isPlay, setIsPlay] = useState(false);
    const lottieRef = useRef<any>();
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!lottieRef.current) {
            return;
        }
        if (isPlay) {
            lottieRef.current.play();
        } else {
            lottieRef.current.pause();
        }
    }, [isPlay])

    // Включение анимации при скролле страницы.
    useEffect(() => {
        const playVideo = throttle(function () {
            if (!containerRef.current) {
                return;
            }

            // Координаты HTML элемента с анимацией.
            const {top} = containerRef.current.getBoundingClientRect();
            // Верхняя граница элемента с анимацией на 100px выше середины viewport.
            const isAnimationInCenterViewport = window.innerHeight / 2 > top + 100;

            if (isAnimationInCenterViewport) {
                setIsPlay(true);
                window.removeEventListener('scroll', playVideo);
            }
        }, 100);

        window.addEventListener('scroll', playVideo);

        return () => {
            window.removeEventListener('scroll', playVideo);
        };
    }, []);

    return (
        <div ref={containerRef}>
            <Player
                lottieRef={(instance) => lottieRef.current = instance}
                autoplay={isPlay}
                loop
                src={`${process.env.PUBLIC_URL}/resources/lottie-animations/${fileName}.json`}
                style={{width: '100%'}}
            >
                <Controls visible={true} buttons={['play', 'repeat']}/>
            </Player>
        </div>
    );
}

export default observer(Animation);
