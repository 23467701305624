import axios, {AxiosInstance, AxiosRequestConfig, CancelTokenSource} from 'axios';
import {IBoard, IFile, IImage, IInnerLink, IRoute, ISearchResultItem} from '../types/types';

const routes = {
    files: '/files',
    file: '/files/:id',

    innerLinks: "/innerLinks",

    route: '/routes/:id',

    board: '/boards/:id',

    image: '/images/:id',

    search: "/search/:query",
};

class Api {
    instance: AxiosInstance;
    searchSource: CancelTokenSource | null;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_HOST,
            timeout: 10000,
        });
        this.searchSource = null;
    }

    get = <T>(url: string, config?: AxiosRequestConfig) =>
        this.instance.get(url, config).then((response) => response.data.data as T);

    getFile = (id: string) =>
        this.get<IFile>(routes.file.replace(':id', id))

    getFiles = () => this.get<IFile[]>(routes.files);

    getInnerLinks = () =>
        this.get<IInnerLink[]>(routes.innerLinks);

    getRoute = (id: string) =>
        this.get<IRoute>(routes.route.replace(':id', id));

    getBoard = (id: string) =>
        this.get<IBoard>(routes.board.replace(':id', id));

    getImage = (id: string) =>
        this.get<IImage>(routes.image.replace(':id', id));

    getSearchResults = (query: string) => {
        this.cancelSearch();

        this.searchSource = axios.CancelToken.source();

        return this.get<ISearchResultItem[]>(routes.search.replace(':query', query), {cancelToken: this.searchSource.token});
    }

    cancelSearch = () => {
        this.searchSource?.cancel();
    }
}

export const api = new Api();
