import React from 'react';
import RootStore, {IRootStore} from '../../stores/RootStore';

export const StoreContext = React.createContext<IRootStore>({} as IRootStore);

const store = new RootStore();

export const StoreProvider: React.FC = ({children}) => (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
