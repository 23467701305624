import React from 'react';
import clsx from 'clsx';
import './skeleton.scss';

export enum ESkeletonType {
    DARK,
    LIGHT,
}

export interface ISkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
    /** Тип скелетона - темный или светлый. */
    type?: ESkeletonType;
}

/** Элемент для визуализации содержимого, которое еще не загрузилось. */
export const Skeleton: React.FC<ISkeletonProps> = ({className, type = ESkeletonType.DARK, ...htmlDivAttributes}) => (
    <div
        className={clsx('skeleton dark', className)}
        {...htmlDivAttributes}
    />
);