import React from 'react';
import {useTranslation} from "react-i18next";
import {EAppLanguage} from "../LanguageControl/LanguageControl";

export interface ILogoThemeWhiteProps {
    className?: string;
}

export const LogoThemeWhite = ({className}: ILogoThemeWhiteProps): React.ReactElement => {
    const {i18n} = useTranslation();

    return i18n.language === EAppLanguage.ru ? (
        <svg className={className} width="202" height="65" viewBox="0 0 202 65" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.7284 14.2204C19.7284 6.29283 26.6393 0 34.993 0C37.1014 0 39.1137 0.398646 40.9452 1.12161C41.4837 1.3342 41.748 1.94314 41.5354 2.4817C41.3228 3.02027 40.7139 3.28453 40.1753 3.07194C38.5873 2.44508 36.8361 2.09677 34.993 2.09677C27.6438 2.09677 21.8252 7.5986 21.8252 14.2204V50.7796C21.8252 57.4014 27.6438 62.9032 34.993 62.9032C36.8361 62.9032 38.5873 62.5549 40.1753 61.9281C40.7139 61.7155 41.3228 61.9797 41.5354 62.5183C41.748 63.0569 41.4838 63.6658 40.9452 63.8784C39.1137 64.6014 37.1015 65 34.993 65C26.6393 65 19.7284 58.7072 19.7284 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.8276 6.90185C47.069 6.74194 47.3942 6.80796 47.5541 7.0493C48.9349 9.13333 49.7334 11.5899 49.7334 14.2205V50.7796C49.7334 53.4102 48.9349 55.8669 47.5541 57.9509C47.3942 58.1923 47.0689 58.2583 46.8276 58.0984C46.5862 57.9385 46.5202 57.6132 46.6801 57.3719C47.9532 55.4504 48.685 53.1937 48.685 50.7796V14.2205C48.685 11.8065 47.9532 9.54976 46.6802 7.62835C46.5203 7.38702 46.5863 7.06175 46.8276 6.90185Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M-3.05176e-05 14.2204C-3.05176e-05 6.29283 6.91089 0 15.2645 0C17.373 0 19.3852 0.398646 21.2168 1.12161C21.7553 1.3342 22.0196 1.94314 21.807 2.4817C21.5944 3.02027 20.9855 3.28453 20.4469 3.07194C18.8588 2.44508 17.1077 2.09677 15.2645 2.09677C7.91542 2.09677 2.09674 7.59859 2.09674 14.2204V50.7796C2.09674 57.4014 7.91542 62.9032 15.2645 62.9032C17.1077 62.9032 18.8588 62.5549 20.4469 61.9281C20.9855 61.7155 21.5944 61.9797 21.807 62.5183C22.0196 63.0569 21.7553 63.6658 21.2168 63.8784C19.3852 64.6014 17.373 65 15.2645 65C6.91089 65 -3.05176e-05 58.7072 -3.05176e-05 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.0989 6.90185C27.3402 6.74194 27.6655 6.80796 27.8254 7.0493C29.2062 9.13333 30.0047 11.5899 30.0047 14.2205V50.7796C30.0047 53.4102 29.2062 55.8669 27.8253 57.9509C27.6654 58.1923 27.3402 58.2583 27.0988 58.0984C26.8575 57.9385 26.7915 57.6132 26.9514 57.3719C28.2245 55.4504 28.9563 53.1937 28.9563 50.7796V14.2205C28.9563 11.8065 28.2245 9.54976 26.9514 7.62835C26.7915 7.38702 26.8576 7.06175 27.0989 6.90185Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.457 15.2646C39.457 6.83417 46.2912 0 54.7216 0C63.1519 0 69.9861 6.83417 69.9861 15.2646V49.7354C69.9861 58.1658 63.1519 65 54.7216 65C46.2912 65 39.457 58.1658 39.457 49.7354L39.457 15.2646ZM54.7216 2.09677C47.4492 2.09677 41.5538 7.99219 41.5538 15.2646L41.5538 49.7354C41.5538 57.0078 47.4492 62.9032 54.7216 62.9032C61.9939 62.9032 67.8893 57.0078 67.8893 49.7354V15.2646C67.8893 7.99219 61.9939 2.09677 54.7216 2.09677Z"
                  fill="#1F1F22"/>
            <path d="M87.8568 21.5228H89.3849V13.6798H92.4411V12.3174H84.7822V13.6798H87.8568V21.5228Z" fill="#1F1F22"/>
            <path
                d="M93.8186 24.8367H95.3466V20.1051C95.8621 20.9704 96.9484 21.7253 98.4949 21.7253C101.128 21.7253 102.693 20.3445 102.693 16.7176C102.693 13.4957 101.128 12.1333 98.679 12.1333C96.8195 12.1333 95.7333 13.017 95.273 14.0848V12.3174H93.8186V24.8367ZM95.3466 16.423C95.3835 14.5819 96.3592 13.5141 98.2739 13.5141C100.005 13.5141 101.146 14.3426 101.146 16.8096C101.146 19.3687 100.281 20.3445 98.2555 20.3445C96.5249 20.3445 95.3466 19.2214 95.3466 17.3067V16.423Z"
                fill="#1F1F22"/>
            <path
                d="M105.704 21.5228L105.87 21.3018L110.877 14.6187V21.5228H112.405V12.3174H111.061L105.87 19.1662V12.3174H104.341V21.5228H105.704Z"
                fill="#1F1F22"/>
            <path d="M121.034 21.5228H122.562V12.3174H114.792V21.5228H116.32V13.6798H121.034V21.5228Z" fill="#1F1F22"/>
            <path
                d="M130.958 21.5228H132.486V12.3174H126.005L125.968 13.5877C125.784 19.7001 125.066 19.9578 124.127 19.9578C123.998 19.9578 123.833 19.9394 123.667 19.9026V21.5412C123.851 21.5964 124.109 21.6332 124.403 21.6332C125.747 21.6332 127.128 21.2466 127.367 14.3794L127.386 13.6798H130.958V21.5228Z"
                fill="#1F1F22"/>
            <path
                d="M138.733 12.1333C136.137 12.1333 134.186 13.6982 134.186 16.8833C134.186 20.1051 135.861 21.7069 138.77 21.7069C140.574 21.7069 141.697 21.1545 142.157 20.5838V18.798C141.66 19.6817 140.537 20.3813 138.77 20.3813C136.966 20.3813 135.898 19.4608 135.751 17.4724H142.802V16.4782C142.802 13.7534 141.274 12.1333 138.733 12.1333ZM138.733 13.4589C140.187 13.4589 141.274 14.3794 141.274 16.1468V16.1652H135.769C135.971 14.3794 137.002 13.4589 138.733 13.4589Z"
                fill="#1F1F22"/>
            <path
                d="M144.491 21.5228H146.019V17.4724H147.676L150.99 21.5228H152.923L148.947 16.6623L152.316 12.3174H150.567L147.676 16.1652H146.019V12.3174H144.491V21.5228Z"
                fill="#1F1F22"/>
            <path
                d="M160.821 20.5838V18.798C160.361 19.6817 159.348 20.3813 157.82 20.3813C155.868 20.3813 154.782 19.2951 154.782 16.9385V16.8833C154.782 14.6187 155.85 13.4589 157.783 13.4589C159.035 13.4589 160.103 14.1585 160.729 15.1895V13.017C160.121 12.5567 159.017 12.1333 157.783 12.1333C155.187 12.1333 153.236 13.6982 153.236 16.8833C153.236 20.1051 154.911 21.7069 157.82 21.7069C159.385 21.7069 160.397 21.1545 160.821 20.5838Z"
                fill="#1F1F22"/>
            <path
                d="M162.754 20.5286C162.754 21.173 163.031 21.7069 163.951 21.7069C164.89 21.7069 165.166 21.173 165.166 20.5286C165.166 19.8658 164.89 19.3319 163.951 19.3319C163.031 19.3319 162.754 19.8658 162.754 20.5286Z"
                fill="#1F1F22"/>
            <path
                d="M84.4201 36.7085V38.8442H84.9847V37.2486H92.7295V38.8442H93.2941V36.7085H92.1158V28.6569H86.4208L86.3962 30.4366C86.3594 33.0877 85.9421 35.4934 85.4511 36.7085H84.4201ZM86.9363 30.4734L86.9608 29.1969H91.5267V36.7085H86.028C86.5312 35.3093 86.8872 32.8914 86.9363 30.4734Z"
                fill="#1F1F22"/>
            <path
                d="M95.0835 28.6569H94.4943V37.2486H95.1448L101.208 29.4424V37.2486H101.797V28.6569H101.147L95.0835 36.4385V28.6569Z"
                fill="#1F1F22"/>
            <path
                d="M103.453 30.5471C103.956 29.6756 105.086 29.0619 106.374 29.0742C107.626 29.0865 108.743 29.6511 108.743 30.8416C108.743 31.8358 108.08 32.5845 106.608 32.5845H105.258V33.1H106.62C108.387 33.1 109.173 33.7383 109.173 34.8184C109.173 36.0457 108.166 36.8067 106.583 36.8313C104.926 36.8313 103.772 36.1316 103.269 35.3093V36.2544C103.723 36.7699 104.926 37.3713 106.522 37.3713C108.596 37.359 109.787 36.2789 109.787 34.8306C109.787 33.6769 109.05 32.9282 107.749 32.7686C108.841 32.4495 109.345 31.6885 109.345 30.7189C109.345 29.246 108.08 28.5464 106.374 28.5342C104.963 28.5342 103.895 29.0619 103.453 29.5283V30.5471Z"
                fill="#1F1F22"/>
            <path
                d="M114.139 28.6569L110.309 37.2486H110.935L112.04 34.7324H116.839L117.968 37.2486H118.606L114.765 28.6569H114.139ZM114.433 29.332L116.606 34.1924H112.285L114.433 29.332Z"
                fill="#1F1F22"/>
            <path
                d="M120.204 28.6569H119.615V37.2486H120.266L126.329 29.4424V37.2486H126.918V28.6569H126.268L120.204 36.4385V28.6569ZM124.967 26.4476C124.893 27.135 124.414 27.5645 123.322 27.5645C122.23 27.5645 121.702 27.1595 121.628 26.4476H121.051C121.162 27.7977 122.291 28.0555 123.347 28.0555C124.525 28.0555 125.409 27.5768 125.507 26.4476H124.967Z"
                fill="#1F1F22"/>
            <path
                d="M135.632 37.2486H136.221V28.6569H135.632V32.56H129.593V28.6569H129.004V37.2486H129.593V33.1H135.632V37.2486Z"
                fill="#1F1F22"/>
            <path d="M138.026 33.9101H141.168V33.3455H138.026V33.9101Z" fill="#1F1F22"/>
            <path
                d="M149.823 35.3093C149.345 36.1316 148.24 36.8313 146.669 36.8313C144.325 36.7453 142.974 35.1743 142.974 32.9527C142.974 30.5962 144.533 29.0742 146.742 29.0742C148.031 29.0742 149.16 29.6756 149.664 30.5471V29.5283C149.222 29.0619 148.154 28.5342 146.742 28.5342C144.091 28.5342 142.361 30.3998 142.361 32.9405C142.361 35.6407 143.993 37.3222 146.718 37.3713C148.24 37.3713 149.394 36.7699 149.823 36.2544V35.3093Z"
                fill="#1F1F22"/>
            <path
                d="M151.765 28.6569H151.176V37.2486H151.827L157.89 29.4424V37.2486H158.479V28.6569H157.829L151.765 36.4385V28.6569Z"
                fill="#1F1F22"/>
            <path
                d="M167.414 35.3093C166.935 36.1316 165.83 36.8313 164.259 36.8313C161.915 36.7453 160.565 35.1743 160.565 32.9527C160.565 30.5962 162.124 29.0742 164.333 29.0742C165.622 29.0742 166.751 29.6756 167.254 30.5471V29.5283C166.812 29.0619 165.744 28.5342 164.333 28.5342C161.682 28.5342 159.951 30.3998 159.951 32.9405C159.951 35.6407 161.584 37.3222 164.308 37.3713C165.83 37.3713 166.984 36.7699 167.414 36.2544V35.3093Z"
                fill="#1F1F22"/>
            <path d="M174.953 28.6569H167.662V29.1969H171.013V37.2486H171.602V29.1969H174.953V28.6569Z" fill="#1F1F22"/>
            <path
                d="M181.203 32.56H176.527V29.1969H181.67V28.6569H175.938V37.2486H181.805V36.7085H176.527V33.1H181.203V32.56Z"
                fill="#1F1F22"/>
            <path
                d="M191.352 37.2486H191.942V28.6569H191.242L187.67 34.3888L184.013 28.6569H183.289V37.2486H183.878V29.5774L187.523 35.2111H187.781L191.352 29.4915V37.2486Z"
                fill="#1F1F22"/>
            <path
                d="M196.769 28.6569L192.939 37.2486H193.565L194.67 34.7324H199.469L200.598 37.2486H201.236L197.395 28.6569H196.769ZM197.063 29.332L199.236 34.1924H194.915L197.063 29.332Z"
                fill="#1F1F22"/>
            <path
                d="M92.3981 51.0351C91.9194 51.8575 90.8148 52.5571 89.2437 52.5571C86.8994 52.4711 85.5493 50.9001 85.5493 48.6785C85.5493 46.322 87.1081 44.8 89.3174 44.8C90.6061 44.8 91.7353 45.4014 92.2385 46.2729V45.2542C91.7967 44.7877 90.7289 44.26 89.3174 44.26C86.6662 44.26 84.9356 46.1256 84.9356 48.6663C84.9356 51.3665 86.5681 53.048 89.2928 53.0971C90.8148 53.0971 91.9685 52.4957 92.3981 51.9802V51.0351Z"
                fill="#1F1F22"/>
            <path
                d="M93.7512 52.9744H96.2428C98.5871 52.9744 99.8758 52.1152 99.8758 50.225C99.8758 48.298 98.6484 47.5494 96.2428 47.5494H94.3403V44.9228H99.434V44.3827H93.7512V52.9744ZM96.2551 48.0894C98.2311 48.0894 99.2621 48.6049 99.2621 50.1882C99.2621 51.8084 98.2066 52.4343 96.206 52.4343H94.3403V48.0894H96.2551Z"
                fill="#1F1F22"/>
            <path
                d="M106.715 48.2858H102.039V44.9228H107.182V44.3827H101.45V52.9744H107.317V52.4343H102.039V48.8258H106.715V48.2858Z"
                fill="#1F1F22"/>
            <path
                d="M111.292 49.955C113.636 49.955 114.925 49.0222 114.925 47.132C114.925 45.2051 113.698 44.3827 111.525 44.3827H108.801V52.9744H109.39V49.955H111.292ZM111.464 44.9228C113.317 44.9228 114.311 45.5119 114.311 47.0952C114.311 48.7154 113.256 49.415 111.255 49.415H109.39V44.9228H111.464Z"
                fill="#1F1F22"/>
            <path
                d="M116.475 52.9744H118.967C121.311 52.9744 122.6 52.1152 122.6 50.225C122.6 48.298 121.372 47.5494 118.967 47.5494H117.064V44.9228H122.158V44.3827H116.475V52.9744ZM118.979 48.0894C120.955 48.0894 121.986 48.6049 121.986 50.1882C121.986 51.8084 120.93 52.4343 118.93 52.4343H117.064V48.0894H118.979Z"
                fill="#1F1F22"/>
            <path
                d="M124.763 44.3827H124.173V52.9744H124.824L130.887 45.1682V52.9744H131.476V44.3827H130.826L124.763 52.1643V44.3827Z"
                fill="#1F1F22"/>
            <path
                d="M133.132 46.2729C133.636 45.4014 134.765 44.7877 136.054 44.8C137.305 44.8123 138.422 45.3769 138.422 46.5674C138.422 47.5616 137.76 48.3103 136.287 48.3103H134.937V48.8258H136.299C138.066 48.8258 138.852 49.4641 138.852 50.5442C138.852 51.7715 137.845 52.5325 136.262 52.5571C134.605 52.5571 133.451 51.8575 132.948 51.0351V51.9802C133.402 52.4957 134.605 53.0971 136.201 53.0971C138.275 53.0848 139.466 52.0047 139.466 50.5564C139.466 49.4027 138.729 48.654 137.428 48.4944C138.521 48.1753 139.024 47.4143 139.024 46.4447C139.024 44.9719 137.76 44.2722 136.054 44.26C134.642 44.26 133.574 44.7877 133.132 45.2542V46.2729Z"
                fill="#1F1F22"/>
            <path
                d="M147.708 52.9744H148.298V44.3827H147.708V48.2858H141.67V44.3827H141.081V52.9744H141.67V48.8258H147.708V52.9744Z"
                fill="#1F1F22"/>
            <path
                d="M155.651 48.2858H150.974V44.9228H156.117V44.3827H150.385V52.9744H156.252V52.4343H150.974V48.8258H155.651V48.2858Z"
                fill="#1F1F22"/>
            <path
                d="M164.585 51.0351C164.106 51.8575 163.002 52.5571 161.43 52.5571C159.086 52.4711 157.736 50.9001 157.736 48.6785C157.736 46.322 159.295 44.8 161.504 44.8C162.793 44.8 163.922 45.4014 164.425 46.2729V45.2542C163.983 44.7877 162.916 44.26 161.504 44.26C158.853 44.26 157.122 46.1256 157.122 48.6663C157.122 51.3665 158.755 53.048 161.48 53.0971C163.002 53.0971 164.155 52.4957 164.585 51.9802V51.0351Z"
                fill="#1F1F22"/>
        </svg>
    ) : (
        <svg className={className} width="202" height="65" viewBox="0 0 202 65" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6786 14.2204C19.6786 6.29283 26.572 0 34.9046 0C37.0077 0 39.0149 0.398646 40.8418 1.12161C41.379 1.3342 41.6426 1.94314 41.4305 2.4817C41.2184 3.02027 40.611 3.28453 40.0738 3.07194C38.4898 2.44508 36.7431 2.09677 34.9046 2.09677C27.574 2.09677 21.7701 7.5986 21.7701 14.2204V50.7796C21.7701 57.4014 27.574 62.9032 34.9046 62.9032C36.7431 62.9032 38.4898 62.5549 40.0738 61.9281C40.6111 61.7155 41.2184 61.9797 41.4305 62.5183C41.6426 63.0569 41.379 63.6658 40.8418 63.8784C39.0149 64.6014 37.0077 65 34.9046 65C26.572 65 19.6786 58.7072 19.6786 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.7093 6.90178C46.95 6.74188 47.2745 6.8079 47.434 7.04924C48.8113 9.13327 49.6077 11.5899 49.6077 14.2204V50.7796C49.6077 53.4102 48.8113 55.8668 47.4339 57.9509C47.2744 58.1922 46.9499 58.2582 46.7092 58.0983C46.4685 57.9384 46.4026 57.6131 46.5621 57.3718C47.832 55.4504 48.562 53.1936 48.562 50.7796V14.2204C48.562 11.8065 47.832 9.5497 46.5622 7.62829C46.4027 7.38696 46.4686 7.06169 46.7093 6.90178Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 14.2204C0 6.29283 6.89346 0 15.226 0C17.3291 0 19.3363 0.398646 21.1632 1.12161C21.7004 1.3342 21.964 1.94314 21.7519 2.4817C21.5399 3.02027 20.9325 3.28453 20.3953 3.07194C18.8112 2.44508 17.0645 2.09677 15.226 2.09677C7.89545 2.09677 2.09148 7.59859 2.09148 14.2204V50.7796C2.09148 57.4014 7.89544 62.9032 15.226 62.9032C17.0645 62.9032 18.8112 62.5549 20.3953 61.9281C20.9325 61.7155 21.5399 61.9797 21.7519 62.5183C21.964 63.0569 21.7004 63.6658 21.1632 63.8784C19.3363 64.6014 17.3292 65 15.226 65C6.89346 65 0 58.7072 0 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.0304 6.90178C27.2712 6.74188 27.5956 6.8079 27.7551 7.04924C29.1324 9.13327 29.9289 11.5899 29.9289 14.2204V50.7796C29.9289 53.4102 29.1324 55.8668 27.7551 57.9509C27.5956 58.1922 27.2711 58.2582 27.0304 58.0983C26.7897 57.9384 26.7238 57.6131 26.8833 57.3718C28.1532 55.4504 28.8832 53.1936 28.8832 50.7796V14.2204C28.8832 11.8065 28.1532 9.5497 26.8834 7.62829C26.7239 7.38696 26.7897 7.06169 27.0304 6.90178Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.3573 15.2646C39.3573 6.83417 46.1742 0 54.5833 0C62.9924 0 69.8093 6.83417 69.8093 15.2646V49.7354C69.8093 58.1658 62.9924 65 54.5833 65C46.1742 65 39.3573 58.1658 39.3573 49.7354L39.3573 15.2646ZM54.5833 2.09677C47.3293 2.09677 41.4488 7.99219 41.4488 15.2646L41.4488 49.7354C41.4488 57.0078 47.3293 62.9032 54.5833 62.9032C61.8373 62.9032 67.7178 57.0078 67.7178 49.7354V15.2646C67.7178 7.99219 61.8373 2.09677 54.5833 2.09677Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M19.6786 14.2204C19.6786 6.29283 26.572 0 34.9046 0C37.0077 0 39.0149 0.398646 40.8418 1.12161C41.379 1.3342 41.6426 1.94314 41.4305 2.4817C41.2184 3.02027 40.6111 3.28453 40.0738 3.07194C38.4898 2.44508 36.7431 2.09677 34.9046 2.09677C27.574 2.09677 21.7701 7.5986 21.7701 14.2204V50.7796C21.7701 57.4014 27.574 62.9032 34.9046 62.9032C36.7431 62.9032 38.4898 62.5549 40.0739 61.9281C40.6111 61.7155 41.2185 61.9797 41.4305 62.5183C41.6426 63.0569 41.379 63.6658 40.8418 63.8784C39.0149 64.6014 37.0077 65 34.9046 65C26.572 65 19.6786 58.7072 19.6786 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M46.7092 6.90178C46.9499 6.74188 47.2743 6.8079 47.4338 7.04924C48.8112 9.13327 49.6076 11.5899 49.6076 14.2204V50.7796C49.6076 53.4102 48.8111 55.8668 47.4338 57.9509C47.2743 58.1922 46.9498 58.2582 46.7091 58.0983C46.4684 57.9384 46.4025 57.6132 46.562 57.3718C47.8319 55.4504 48.5619 53.1936 48.5619 50.7796V14.2204C48.5619 11.8065 47.8319 9.5497 46.5621 7.62829C46.4026 7.38696 46.4684 7.06169 46.7092 6.90178Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 14.2204C0 6.29283 6.89346 0 15.226 0C17.3292 0 19.3363 0.398646 21.1632 1.12161C21.7004 1.3342 21.964 1.94314 21.7519 2.4817C21.5399 3.02027 20.9325 3.28453 20.3953 3.07194C18.8112 2.44508 17.0645 2.09677 15.226 2.09677C7.89545 2.09677 2.09148 7.59859 2.09148 14.2204V50.7796C2.09148 57.4014 7.89545 62.9032 15.226 62.9032C17.0645 62.9032 18.8112 62.5549 20.3953 61.9281C20.9325 61.7155 21.5399 61.9797 21.7519 62.5183C21.964 63.0569 21.7004 63.6658 21.1632 63.8784C19.3363 64.6014 17.3292 65 15.226 65C6.89346 65 0 58.7072 0 50.7796V14.2204Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M27.0304 6.90178C27.2712 6.74188 27.5956 6.8079 27.7551 7.04924C29.1324 9.13327 29.9289 11.5899 29.9289 14.2204V50.7796C29.9289 53.4102 29.1324 55.8668 27.7551 57.9509C27.5956 58.1922 27.2711 58.2582 27.0304 58.0983C26.7897 57.9384 26.7238 57.6132 26.8833 57.3718C28.1532 55.4504 28.8832 53.1936 28.8832 50.7796V14.2204C28.8832 11.8065 28.1532 9.5497 26.8834 7.62829C26.7239 7.38696 26.7897 7.06169 27.0304 6.90178Z"
                  fill="#1F1F22"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M39.3573 15.2646C39.3573 6.83417 46.1742 0 54.5833 0C62.9924 0 69.8093 6.83417 69.8093 15.2646V49.7354C69.8093 58.1658 62.9924 65 54.5833 65C46.1742 65 39.3573 58.1658 39.3573 49.7354L39.3573 15.2646ZM54.5833 2.09677C47.3293 2.09677 41.4488 7.99219 41.4488 15.2646L41.4488 49.7354C41.4488 57.0078 47.3293 62.9032 54.5833 62.9032C61.8373 62.9032 67.7178 57.0078 67.7178 49.7354V15.2646C67.7178 7.99219 61.8373 2.09677 54.5833 2.09677Z"
                  fill="#1F1F22"/>
            <path
                d="M86.182 10.7454V12.4347H84.2952V13.7934H86.182V18.9347C86.182 21.0095 87.1895 21.7991 89.2412 21.7991C90.0289 21.7991 90.9815 21.5053 91.3845 21.1748V19.8344C90.8532 20.1649 90.0838 20.422 89.3145 20.422C88.0688 20.422 87.7024 19.9629 87.7024 18.5491V13.7934H91.0181V12.4347H87.7024V9.64373L86.182 10.7454Z"
                fill="#1F1F22"/>
            <path
                d="M96.2873 13.6282C96.9284 13.6466 97.4047 13.8485 97.6978 14.1607V12.5816C97.5146 12.4163 97.075 12.2878 96.6536 12.2694C95.3347 12.2694 94.4004 13.1691 94.1989 14.381V12.4347H92.7518V21.6155H94.2722V16.6762C94.2173 14.7482 94.8401 13.6282 96.2873 13.6282Z"
                fill="#1F1F22"/>
            <path
                d="M99.1611 8.56039C99.1611 9.14796 99.5641 9.53356 100.187 9.53356C100.791 9.53356 101.194 9.14796 101.194 8.56039C101.194 7.99118 100.791 7.60559 100.187 7.60559C99.5641 7.60559 99.1611 7.99118 99.1611 8.56039ZM99.4176 21.6155H100.938V12.4347H99.4176V21.6155Z"
                fill="#1F1F22"/>
            <path
                d="M103.585 24.9206H105.105V20.2016C105.618 21.0646 106.699 21.8175 108.238 21.8175C110.857 21.8175 112.414 20.4403 112.414 16.8231C112.414 13.6098 110.857 12.2511 108.421 12.2511C106.571 12.2511 105.49 13.1324 105.032 14.1974V12.4347H103.585V24.9206ZM105.105 16.5293C105.142 14.6932 106.113 13.6282 108.018 13.6282C109.74 13.6282 110.876 14.4545 110.876 16.9149C110.876 19.4672 110.015 20.4403 108 20.4403C106.278 20.4403 105.105 19.3203 105.105 17.4107V16.5293Z"
                fill="#1F1F22"/>
            <path
                d="M118.305 20.1098C118.012 20.22 117.517 20.3485 116.968 20.3485C115.942 20.3485 115.575 20.0547 115.575 18.8429V7.84429H114.055V19.4121C114.055 21.1197 115.063 21.7991 116.638 21.7991C117.316 21.7991 118.012 21.6338 118.305 21.4686V20.1098Z"
                fill="#1F1F22"/>
            <path
                d="M123.327 12.2511C120.744 12.2511 118.802 13.8118 118.802 16.9884C118.802 20.2016 120.469 21.7991 123.364 21.7991C125.159 21.7991 126.276 21.2482 126.734 20.679V18.898C126.24 19.7793 125.122 20.4771 123.364 20.4771C121.568 20.4771 120.506 19.559 120.359 17.5759H127.375V16.5844C127.375 13.8669 125.855 12.2511 123.327 12.2511ZM123.327 13.5731C124.774 13.5731 125.855 14.4912 125.855 16.2539V16.2723H120.378C120.579 14.4912 121.605 13.5731 123.327 13.5731Z"
                fill="#1F1F22"/>
            <path
                d="M127.774 21.6155H129.551L132.097 17.9615L134.643 21.6155H136.512L133.068 16.7864L136.292 12.4347H134.515L132.189 15.7214L129.862 12.4347H127.994L131.181 16.9149L127.774 21.6155Z"
                fill="#1F1F22"/>
            <path
                d="M137.795 20.624C137.795 21.2666 138.07 21.7991 138.986 21.7991C139.92 21.7991 140.195 21.2666 140.195 20.624C140.195 19.9629 139.92 19.4304 138.986 19.4304C138.07 19.4304 137.795 19.9629 137.795 20.624Z"
                fill="#1F1F22"/>
            <path
                d="M90.6212 30.6035V29.5875C90.1815 29.1223 89.1191 28.5959 87.7513 28.5959C86.0782 28.5959 84.8203 29.3426 84.8203 30.7504C84.8203 33.8841 90.2548 32.5376 90.2548 35.1082C90.2548 36.32 89.2412 36.8831 87.9345 36.8831C86.3346 36.8831 85.1867 36.1854 84.686 35.3652V36.3078C85.1378 36.8219 86.3346 37.4217 87.8734 37.4217C89.7419 37.4217 90.8654 36.4914 90.8654 35.0959C90.8654 31.901 85.4187 33.2598 85.4187 30.7259C85.4187 29.7344 86.2858 29.159 87.7513 29.1345C88.9969 29.1345 90.1205 29.7344 90.6212 30.6035Z"
                fill="#1F1F22"/>
            <path
                d="M95.8219 37.2993C97.7515 37.2993 98.9361 36.5771 98.9361 34.8022C98.9361 33.6882 98.3499 33.0027 97.2264 32.8313C98.1667 32.5376 98.6796 31.8521 98.6796 30.8116C98.6796 29.3549 97.7149 28.7306 96.0418 28.7306H92.488V37.2993H95.8219ZM93.0742 36.7607V33.1496H96.0906C97.5805 33.1496 98.3499 33.7127 98.3499 34.8144C98.3499 36.1364 97.4706 36.7607 95.883 36.7607H93.0742ZM93.0742 32.6477V29.2692H95.9196C97.3851 29.2692 98.0935 29.7711 98.0935 30.9095C98.0935 31.8765 97.3607 32.6477 96.0173 32.6477H93.0742Z"
                fill="#1F1F22"/>
            <path
                d="M105.793 32.6232H101.14V29.2692H106.257V28.7306H100.553V37.2993H106.391V36.7607H101.14V33.1618H105.793V32.6232Z"
                fill="#1F1F22"/>
            <path
                d="M113.729 37.2993H114.45L112.093 34.0432C113.278 33.6515 113.925 32.8069 113.925 31.4726C113.925 29.5507 112.704 28.7306 110.542 28.7306H107.867V37.2993H108.454V34.288H110.31C110.737 34.288 111.128 34.2635 111.482 34.2023L113.729 37.2993ZM110.481 29.2692C112.325 29.2692 113.314 29.8568 113.314 31.4359C113.314 33.0517 112.264 33.7494 110.273 33.7494H108.454V29.2692H110.481Z"
                fill="#1F1F22"/>
            <path
                d="M119.148 37.2993C121.077 37.2993 122.262 36.5771 122.262 34.8022C122.262 33.6882 121.676 33.0027 120.552 32.8313C121.492 32.5376 122.005 31.8521 122.005 30.8116C122.005 29.3549 121.041 28.7306 119.367 28.7306H115.814V37.2993H119.148ZM116.4 36.7607V33.1496H119.416C120.906 33.1496 121.676 33.7127 121.676 34.8144C121.676 36.1364 120.796 36.7607 119.209 36.7607H116.4ZM116.4 32.6477V29.2692H119.245C120.711 29.2692 121.419 29.7711 121.419 30.9095C121.419 31.8765 120.686 32.6477 119.343 32.6477H116.4Z"
                fill="#1F1F22"/>
            <path
                d="M124.404 28.7306H123.818V33.8841C123.818 36.2344 124.978 37.4217 127.262 37.4217C129.277 37.4217 130.657 36.4425 130.657 33.8963V28.7306H130.071V33.933C130.071 36.0997 128.947 36.8831 127.323 36.8831C125.503 36.8831 124.404 36.0507 124.404 33.7861V28.7306Z"
                fill="#1F1F22"/>
            <path
                d="M138.143 30.6035V29.5875C137.703 29.1223 136.641 28.5959 135.273 28.5959C133.6 28.5959 132.342 29.3426 132.342 30.7504C132.342 33.8841 137.777 32.5376 137.777 35.1082C137.777 36.32 136.763 36.8831 135.456 36.8831C133.857 36.8831 132.709 36.1854 132.208 35.3652V36.3078C132.66 36.8219 133.857 37.4217 135.395 37.4217C137.264 37.4217 138.387 36.4914 138.387 35.0959C138.387 31.901 132.941 33.2598 132.941 30.7259C132.941 29.7344 133.808 29.159 135.273 29.1345C136.519 29.1345 137.642 29.7344 138.143 30.6035Z"
                fill="#1F1F22"/>
            <path d="M140.596 28.7306H140.01V37.2993H140.596V28.7306Z" fill="#1F1F22"/>
            <path
                d="M149.353 37.2993H149.976V28.7306H149.39V36.3812L143.296 28.7306H142.673V37.2993H143.259V29.6487L149.353 37.2993Z"
                fill="#1F1F22"/>
            <path
                d="M157.289 32.6232H152.636V29.2692H157.753V28.7306H152.05V37.2993H157.888V36.7607H152.636V33.1618H157.289V32.6232Z"
                fill="#1F1F22"/>
            <path
                d="M164.835 30.6035V29.5875C164.396 29.1223 163.333 28.5959 161.965 28.5959C160.292 28.5959 159.034 29.3426 159.034 30.7504C159.034 33.8841 164.469 32.5376 164.469 35.1082C164.469 36.32 163.455 36.8831 162.149 36.8831C160.549 36.8831 159.401 36.1854 158.9 35.3652V36.3078C159.352 36.8219 160.549 37.4217 162.088 37.4217C163.956 37.4217 165.08 36.4914 165.08 35.0959C165.08 31.901 159.633 33.2598 159.633 30.7259C159.633 29.7344 160.5 29.159 161.965 29.1345C163.211 29.1345 164.335 29.7344 164.835 30.6035Z"
                fill="#1F1F22"/>
            <path
                d="M172.054 30.6035V29.5875C171.614 29.1223 170.552 28.5959 169.184 28.5959C167.511 28.5959 166.253 29.3426 166.253 30.7504C166.253 33.8841 171.688 32.5376 171.688 35.1082C171.688 36.32 170.674 36.8831 169.367 36.8831C167.767 36.8831 166.619 36.1854 166.119 35.3652V36.3078C166.571 36.8219 167.767 37.4217 169.306 37.4217C171.175 37.4217 172.298 36.4914 172.298 35.0959C172.298 31.901 166.852 33.2598 166.852 30.7259C166.852 29.7344 167.719 29.159 169.184 29.1345C170.43 29.1345 171.553 29.7344 172.054 30.6035Z"
                fill="#1F1F22"/>
            <path
                d="M85.15 44.4144V52.9832H87.7635C90.3159 52.9832 92.4164 51.7468 92.4164 48.5274C92.4164 45.8956 90.8044 44.4144 87.9345 44.4144H85.15ZM85.7362 52.4446V44.953H87.8245C90.4014 44.953 91.8058 46.2016 91.8058 48.5886C91.8058 51.1348 90.267 52.4446 87.7024 52.4446H85.7362Z"
                fill="#1F1F22"/>
            <path
                d="M99.1224 48.3071H94.4695V44.953H99.5865V44.4144H93.8833V52.9832H99.7209V52.4446H94.4695V48.8457H99.1224V48.3071Z"
                fill="#1F1F22"/>
            <path
                d="M106.669 46.2873V45.2713C106.229 44.8062 105.166 44.2798 103.799 44.2798C102.126 44.2798 100.868 45.0265 100.868 46.4342C100.868 49.5679 106.302 48.2214 106.302 50.792C106.302 52.0039 105.289 52.567 103.982 52.567C102.382 52.567 101.234 51.8692 100.733 51.0491V51.9916C101.185 52.5058 102.382 53.1056 103.921 53.1056C105.789 53.1056 106.913 52.1753 106.913 50.7798C106.913 47.5849 101.466 48.9436 101.466 46.4097C101.466 45.4182 102.333 44.8429 103.799 44.8184C105.044 44.8184 106.168 45.4182 106.669 46.2873Z"
                fill="#1F1F22"/>
            <path d="M109.122 44.4144H108.535V52.9832H109.122V44.4144Z" fill="#1F1F22"/>
            <path
                d="M114.947 53.1056C116.388 53.1056 117.707 52.4935 118.159 51.8937V48.6743H114.862V49.2129H117.61V51.6734C117.182 52.1263 116.266 52.5547 115.045 52.567C112.615 52.5547 111.198 50.9756 111.198 48.6988C111.198 46.3608 112.737 44.8429 114.923 44.8306C116.205 44.8306 117.329 45.4304 117.83 46.2996V45.2836C117.39 44.8184 116.327 44.292 114.923 44.292C112.297 44.3043 110.588 46.1649 110.588 48.6866C110.588 51.3918 112.224 53.0689 114.947 53.1056Z"
                fill="#1F1F22"/>
            <path
                d="M126.54 52.9832H127.163V44.4144H126.577V52.0651L120.483 44.4144H119.86V52.9832H120.446V45.3325L126.54 52.9832Z"
                fill="#1F1F22"/>
            <path d="M128.956 49.6536H132.083V49.0905H128.956V49.6536Z" fill="#1F1F22"/>
            <path
                d="M139.351 46.2873V45.2713C138.911 44.8062 137.849 44.2798 136.481 44.2798C134.808 44.2798 133.55 45.0265 133.55 46.4342C133.55 49.5679 138.985 48.2214 138.985 50.792C138.985 52.0039 137.971 52.567 136.664 52.567C135.064 52.567 133.916 51.8692 133.416 51.0491V51.9916C133.868 52.5058 135.064 53.1056 136.603 53.1056C138.472 53.1056 139.595 52.1753 139.595 50.7798C139.595 47.5849 134.149 48.9436 134.149 46.4097C134.149 45.4182 135.016 44.8429 136.481 44.8184C137.727 44.8184 138.85 45.4182 139.351 46.2873Z"
                fill="#1F1F22"/>
            <path
                d="M147.174 44.4144H146.502L143.486 48.9804L140.408 44.4144H139.725L143.168 49.5557V52.9832H143.755V49.5557L147.174 44.4144Z"
                fill="#1F1F22"/>
            <path
                d="M153.419 46.2873V45.2713C152.979 44.8062 151.917 44.2798 150.549 44.2798C148.876 44.2798 147.618 45.0265 147.618 46.4342C147.618 49.5679 153.052 48.2214 153.052 50.792C153.052 52.0039 152.039 52.567 150.732 52.567C149.132 52.567 147.984 51.8692 147.483 51.0491V51.9916C147.935 52.5058 149.132 53.1056 150.671 53.1056C152.539 53.1056 153.663 52.1753 153.663 50.7798C153.663 47.5849 148.216 48.9436 148.216 46.4097C148.216 45.4182 149.083 44.8429 150.549 44.8184C151.794 44.8184 152.918 45.4182 153.419 46.2873Z"
                fill="#1F1F22"/>
            <path d="M161.13 44.4144H153.876V44.953H157.21V52.9832H157.796V44.953H161.13V44.4144Z" fill="#1F1F22"/>
            <path
                d="M167.35 48.3071H162.697V44.953H167.814V44.4144H162.111V52.9832H167.948V52.4446H162.697V48.8457H167.35V48.3071Z"
                fill="#1F1F22"/>
            <path
                d="M177.448 52.9832H178.034V44.4144H177.338L173.784 50.131L170.145 44.4144H169.425V52.9832H170.011V45.3325L173.638 50.9512H173.894L177.448 45.2468V52.9832Z"
                fill="#1F1F22"/>
        </svg>
    );
}
