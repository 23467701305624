import React from 'react';
import {observer} from 'mobx-react';
import {Node} from 'figma-api';
import {IBoard, INode, TImageNode} from '../../../types/types';
import {BoardText, IBoardTextProps} from './BoardText';
import Image from './Image';
import NodeUtils from '../../../utils/NodeUtils';
import DevelopmentStatus, {EDevelopmentStatus} from '../../DevelopmentStatus/DevelopmentStatus';
import {elementRegExp} from '../../../utils/StyleguideUtils';
import BoardSubHeader, {EBoardSubHeaderType} from './BoardSubHeader';
import BoardCol from './BoardCol';
import BoardSpoiler from './BoardSpoiler';
import Animation from './Animation';

interface IBoardNodeProps extends Pick<IBoardTextProps, 'pureText'>{
    boardNode: IBoard;
    node: INode;
}

const BoardNode: React.FC<IBoardNodeProps> = ({boardNode, node, pureText}) => {
    if (node.image) {
        return <Image key={node.id} node={node as TImageNode} />;
    }

    if (node.content.children) {
        const match = node.content.name.match(elementRegExp);

        if (match) {
            const nodeName = match[3] || match[5];
            // 'Sub Header' - удалить, вместо него теперь L и M
            if (nodeName === 'Sub Header') {
                return <BoardSubHeader boardNode={boardNode} node={node} type={EBoardSubHeaderType.LARGE} />;
            } else if (nodeName === 'Sub Header L') {
                return <BoardSubHeader boardNode={boardNode} node={node} type={EBoardSubHeaderType.LARGE} />;
            } else if (nodeName === 'Sub Header M') {
                return <BoardSubHeader boardNode={boardNode} node={node} type={EBoardSubHeaderType.MEDIUM} />;
            } else if (node.content.name.includes('Col-')) {
                return <BoardCol key={node.nodeFigmaId} boardNode={boardNode} node={node} />;
            } else if (node.content.name.includes('Spoiler')) {
                return <BoardSpoiler key={node.nodeFigmaId} boardNode={boardNode} node={node} />;
            } else if (node.content.name.includes('Animation')) {
                return <Animation key={node.nodeFigmaId} fileName={match[2].replace('Animation/', '')} />;
            }
        }

        return (
            <>
                {NodeUtils.getChildrenNodes(boardNode, node).map((k) => (
                    <BoardNode key={k.id} boardNode={boardNode} node={k} />
                ))}
            </>
        );
    } else if (node.content.type === 'TEXT') {
        if (Object.values<string>(EDevelopmentStatus).includes(node.content.name)) {
            return <DevelopmentStatus status={node.content.name as EDevelopmentStatus} />;
        }
        return <BoardText id={node.nodeFigmaId} key={node.id} pureText={pureText} textBlock={node.content as unknown as Node<'TEXT'>} />;
    }

    return null;
};

export default observer(BoardNode);
