import React, {useEffect} from "react";
import {
    Switch,
    Route,
    useRouteMatch, useParams, useHistory,
} from "react-router-dom";
import StartPage from "../StartPage/StartPage";
import {useTranslation} from 'react-i18next';
import File from "../File/File";
import {EAppLanguage} from "../LanguageControl/LanguageControl";

export const Lang = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const {language} = useParams<{ language: string }>();
    const {i18n} = useTranslation();

    useEffect(() => {
        // language не является 'ru' или 'en'.
        if (!Object.values<string>(EAppLanguage).includes(language)) {
            history.push(`/${i18n.language}`);
        } else {
            i18n.changeLanguage(language);
        }
    }, [language]);

    return (
        <Switch>
            <Route path={`${match.path}/:fileTitle`} component={File}/>
            <Route path={match.path} component={StartPage}/>
        </Switch>
    );
}

Lang.displayName = 'Lang';