import {IBoard, INode} from '../types/types';
import {groupNodeRegExp} from './StyleguideUtils';

interface INodeUtils {
    /**
     * Возвращает ноду по ее id.
     */
    getBoardNodeByNodeId: (boardNode: IBoard, nodeId: string) => INode | undefined;
    /**
     * Возвращаем отсортированный массив дочерних нод для текущей ноды, у которой в children указаны только id дочерних нод.
     */
    getChildrenNodes: (boardNode: IBoard, node: INode) => INode[];
    /**
     * Сортирует дочерние ноды board в порядке вывода во View.
     */
    sortBoardChildrenNodes: (arr: INode[]) => INode[];
    /**
     * Сортирует дочерние ноды в порядке вывода во View.
     */
    sortChildrenNodes: (arr: INode[]) => INode[];
}

const NodeUtils: INodeUtils = {
    getBoardNodeByNodeId: (boardNode, nodeId) => boardNode.nodes.filter((bn) => bn.nodeFigmaId === nodeId)[0],
    getChildrenNodes: (boardNode, node) => {
        if (!node.content.children) {
            console.error(`Нода - ${node.nodeFigmaId} не имеет children.`);
            return [];
        }

        const childrenArr = [...node.content.children].map((nodeId) => {
            const n = NodeUtils.getBoardNodeByNodeId(boardNode, nodeId);
            if (!n) {
                throw new Error(`Не найдена нода - ${nodeId}, board - ${boardNode.id}`);
            }
            return n;
        });

        return NodeUtils.sortChildrenNodes(childrenArr);
    },
    sortChildrenNodes: (arr) =>
        [...arr].sort((node1, node2) => {
            // Сортировка по индексу.
            // const node1Name = node1.content.name;
            // const node2Name = node2.content.name;
            // if (elementRegExp.test(node1Name) && elementRegExp.test(node2Name)) {
            //     const match1 = node1Name.match(elementRegExp);
            //     const node1Number = parseInt(match1![1] || match1![4]);
            //     const match2 = node2Name.match(elementRegExp);
            //     const node2Number = parseInt(match2![1] || match2![4]);
            //     return node1Number - node2Number;
            // }
            // return -1;

            // Сортировка по координатам.
            return node1.content.absoluteBoundingBox.y - node2.content.absoluteBoundingBox.y
        }),
    sortBoardChildrenNodes: (arr) => {
        // Ноды первого уровня вложенности board.
        const firstLevelNodes = arr.filter((n) => groupNodeRegExp.test(n.content.name));
        // Ноды остальных уровней вложенности board.
        const otherLevelNodes = arr.filter((n) => !groupNodeRegExp.test(n.content.name));
        // Отсортированные ноды первого уровня вложенности board.
        const firstLevelNodesSorted = [...firstLevelNodes].sort((node1, node2) => {
            // Сортировка по индексу.
            // const node1Number = parseInt(node1.content.name.replace(groupNodeRegExp, '$1'));
            // const node2Number = parseInt(node2.content.name.replace(groupNodeRegExp, '$1'));
            // return node1Number - node2Number;

            // Сортировка по координатам.
            return node1.content.absoluteBoundingBox.y - node2.content.absoluteBoundingBox.y
        });

        return [...firstLevelNodesSorted, ...otherLevelNodes];
    },
};

export default NodeUtils;
