import React from 'react';
import {useTranslation} from "react-i18next";

export interface ISearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const SearchInput = React.forwardRef<HTMLInputElement, ISearchInputProps>((props, ref) => {
    const {t} = useTranslation();

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        (window as any).dataLayer &&
            (window as any).dataLayer.push({
                event: 'event-to-ga',
                eventCategory: 'FilterMenu',
                eventAction: 'blur',
                eventLabel: e.target.value,
            });

        if (props.onBlur) {
            props.onBlur(e);
        }
    };

    return <input type="text" placeholder={t('search placeholder') || undefined} className="search-input" {...props} onBlur={handleBlur} ref={ref} />;
});

SearchInput.displayName = 'SearchInput';

export default SearchInput;
