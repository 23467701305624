import React from 'react';
import {IStyleguideRoute} from '../../types/types';

interface IMenuLevelItemProps {
    route: IStyleguideRoute;
    onClickMenuItem: (route: IStyleguideRoute) => void;
}

/**
 * Вложенный пункт меню.
 */
export const MenuListItem: React.FC<IMenuLevelItemProps> = ({onClickMenuItem, route}) => (
    <li className="menu-list-item">
        <span className="menu-list-link" onClick={() => onClickMenuItem(route)}>{route.NAME}</span>
    </li>
);
