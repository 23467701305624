import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import './footer.scss';

const FOOTER_LINKS = {
    FIGMA: {
        LABEL: 'Figma',
        HREF: 'https://www.figma.com/files/844606278046881047/project/9393688/00-UIUX-Standart',
    },
    FIGMA_COMMUNITY: {
        LABEL: 'Figma Community',
        HREF: 'https://www.figma.com/@triplex',
    },
    CONFLUENCE: {
        LABEL: 'Confluence',
        HREF: 'https://sbtatlas.sigma.sbrf.ru/wiki/pages/viewpage.action?pageId=1569564009',
    },
    CONTACT: {
        LABEL: 'Triplex@sberbank.ru',
        HREF: 'Triplex@sberbank.ru',
    },
};

const Footer: React.FC = () => {
    const {t} = useTranslation();

    return (
        <footer className="footer">
            <div>
                <Trans i18nKey="inner page copyright" values={{dateEnd: new Date().getFullYear()}} />
                {' | '}
                <a target="_blank" rel="noreferrer" href={FOOTER_LINKS.CONFLUENCE.HREF}>
                    {FOOTER_LINKS.CONFLUENCE.LABEL}
                </a>
                {' | '}
                <a target="_blank" rel="noreferrer" href={FOOTER_LINKS.FIGMA_COMMUNITY.HREF}>
                    {FOOTER_LINKS.FIGMA_COMMUNITY.LABEL}
                </a>
            </div>
            <div>
                {t('footer contacts')}
                {' | '}
                <a href={`mailto:${FOOTER_LINKS.CONTACT.HREF}`}>{FOOTER_LINKS.CONTACT.LABEL}</a>
            </div>
        </footer>
    );
}

export default Footer;
