import React, {useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import clsx from 'clsx';
import {ISearchItem} from '../Search/Search';
import SearchResult from "../SearchResult/SearchResult";
import {Trans, useTranslation} from "react-i18next";
import './SearchPopup.scss';

interface ISearchResultProps {
    query: string;
    searchList: ISearchItem[];
}

const SearchPopup: React.FC<ISearchResultProps> = ({query, searchList}) => {
    const [faded, setFaded] = useState(false);
    const resultRef = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();

    // Флаг показа результатов поиска.
    const showSearchResult = !!searchList.length && query.length > 2;
    // Флаг показа сообщения о минимальном числе символов.
    const showCountMessage = query.length > 0 && query.length < 3;
    // Флаг показа сообщения 'Ничего не найдено'.
    const showNotFoundMessage = !searchList.length && query.length > 2;
    // Флаг скрытия поп-апа.
    const hidden = !showNotFoundMessage && !showSearchResult && !showCountMessage;

    const className = clsx('search-popup', {faded, hidden});

    useEffect(() => {
        setFadedState();
    }, [searchList]);

    useEffect(() => {
        window.addEventListener('resize', setFadedState);
        return () => window.removeEventListener('resize', setFadedState);
    }, []);

    const setFadedState = () => {
        const container = resultRef.current;

        // Если контейнер с результатом поиска переполнен и скроллбар находится не в конце.
        if (container && container.clientHeight + container.scrollTop < container.scrollHeight)
            !faded && setFaded(true);
        else
            faded && setFaded(false);
    }

    return (
        <div className={className}>
            {showSearchResult && <SearchResult onScroll={setFadedState} searchList={searchList} query={query} ref={resultRef} />}

            {showCountMessage && <div className="search-symbol-count">{t('search two characters')}</div>}

            {showNotFoundMessage && <div className="search-not-found"><Trans i18nKey="search not found" /></div>}
        </div>
    );
}

export default observer(SearchPopup);
