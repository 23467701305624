import {Node, NodeTypes} from 'figma-api';

export interface IStyleguideRoute {
    ID?: string;
    FIGMA_ID?: string;
    LANG_ID?: string;
    NAME: string;
    PATH: string;
    FULL_PATH: string;
    CHILDREN?: IStyleguideRoute[];
}

export interface IFigmaNodeBase {
    id: string;
    name: string;
    visible?: boolean;
    // String для сопоставления с types из бекенда.
    type: NodeTypes | string;
}

export interface IFigmaNormalizedNode extends IFigmaNodeBase {
    absoluteBoundingBox: {
        height: number,
        width: number,
        x: number,
        y: number,
    }
    children: string[];
    characters?: string;
}

export type TFigmaNormalizedNodes = Record<string, IFigmaNormalizedNode> & {
    root?: string;
};

export interface IWIthId {
    id: string;
}

export interface IFile extends IWIthId {
    fileKey: string;
    versionInformation: {
        figmaId: string;
        created: Date;
        label: string;
        description: string;
        language: string;
        routeTitle: string;
    };
    route: string;
    boards?: string[];
}

export interface IRoute extends IWIthId {
    routes: IStyleguideRoute[];
}

export interface IBoard extends IWIthId {
    boardFigmaId: string;
    // FigmaId дочерних нод первого уровня вложенности.
    children: string[];
    name: string;
    // Все дочерние ноды любого уровня вложенности.
    nodes: INode[];
}

export interface INode extends IWIthId {
    nodeFigmaId: string;
    content: IFigmaNormalizedNode;
    image?: IImage;
}

export interface IImage extends IWIthId {
    imageBase64: string;
}

export enum EChannel {
    super = 'Super',
    web = 'Web',
    mobile = 'Mobile',
    desktop = 'Desktop',
}

/**
 * @prop {string} type Тип ссылки.
 * @prop {string} nodeID Указатель на ноду.
 * @prop {string} url Адрес ссылки.
 */
export interface IFigmaLink {
    type: string;
    nodeID: string;
    url: string;
}

export type TImageNode = INode & {
    content: Node<'FRAME'>;
    image: IImage;
};

export interface IInnerLink {
    // FigmaId Board, в котором находится targetNode, на которую ведет ссылка.
    boardFigmaId: string,
    // Нода, на которую ведет ссылка.
    targetNode: string,
    targetFigmaId: string,
}

/** Найденный результат поиска. В запросе возвращается массив таких результатов. */
export interface ISearchResultItem {
    nodes: INode[];
    board: IBoard;
}
