import React from 'react';

/**
 * Состояние компонента.
 *
 * @prop {string} id Уникальный идентификатор.
 * @prop {boolean} isSelected Выбранный таб.
 * @prop {string} label Текст таба.
 * @prop {Function} onSelect Обработчик выбора таба.
 */
export interface ITabItemProps {
    id: string;
    isSelected?: boolean;
    label: string;
    onSelect: (id: string) => void;
}

/** Компонент TabItem. */
const TabItem: React.SFC<ITabItemProps> = ({id, isSelected, label, onSelect}) => {
    const handleClick = () => {
        if (!isSelected) {
            onSelect(id);
        }
    };

    return (
        <button className={`${isSelected ? 'selected' : ''} tabItemInline tabItem`} onClick={handleClick} type="button">
            <span className="tabItemInner">{label}</span>
        </button>
    );
};

TabItem.displayName = 'TabItem';

export {TabItem};
