import React, {useContext, useEffect, useState, useRef} from 'react';
import {Route, RouteComponentProps, Switch, useLocation, useParams} from 'react-router-dom';
import {StoreContext} from '../StoreContext/StoreContext';
import {observer} from 'mobx-react';
import Layout from '../Layout/Layout';
import Menu from '../Menu/Menu';
import Board from '../Board/Board';
import ChannelSwitcher from '../ChannelSwitcher/ChannelSwitcher';
import NotFoundBoard from '../NotFoundBoard/NotFoundBoard';
import {SEARCH_QUERY_PARAM_TITLE} from "../Search/consts";
import Search from "../Search/Search";
import {useTranslation} from 'react-i18next';
import {EChannel, IFile} from "../../types/types";
import SectionNotTranslated from "../SectionNotTranslated/SectionNotTranslated";

interface IFileProps extends RouteComponentProps {}

const File: React.FC<IFileProps> = ({history}) => {
    const location = useLocation();
    const {fileTitle} = useParams<{ fileTitle: string }>();
    const [currentFile, setCurrentFile] = useState<IFile | undefined>();
    const {routesStore, filesStore} = useContext(StoreContext);
    const {i18n} = useTranslation();
    // Поисковый запрос.
    const searchQuery = useRef<string | null>(new URLSearchParams(location.search).get(SEARCH_QUERY_PARAM_TITLE));


    useEffect(() => {
        const file = filesStore.files.filter(f => {

            if (f.versionInformation.language) {
                return f.versionInformation.language.toLowerCase() === i18n.language.toLowerCase() && f.versionInformation.routeTitle === fileTitle
            }

            return f.versionInformation.routeTitle === fileTitle
        })[0];

        setCurrentFile(file);
    }, [fileTitle, i18n.language])

    const renderMenu = () => {
        if (!currentFile) {
            return <div />;
        }

        const routes = routesStore.routes[currentFile.route];
        if (routes) {
            return (
                <>
                    <div className="mobile-search">
                        <Search />
                    </div>

                    <ChannelSwitcher routeTitle={fileTitle}/>

                    <Menu location={location} menu={routes.routes}/>
                </>
            );
        }
    };

    // Обработчик изменения url. Удаляет query параметр 'query'. Он устанавливается при переходе на страницу из поиска. При переходе на следующую страницу - удаляется.
    const handleChangeLocation = (location: any) => {
        const queryParams = new URLSearchParams(location.search);
        const queryParam = queryParams.get(SEARCH_QUERY_PARAM_TITLE);

        // Существует предыдущее значение равное новому значению.
        if (searchQuery.current && queryParam === searchQuery.current) {
            // Удаление параметра.
            queryParams.delete(SEARCH_QUERY_PARAM_TITLE);
            history.replace({...location, search: queryParams.toString()})
        } else {
            searchQuery.current = queryParam;
        }
    }

    useEffect(() => {
        return history.listen(handleChangeLocation);
    }, []);

    // Без карты роутов отрисовать Board нельзя.
    if (!Object.keys(routesStore.routesAndBoardsMap).length) {
        return null;
    }

    return (
        <Layout fileTitle={fileTitle as EChannel} menu={renderMenu()}>
            <Switch>
                {Object.keys(routesStore.routesAndBoardsMap).map((route) => {
                    return (
                        <Route
                            // Добавлено для заглавных страниц раздела.
                            exact
                            key={route}
                            path={route}
                            render={(routeProps) => <Board {...routeProps} fileTitle={fileTitle as EChannel}
                                                           id={routesStore.routesAndBoardsMap[route].id}/>}
                        />
                    );
                })}

                {/* Роут страницы без перевода на текущем языке, но имеющей перевод на другуом языке. */}
                <Route path={`/${i18n.language}/${fileTitle}/sectionNotTranslated`} component={SectionNotTranslated}/>

                {/* 404. */}
                <Route path="*" component={NotFoundBoard}/>
            </Switch>
        </Layout>
    );
};

export default observer(File);
