import {makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {IImage} from '../types/types';

export interface IImagesStore {
    images: {
        [imageId: string]: string;
    };
    loadImage: (id: string) => void;
    loadImageSuccess: (imageId: string) => (image: IImage) => void;
}

class ImagesStore implements IImagesStore {
    images: {
        [imageId: string]: string;
    } = {};

    constructor() {
        makeAutoObservable(this);
    }

    loadImage = (id: string): void => {
        api.getImage(id)
            .then(this.loadImageSuccess(id));
    };

    loadImageSuccess = (imageId: string) => (image: IImage): void => {
        this.images[imageId] = image.imageBase64;
    };
}

export default ImagesStore;
