import {makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {ISearchResultItem} from '../types/types';
import {IRootStore} from "./RootStore";

export interface ISearchStore {
    // Идет запрос.
    isLoading: boolean;
    list: ISearchResultItem[];
    rootStore: IRootStore;
    getItems: (query: string) => void;
    clearList: () => void;
}

class SearchStore implements ISearchStore {
    isLoading = false;
    list: ISearchResultItem[] = [];
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    getItems = (query: string): void => {
        this.isLoading = true;
        api.getSearchResults(query)
            .then(this.handleRequestFulfilled, this.handleRequestRejected)
    };

    handleRequestFulfilled = (list: ISearchResultItem[]): void => {
        this.list = list;
        this.isLoading = false;
    };

    handleRequestRejected = (): void => {
        this.isLoading = false;
    };

    clearList = (): void => {
        this.list = [];
        if (this.isLoading) {
            api.cancelSearch();
            this.isLoading = false;
        }
    };
}

export default SearchStore;
