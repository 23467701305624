import React, {useContext, useEffect, useRef, useState} from 'react';
import {SearchIcon} from './components/SearchIcon';
import SearchInput, {ISearchInputProps} from './components/SearchInput';
import {ISearchResultItem} from "../../types/types";
import {StoreContext} from "../StoreContext/StoreContext";
import {observer} from 'mobx-react';
import SearchPopup from "../SearchPopup/SearchPopup";
import {SearchClear} from "./components/SearchClear";
import {useHistory} from "react-router-dom";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";
import './search.scss';

interface ISearchProps extends Pick<ISearchInputProps, 'value' | 'onChange'> {}

export interface ISearchItem extends ISearchResultItem {
    fullPath: string;
}

const Search: React.FC<ISearchProps> = ({value, onChange}) => {
    const {searchStore, routesStore} = useContext(StoreContext);
    const [query, setQuery] = useState('');
    const [searchItems, setSearchItems] = useState<ISearchItem[]>([]);
    // Инпут в фокусе.
    const [inputFocused, setInputFocused] = useState(false);
    // Popup показан.
    const [popupShown, setPopupShown] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const history = useHistory();
    const {i18n} = useTranslation();

    const debouncedSearch = React.useRef(
        debounce(async (query: string) => {
            searchStore.getItems(query);
        }, 500)
    ).current;

    useEffect(() => {
        searchStore.clearList();
        if (query.length > 2)
            debouncedSearch(query);
        else
            debouncedSearch.cancel();
    }, [query]);

    useEffect(() => {
        const searchItemNext: Array<ISearchItem | null> = searchStore.list.map(searchResultItem => {
            let fullPath = '';
            Object.keys(routesStore.routesAndBoardsMap).some(fp => {
                if (routesStore.routesAndBoardsMap[fp].figmaId === searchResultItem.board.boardFigmaId) {
                    fullPath = fp;
                    return true;
                }
            });

            // fullPath - /ru/Super/Principles/skeletons/web
            // langInPathMatch - /ru/
            const langInPathMatch = fullPath.match(/\/(..)\//);
            if (langInPathMatch && langInPathMatch[1] !== i18n.language) {
                // Результаты поиска на другом языке.
                return null;
            }

            return {
                fullPath,
                ...searchResultItem,
            }
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSearchItems(searchItemNext.filter(searchResultItem => searchResultItem !== null));
    }, [searchStore.list, routesStore.routesAndBoardsMap]);

    useEffect(() => {
        if (popupShown) {
            document.body.classList.add('search-overflow-hidden');
        } else {
            document.body.classList.remove('search-overflow-hidden');
        }

        return () => document.body.classList.remove('search-overflow-hidden');
    }, [popupShown])

    useEffect(() => {
        return history.listen(() => {
            // При смене url поп-ап сворачивается.
            setPopupShown(false);
        });
    }, []);

    useEffect(() => {
        if (inputFocused && query.length > 0) {
            setPopupShown(true)
        }
    }, [inputFocused, query]);

    const handleClickClearButton = () => {
        setQuery('');
        inputRef.current?.focus();
    }

    return (
        <div className="search">
            <SearchInput
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                ref={inputRef}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
            />

            <SearchIcon className="search-icon"/>

            {popupShown ? (
                <>
                    <div className="search-popup-overlay" onClick={() => setPopupShown(false)}/>
                    <SearchPopup searchList={searchItems} query={query}/>
                </>
            ) : null}

            {query ? <SearchClear onClick={handleClickClearButton}/> : null}
        </div>
    );
}

export default observer(Search);
