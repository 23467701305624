import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {NotFoundBoardIcon} from "./NotFoundBoardIcon";
import {Link} from 'react-router-dom';
import './notFoundBoard.scss';


const NotFoundBoard: React.FC = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className="not-found-board">
            <div className="not-found-board-icon-wrapper">
                <NotFoundBoardIcon/>
            </div>

            <div className="not-found-board-label">
                {t('page not found')}
            </div>

            <Link
                to={`/${i18n.language}`}
                className="not-found-board-link"
            >
                {t('page not found link')}
            </Link>
        </div>
    );
}

export default observer(NotFoundBoard);
