import React from 'react';

const FigmaIcon: React.FC = () => (
    <svg width="30.000000" height="30.000000" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip71147_88008">
                <rect id="Frame 9" rx="15.000000" width="30.000000" height="30.000000" fill="white" fillOpacity="0"/>
            </clipPath>
        </defs>
        <rect id="Frame 9" rx="15.000000" width="30.000000" height="30.000000" fill="#2E2F36" fillOpacity="1.000000"/>
        <g clipPath="url(#clip71147_88008)">
            <path id="Vector"
                  d="M17.81 12.38C16.08 12.38 14.68 13.78 14.68 15.51C14.68 17.24 16.09 18.64 17.81 18.64C19.54 18.64 20.94 17.24 20.94 15.51C20.94 13.78 19.54 12.38 17.81 12.38ZM17.32 13.44Q17.56 13.38 17.81 13.38Q18.06 13.38 18.29 13.43Q18.47 13.47 18.64 13.55Q18.81 13.62 18.97 13.72Q19.16 13.84 19.32 14Q19.48 14.16 19.6 14.35Q19.7 14.51 19.78 14.68Q19.85 14.84 19.89 15.02Q19.94 15.26 19.94 15.51Q19.94 15.76 19.89 15.99Q19.85 16.17 19.78 16.34Q19.7 16.51 19.6 16.67Q19.48 16.86 19.32 17.02Q19.16 17.18 18.97 17.3Q18.81 17.4 18.64 17.47Q18.48 17.54 18.31 17.58Q18.07 17.64 17.81 17.64Q17.57 17.64 17.34 17.59Q17.16 17.55 16.99 17.47Q16.82 17.4 16.66 17.3Q16.47 17.18 16.31 17.02Q16.14 16.85 16.02 16.66Q15.92 16.51 15.85 16.34Q15.77 16.16 15.73 15.98Q15.68 15.75 15.68 15.51Q15.68 15.26 15.73 15.03Q15.78 14.85 15.85 14.68Q15.92 14.51 16.02 14.35Q16.14 14.16 16.3 14Q16.47 13.84 16.65 13.72Q16.81 13.62 16.98 13.55Q17.15 13.48 17.32 13.44Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M12.13 17.5C10.39 17.5 9 18.9 9 20.63C9 22.36 10.39 23.76 12.13 23.76C13.86 23.76 15.26 22.35 15.26 20.63L15.26 17.5L12.13 17.5ZM14.26 18.5L12.13 18.5Q11.87 18.5 11.63 18.55Q11.46 18.59 11.3 18.66Q11.12 18.74 10.97 18.84Q10.78 18.96 10.62 19.12Q10.46 19.28 10.34 19.47Q10.24 19.62 10.16 19.8Q10.09 19.97 10.05 20.15Q10 20.38 10 20.63Q10 20.89 10.05 21.13Q10.09 21.3 10.16 21.46Q10.24 21.64 10.34 21.79Q10.46 21.98 10.62 22.13Q10.78 22.29 10.96 22.41Q11.12 22.52 11.29 22.59Q11.45 22.66 11.62 22.7Q11.87 22.76 12.13 22.76Q12.38 22.76 12.62 22.7Q12.79 22.66 12.96 22.59Q13.13 22.52 13.28 22.42Q13.47 22.3 13.63 22.13Q13.79 21.97 13.92 21.79Q14.02 21.63 14.09 21.45Q14.16 21.28 14.2 21.11Q14.26 20.87 14.26 20.63L14.26 18.5Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M17.81 11.38L15.68 11.38L15.68 7.12L17.81 7.12Q18.07 7.12 18.31 7.17Q18.48 7.21 18.64 7.28Q18.81 7.36 18.97 7.46Q19.16 7.58 19.32 7.74Q19.48 7.9 19.6 8.09Q19.7 8.25 19.78 8.42Q19.85 8.59 19.89 8.77Q19.94 9 19.94 9.25Q19.94 9.5 19.89 9.74Q19.85 9.91 19.78 10.08Q19.7 10.25 19.6 10.4Q19.48 10.59 19.32 10.76Q19.16 10.92 18.97 11.04Q18.81 11.14 18.64 11.21Q18.47 11.28 18.29 11.32Q18.06 11.38 17.81 11.38ZM15.68 6.12L17.81 6.12C19.54 6.12 20.94 7.52 20.94 9.25C20.94 10.98 19.54 12.38 17.81 12.38L14.68 12.38L14.68 6.12L15.68 6.12Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M12.13 12.39C10.39 12.39 9 10.98 9 9.26C9 7.52 10.39 6.12 12.13 6.12L15.26 6.12L15.26 12.39L12.13 12.39ZM14.26 11.39L12.13 11.39Q11.87 11.39 11.63 11.33Q11.46 11.29 11.3 11.22Q11.12 11.15 10.97 11.05Q10.78 10.92 10.62 10.76Q10.46 10.6 10.34 10.41Q10.24 10.26 10.16 10.08Q10.09 9.91 10.05 9.74Q10 9.5 10 9.26Q10 8.99 10.05 8.75Q10.09 8.58 10.16 8.42Q10.24 8.25 10.34 8.09Q10.46 7.9 10.62 7.75Q10.78 7.59 10.96 7.47Q11.12 7.37 11.29 7.29Q11.45 7.22 11.62 7.18Q11.87 7.12 12.13 7.12L14.26 7.12L14.26 11.39Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            <path id="Vector"
                  d="M12.13 18.07C10.39 18.07 9 16.67 9 14.94C9 13.22 10.39 11.81 12.13 11.81L15.26 11.81L15.26 18.07L14.26 18.07L12.13 18.07ZM14.26 17.07L12.13 17.07Q11.87 17.07 11.63 17.01Q11.46 16.97 11.3 16.9Q11.12 16.83 10.97 16.73Q10.78 16.61 10.62 16.45Q10.46 16.29 10.34 16.1Q10.24 15.94 10.16 15.77Q10.09 15.6 10.05 15.42Q10 15.19 10 14.94Q10 14.69 10.05 14.46Q10.09 14.28 10.16 14.11Q10.24 13.94 10.34 13.78Q10.46 13.6 10.62 13.43Q10.78 13.27 10.97 13.15Q11.12 13.05 11.3 12.98Q11.46 12.91 11.63 12.87Q11.87 12.81 12.13 12.81L14.26 12.81L14.26 17.07Z"
                  fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
        </g>
    </svg>

);

export default FigmaIcon;