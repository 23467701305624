import React from 'react';

const GitHubIcon: React.FC = () => (
    <svg width="30.000000" height="30.000000" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip71147_88006">
                <rect id="github-mark-white 1" width="16.000000" height="16.000000"
                      transform="translate(7.000000 7.000000)" fill="white" fillOpacity="0"/>
            </clipPath>
            <clipPath id="clip71147_88005">
                <rect id="Frame 504" rx="15.000000" width="30.000000" height="30.000000" fill="white" fillOpacity="0"/>
            </clipPath>
        </defs>
        <rect id="Frame 504" rx="15.000000" width="30.000000" height="30.000000" fill="#2E2F36"
              fillOpacity="1.000000"/>
        <g clipPath="url(#clip71147_88005)">
            <rect id="github-mark-white 1" width="16.000000" height="16.000000" transform="translate(7.000000 7.000000)"
                  fill="#FFFFFF" fillOpacity="0"/>
            <g clipPath="url(#clip71147_88006)">
                <path id="Vector"
                      d="M15 7C10.57 7 7 10.66 7 15.2C7 18.82 9.29 21.89 12.47 22.98C12.87 23.06 13.01 22.8 13.01 22.59C13.01 22.4 13 21.74 13 21.06C10.77 21.55 10.31 20.09 10.31 20.09C9.95 19.14 9.42 18.89 9.42 18.89C8.69 18.39 9.47 18.39 9.47 18.39C10.28 18.44 10.71 19.23 10.71 19.23C11.42 20.48 12.58 20.13 13.04 19.91C13.11 19.38 13.32 19.01 13.54 18.81C11.77 18.62 9.9 17.91 9.9 14.76C9.9 13.87 10.22 13.13 10.72 12.56C10.64 12.36 10.36 11.52 10.8 10.39C10.8 10.39 11.48 10.17 13 11.23C13.65 11.05 14.33 10.96 15 10.96C15.68 10.96 16.37 11.06 17 11.23C18.53 10.17 19.2 10.39 19.2 10.39C19.64 11.52 19.36 12.36 19.28 12.56C19.8 13.13 20.11 13.87 20.11 14.76C20.11 17.91 18.24 18.61 16.45 18.81C16.74 19.07 16.99 19.56 16.99 20.33C16.99 21.43 16.98 22.31 16.98 22.59C16.98 22.8 17.12 23.06 17.52 22.98C20.7 21.89 23 18.82 23 15.2C23.01 10.66 19.42 7 15 7Z"
                      fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
            </g>
        </g>
    </svg>
);

export default GitHubIcon;