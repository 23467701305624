import React, {useContext} from 'react';
import {EAppTheme, ThemeContext} from '../ThemeSwitcher/ThemeSwitcher';
import {DarkThemeIcon} from './DarkThemeIcon';
import {DefaultThemeIcon} from './DefaultThemeIcon';
import './themeControl.scss';

const ThemeControl: React.FC = () => {
    const themeContext = useContext(ThemeContext);

    const icon =
        themeContext.theme === EAppTheme.DEFAULT ? (
            <DefaultThemeIcon
                onClick={() => {
                    themeContext.changeTheme(EAppTheme.DARK);
                }}
            />
        ) : (
            <DarkThemeIcon
                onClick={() => {
                    themeContext.changeTheme(EAppTheme.DEFAULT);
                }}
            />
        );
    return <div className="themeIcon">{icon}</div>;
};

export default ThemeControl;
