import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';
import {ContentTpl} from '../../../utils/StyleguideUtils';
import NodeUtils from '../../../utils/NodeUtils';

interface IBoardNodeProps {
    boardNode: IBoard;
    children?: never;
}

/**
 * Компонент для отрисовки заголовка контентной области страницы.
 */
const BoardHeader: React.FC<IBoardNodeProps> = observer(({boardNode}) => {
    const [headerNode, setHeaderNode] = useState<INode>();

    useEffect(() => {
        const findHeaderNode = (node: INode) => {
            if (node.content.children) {
                if (node.content.visible === false) {
                    return;
                }
                const componentTitleMatch = node.content.name.match(ContentTpl);
                if (componentTitleMatch) {
                    const componentTitle = componentTitleMatch[3];

                    if (componentTitle === 'Header') {
                        setHeaderNode(node);
                        return;
                    }

                }

                NodeUtils.getChildrenNodes(boardNode, node).forEach(findHeaderNode)
            }
        }

        boardNode.children.forEach(nodeId => {
            const node = boardNode.nodes.filter(n => n.nodeFigmaId === nodeId)[0];
            findHeaderNode(node);
        })
    }, []);

    if (!headerNode) {
        return null;
    }

    return (
        <div className="board-grid board-header" id={headerNode.nodeFigmaId}>
            <div className="board-left-col board-header-main">
                <BoardNode boardNode={boardNode} node={headerNode} />
            </div>

            <div className="board-right-col" />
        </div>
    )
});

export default BoardHeader;
