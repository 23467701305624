import {makeAutoObservable} from 'mobx';
import {api} from '../api/api';
import {IBoard} from '../types/types';
import {IRootStore} from './RootStore';
import NodeUtils from '../utils/NodeUtils';

export interface IBoardsStore {
    boards: {
        [boardId: string]: IBoard;
    };
    isLoading: boolean;
    loadBoard: (id: string) => void;
    loadBoardSuccess: (boardId: string) => (board: IBoard) => void;
    rootStore: IRootStore;
}

class BoardsStore implements IBoardsStore {
    boards: {
        [boardId: string]: IBoard;
    } = {};
    isLoading = false;
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    loadBoard = (id: string): void => {
        this.isLoading = true;
        api.getBoard(id)
            .then(this.loadBoardSuccess(id))
            .catch(e => this.rootStore.errorsStore.addError(e));
    };

    loadBoardSuccess = (boardId: string) => (board: IBoard): void => {
        this.isLoading = false;
        board.nodes = NodeUtils.sortBoardChildrenNodes([...board.nodes]);
        this.boards[boardId] = board;
    };
}

export default BoardsStore;
