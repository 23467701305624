import isEmpty from 'lodash-es/isEmpty';
import React, {useState, useEffect, useRef} from 'react';
import Spinner from '../Spinner/Spinner';
import {IStyleguideRoute} from '../../types/types';
import {MenuList} from './MenuList';
import {MenuBackLink} from './MenuBackLink';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './menu.scss';

export type TMenuLevel = 1 | 2 | 3;

/**
 * Свойства компонента.
 *
 * @prop {IStyleguideRoute[]} location Пункты меню.
 * @prop {IStyleguideRoute[]} menu Пункты меню.
 */
interface IMenuProps {
    location: any;
    menu: IStyleguideRoute[];
}

const findParentRoute = (currentRoute: IStyleguideRoute, route: IStyleguideRoute): IStyleguideRoute | undefined => {
    let parentRoute;

    if (route.CHILDREN) {
        if (route.CHILDREN.some(r => r.FULL_PATH === currentRoute.FULL_PATH)) {
            parentRoute = route;
        } else {
            route.CHILDREN.forEach(r => {
                if (r.CHILDREN && findParentRoute(currentRoute, r)) {
                    parentRoute = findParentRoute(currentRoute, r);
                }
            })
        }
    }

    return parentRoute;
};

/** Компонент меню. */
const Menu: React.FC<IMenuProps> = ({location, menu}) => {
    const [currentMenuItem, setCurrentMenuItem] = useState<IStyleguideRoute | undefined>();
    const {i18n} = useTranslation();

    const history = useHistory();

    const backLinkRef = useRef<HTMLDivElement | null>(null);

    const handleClickMenu = (route?: IStyleguideRoute) => {
        setCurrentMenuItem(route);

        if (backLinkRef.current && backLinkRef.current.getBoundingClientRect().top < window.scrollY) {
            backLinkRef.current.scrollIntoView({behavior: "smooth"});
        }
    }

    useEffect(() => {
        // Устанавливает текущий активный пункт меню при смене урл.

        // Текущий роут не ведет ни на какую борду, типа: "/Web".
        // Если локализации нет, то число 2.
        const isEmptyBoard = !location.pathname.split('/')[3];

        if (isEmptyBoard) {
            // Главная страница раздела.
            setCurrentMenuItem(undefined);
            return;
        }

        // Возвращает роут по pathName.
        const findRoute = (pathName: string, route: IStyleguideRoute): IStyleguideRoute | undefined => {
            if (route.CHILDREN) {
                return route.CHILDREN.map((r) => findRoute(pathName, r)).filter(a => a)[0];
            } else if (route.FULL_PATH === pathName) {
                return route;
            }

            return undefined;
        }

        // Убирает первый слеш т.к. location.pathname начинается со '/'.
        const pathname = location.pathname.replace(`/${i18n.language}/`, '');
        // Текущий роут.
        const currentRoute = menu.map((route) => findRoute(pathname, route)).filter(a => a)[0];

        if (currentRoute) {
            let parentRoute;
            menu.forEach(m => {
                if (findParentRoute(currentRoute, m)) {
                    parentRoute = findParentRoute(currentRoute, m);
                }
            });

            if (parentRoute) {
                setCurrentMenuItem(parentRoute);
            }
        }
    }, [location, menu]);

    return (
        <div className={`styleguide-menu ${isEmpty(menu) ? 'styleguide-menu-loading' : ''}`}>
            {isEmpty(menu) && <Spinner/>}

            <MenuBackLink ref={backLinkRef} route={currentMenuItem} onClick={() => {
                if (currentMenuItem) {
                    let parentRoute;
                    menu.forEach(m => {
                        if (findParentRoute(currentMenuItem, m)) {
                            parentRoute = findParentRoute(currentMenuItem, m);
                        }
                    })

                    handleClickMenu(parentRoute);
                } else {
                    history.push(`/${i18n.language}`);
                }

            }}/>
            <MenuList menu={menu} route={currentMenuItem} onClickMenuItem={handleClickMenu}/>
        </div>
    );
};

export default Menu;
