import React, {useContext, useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {observer} from 'mobx-react';
import {StoreContext} from '../StoreContext/StoreContext';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';
import ErrorPage from '../ErrorPage/ErrorPage';
import {Lang} from "../Lang/Lang";
import {useHistory} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import './app.scss';

const App: React.FC = () => {
    const {errorsStore, filesStore, innerLinksStore, routesStore} = useContext(StoreContext);
    const history = useHistory();
    const {i18n} = useTranslation();

    useEffect(() => {
        filesStore.loadFiles();
        innerLinksStore.loadInnerLinks();

        history.listen((location) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ym(96774952, 'hit', location.pathname, {
                // title: 'Contact information',
            });
        });
    }, []);

    useEffect(() => {
        if (filesStore.isFilesLoaded) {
            filesStore.files.forEach(file => {
                routesStore.loadRoute(file.route, file.versionInformation.language);
            })
        }
    }, [filesStore.isFilesLoaded]);

    if (!filesStore.isFilesLoaded) {
        return null;
    }

    return (
        <div className="app">
            <ThemeSwitcher>
                {errorsStore.errors.length ? (
                    <Route path="*" component={ErrorPage}/>
                ) : (
                    <Switch>
                        <Route path="/:language" component={Lang}/>
                        <Redirect exact from="/" to={`/${i18n.language}`}/>
                    </Switch>
                )}
            </ThemeSwitcher>
        </div>
    );
};

export default observer(App);
