import {makeAutoObservable} from 'mobx';
import {IRootStore} from './RootStore';

export interface IError {
    message: string;
}

export interface IErrorsStore {
    errors: IError[];
    addError: (error: any) => void;
    rootStore: IRootStore;
}

class ErrorsStore implements IErrorsStore {
    errors: IError[] = [];
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
        this.rootStore = rootStore;

        makeAutoObservable(this);
    }

    addError(error: any): void {
        if (error && error.message) {
            this.errors.push({message: error.message});
        } else {
            this.errors.push({message: 'Неизвестная ошибка.'});
        }
    }
}

export default ErrorsStore;
