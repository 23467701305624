import React, {useContext} from 'react';
import {IFigmaLink} from '../../../types/types';
import {StoreContext} from '../../StoreContext/StoreContext';
import { Link } from 'react-router-dom';

/** Типы ссылки. */
export enum EFigmaLinkType {
    node = 'NODE',
    url = 'URL',
}
export interface IFigmaLinkProps extends React.HTMLAttributes<HTMLDivElement> {
    link: IFigmaLink;
}
export const FigmaLink: React.FC<IFigmaLinkProps> = ({children, link, style}) => {
    const {innerLinksStore, routesStore} = useContext(StoreContext);

    if (link.type === EFigmaLinkType.url) {
        return (
            <a href={link.url} className="board-link" style={style}>
                {children}
            </a>
        )
    }

    /**
     * Объект внутренней ссыли из БД, содержит информацию о борде, на который ссылается ссылка.
     */
    const innerLink = innerLinksStore.innerLinks.filter(l => l.targetFigmaId === link.nodeID)[0];
    if (innerLink) {
        /**
         * Роут, на который ведет ссылка.
         */
        let route;
        for (const key in routesStore.routesAndBoardsMap) {
            if (routesStore.routesAndBoardsMap[key].figmaId === innerLink.boardFigmaId) {
                route = key;
            }
        }

        if (route) {
            return (
                // Если ссылка ведет не на Board, а на ноду внутри, добавляется хеш с id ноды.
                <Link to={innerLink.boardFigmaId === innerLink.targetFigmaId ? route : `${route}#${innerLink.targetFigmaId}`} className="board-link" style={style}>
                    {children}
                </Link>
            )
        } else {
            console.error('Не найден роут для ссылки - ', link.nodeID);
        }

    } else {
        console.error(`Не найдена нода ссылки - ${children}, nodeID - ${link.nodeID}`);
    }

    /**
     * Возвращается только текст ссылки.
     */
    return <span>{children}</span>;
};

FigmaLink.displayName = 'FigmaLink';
