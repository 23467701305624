import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import {StoreContext} from '../StoreContext/StoreContext';
import Layout from '../Layout/Layout';
import './error-page.scss'

const ErrorPage: React.FC = () => {
    const {errorsStore} = useContext(StoreContext);

    return (
        <Layout>
            <div className="error-page-header">Произошла ошибка, попробуйте обновить страницу.</div>

            {errorsStore.errors.map((error, index) => <div className="error-page-error" key={index}>{error.message}</div>)}
        </Layout>
    );
};

export default observer(ErrorPage);
