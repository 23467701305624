import FilesStore, {IFilesStore} from './FilesStore';
import RoutesStore from './RoutesStore';
import BoardsStore, {IBoardsStore} from './BoardsStore';
import InnerLinksStore, {IInnerLinksStore} from './InnerLinksStore';
import ErrorsStore, {IErrorsStore} from './ErrorsStore';
import ImagesStore, {IImagesStore} from './ImagesStore';
import SearchStore , {ISearchStore} from './SearchStore';

export interface IRootStore {
    boardsStore: IBoardsStore;
    errorsStore: IErrorsStore;
    filesStore: IFilesStore;
    imagesStore: IImagesStore;
    innerLinksStore: IInnerLinksStore;
    routesStore: RoutesStore;
    searchStore: ISearchStore;
}

class RootStore implements IRootStore {
    boardsStore: IBoardsStore;
    errorsStore: IErrorsStore;
    filesStore: IFilesStore;
    imagesStore: IImagesStore;
    innerLinksStore: IInnerLinksStore;
    routesStore: RoutesStore;
    searchStore: ISearchStore;

    constructor() {
        this.boardsStore = new BoardsStore(this);
        this.errorsStore = new ErrorsStore(this);
        this.filesStore = new FilesStore(this);
        this.imagesStore = new ImagesStore();
        this.innerLinksStore = new InnerLinksStore(this);
        this.routesStore = new RoutesStore(this);
        this.searchStore = new SearchStore(this);
    }
}

export default RootStore;
