import React from 'react';
import MenuListLink from './MenuListLink';
import {IStyleguideRoute} from '../../types/types';
import {MenuListItem} from './MenuListItem';
import {channelMainPageFigmaIds} from '../../stores/consts';

interface IMenuLevelProps {
    menu: IStyleguideRoute[];
    route?: IStyleguideRoute;
    onClickMenuItem: (route?: IStyleguideRoute) => void;
}

/**
 * Список пунктов меню.
 */
export const MenuList: React.FC<IMenuLevelProps> = ({menu, onClickMenuItem, route}) => {
    const m = route && route.CHILDREN || menu;

    return (
        <ul className={`menu-list`}>
            {m.map((route) => {
                const {CHILDREN, FULL_PATH, NAME} = route;
                if (CHILDREN && CHILDREN.length) {
                    return (
                        <MenuListItem key={FULL_PATH} route={route} onClickMenuItem={onClickMenuItem} />
                    );
                }

                // Титульные страницы раздела в меню не выводятся.
                if (channelMainPageFigmaIds.includes(route.FIGMA_ID!)) {
                    return null;
                }
                return <MenuListLink key={FULL_PATH} path={FULL_PATH} label={NAME} />;
            })}
        </ul>

    );
}
