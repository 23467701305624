import React from 'react';
import {observer} from 'mobx-react';
import './developmentStatus.scss';

export enum EDevelopmentStatus {
    d = 'D',
    r = 'R',
    w = 'W',
}

interface IDevelopmentStatusProps {
    isShowTip?: boolean;
    children?: never;
    status: EDevelopmentStatus;
}

/**
 * Иконка статуса разработки.
 */
const DevelopmentStatus: React.FC<IDevelopmentStatusProps> = observer(({isShowTip, status}) => {
    let classNameStatus = 'platform';
    switch (status) {
        case EDevelopmentStatus.d:
            classNameStatus = 'design';
            break;
        case EDevelopmentStatus.w:
            classNameStatus = 'wip';
            break;
    }
    return (
        <span className={`development-status ${classNameStatus} ${isShowTip ? 'with-tip' : ''}`}>
            {status}

            {isShowTip ? (
                <span className="development-status-tip">
                    {
                        {
                            [EDevelopmentStatus.r]: 'Компонент или Правило разработаны и опубликованы в библиотеке.',
                            [EDevelopmentStatus.d]: 'Дизайн Компонента или Правило утверждены, но не переданы в разработку.',
                            [EDevelopmentStatus.w]: 'Компонент или Правило переданы в разработку или находятся в тестировании.',
                        }[status]
                    }
                </span>
            ) : null}
        </span>
    );
});

export default DevelopmentStatus;
