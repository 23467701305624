import React from 'react';
import {Link} from 'react-router-dom';
import './styles/ChannelButton.scss';

/**
 * @prop {JSX.Element} icon Компонент иконки.
 * @prop {string} label Текст кнопки.
 * @prop {string} pathname Относительная ссылка (для react-router).
 */
export interface IChannelButtonProps {
    icon: JSX.Element;
    label: string;
    pathname: string;
}

const ChannelButton = ({icon, label, pathname}: IChannelButtonProps): React.ReactElement => (
    <Link className="channel-button" to={(location: any) => ({...location, pathname})}>
        <div className="channel-icon-wrapper">{icon}</div>
        <div className="channel-label">{label}</div>
    </Link>
);

export default ChannelButton;