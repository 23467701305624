import React from 'react';
import {observer} from 'mobx-react';
import {ISearchItem} from '../Search/Search';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {SEARCH_QUERY_PARAM_TITLE} from "../Search/consts";
import './SearchResult.scss';

interface ISearchResultItemProps {
    item: ISearchItem;
    query: string;
}

// Количество найденных нод в одном результате поиска.
const MAX_NODES_COUNT = 1;

const SearchResultItem: React.FC<ISearchResultItemProps> = ({item, query}) => {
    const regex = new RegExp(`(${query})`, 'gi');
    const {t} = useTranslation();

    // item.fullPath - /ru/Super/Principles/skeletons/web
    // pathLabel - Super / Principles / skeletons / web
    let pathLabel = item.fullPath;
    // langInPathMatch - /ru/
    const langInPathMatch = item.fullPath.match(/\/(..)\//);
    if (langInPathMatch) {
        pathLabel = item.fullPath.replace(langInPathMatch[0], '').replace(/\//g, ' / ')
    }

    // Первый текстовый блок на странице с совпадением.
    const firstTextNode = item.nodes[0];
    const content = firstTextNode.content.characters?.replace(regex, '<span class="search-result-item-selection">$1</span>');

    const handleClickNavLink = (location: any) => {
        const queryParam =  new URLSearchParams(location.search);
        queryParam.set(SEARCH_QUERY_PARAM_TITLE, query)
        return { ...location, pathname: item.fullPath, hash: firstTextNode.nodeFigmaId, search: queryParam.toString()}
    }

    return (
        <NavLink
            to={handleClickNavLink}
            className="search-result-item-link"
            activeClassName="active"
            isActive={(match) => Boolean(match)}
        >
            <div className="search-result-item">
                <div className="search-result-item-header">
                    {pathLabel}
                    <span className="current-page-label">Текущая</span>
                </div>
                <div className="search-result-item-body" dangerouslySetInnerHTML={{
                    __html: content ?? ''
                }}>
                </div>
                {item.nodes.length > MAX_NODES_COUNT ? (
                    <div className="search-result-item-footer">{t('search result item nodes count pre')} {item.nodes.length - MAX_NODES_COUNT} {t('search result item nodes count', {
                        postProcess: 'interval',
                        count: item.nodes.length - MAX_NODES_COUNT
                    })} {t('search result item nodes count post')}</div>
                ) : null}
            </div>
        </NavLink>

    );
}

export default observer(SearchResultItem);
