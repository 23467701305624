import React, {useContext} from 'react';
import {RUInnerIcon} from "./RUInnerIcon";
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {RUMainIcon} from "./RUMainIcon";
import {ENMainIcon} from "./ENMainIcon";
import {ENInnerIcon} from "./ENInnerIcon";
import {StoreContext} from "../StoreContext/StoreContext";
import './languageControl.scss';

export enum EAppLanguage {
    en = 'en',
    ru = 'ru',
}

/**
 * Тема переключателей языка.
 */
export enum ELanguageControlTheme {
    // Для главной страницы.
    main,
    // Для внутренних страниц.
    inner
}

interface ILanguageControlProps {
    theme: ELanguageControlTheme;
}

const LanguageControl: React.FC<ILanguageControlProps> = ({theme}) => {
    const {routesStore} = useContext(StoreContext);
    const {i18n} = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const handleChangeLanguage = (nextLanguage: EAppLanguage) => () => {
        const prevLanguage = nextLanguage === EAppLanguage.en ? EAppLanguage.ru : EAppLanguage.en;

        // Id роута. Одинаковый для разных языковых версий одной и той же доски.
        let langId: string | undefined;
        // Роут текущей страницы на другом языке. Либо страница - Раздел еще не переведен.
        // По-умолчанию роут для главной страницы.
        let nextPath = `/${nextLanguage}`;
        // Роут, без языкового префикса.
        const pathWithoutLang = location.pathname.replace(`/${prevLanguage}`, '');

        // Текущая страница не главная, а где-то в канале.
        if (pathWithoutLang) {
            // Название канала - Web/Super/...
            const fileTitle = pathWithoutLang.split('/')[1];
            if (fileTitle) {
                nextPath = `/${nextLanguage}/${fileTitle}/sectionNotTranslated`
            }
        }

        Object.keys(routesStore.routesAndBoardsMap).filter(path => path.startsWith(`/${prevLanguage}/`)).some(path => {
            if (path === location.pathname) {
                langId = routesStore.routesAndBoardsMap[path].routeLangId;
                return true;
            }
        });

        if (langId) {
            Object.keys(routesStore.routesAndBoardsMap).filter(path => path.startsWith(`/${nextLanguage}/`)).some(path => {
                if (routesStore.routesAndBoardsMap[path].routeLangId === langId) {
                    nextPath = path;
                    return true;
                }
            });
        }

        i18n.changeLanguage(nextLanguage);

        history.push({
            pathname: nextPath,
            hash: location.hash,
            search: location.search,
        });
    }

    return (
        <div className={ theme === ELanguageControlTheme.inner ? 'language-control-inner' : 'language-control-main'}>
            <button
                className="language-control-button"
                disabled={i18n.language === EAppLanguage.ru}
                onClick={handleChangeLanguage(EAppLanguage.ru)}
            >
                {theme === ELanguageControlTheme.inner ? <RUInnerIcon className="language-control-icon"/> : <RUMainIcon className="language-control-icon"/>}
            </button>

            <button
                className="language-control-button"
                disabled={i18n.language === EAppLanguage.en}
                onClick={handleChangeLanguage(EAppLanguage.en)}
            >
                {theme === ELanguageControlTheme.inner ? <ENInnerIcon className="language-control-icon"/> : <ENMainIcon className="language-control-icon"/>}
            </button>
        </div>
    );
};

export default LanguageControl;
