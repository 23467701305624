import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {Helmet} from 'react-helmet';
import {EChannel, IBoard} from '../../types/types';
import {StoreContext} from '../StoreContext/StoreContext';
import BoardHeader from './components/BoardHeader';
import BoardWarning from './components/BoardWarning';
import {ContentTpl} from '../../utils/StyleguideUtils';
import BoardCol from './components/BoardCol';
import BoardContent from './components/BoardContent';
import {ScrollToAnchor} from '../ScrollToAnchor/ScrollToAnchor';
import BoardContents from './components/BoardContents';
import TriplexResources from "../TriplexResources/TriplexResources";
import './board.scss';

interface IBoardProps {
    // Название канала Web/Mob.
    fileTitle: EChannel;
    id: string;
}

const Board: React.FC<IBoardProps> = ({fileTitle, id}) => {
    const {boardsStore, routesStore} = useContext(StoreContext);
    const [board, setBoard] = useState<IBoard | undefined>();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const currentBoard = boardsStore.boards[id];

        if (currentBoard) {
            setBoard(currentBoard);
        } else {
            boardsStore.loadBoard(id);
        }
    }, []);

    // Board загрузился.
    useEffect(() => {
        if (!boardsStore.isLoading) {
            const currentBoard = boardsStore.boards[id];
            if (currentBoard) {
                setBoard(currentBoard);
            }
        }
    }, [boardsStore.isLoading]);

    if (!board) {
        return null;
    }

    return (
        <div className="board">

            <Helmet>
                <meta property="og:url" content={document.location.href} />
                <meta property="og:type"               content="article" />
            </Helmet>

            <BoardHeader boardNode={board} />

            <div className="board-grid">
                <div className="board-left-col">
                    {board.nodes
                        // Отсеивание не дочерних нод.
                        .filter((n) => board.children.includes(n.nodeFigmaId))
                        .map((node) => {
                            if (!node.content.visible === false) {
                                return null;
                            }
                            const componentTitleMatch = node.content.name.match(ContentTpl);
                            if (componentTitleMatch) {
                                const componentTitle = componentTitleMatch[3];
                                switch (componentTitle) {
                                    // TODO удалить, BoardCol лежит только внутри BoardContent.
                                    case 'Col-':
                                        return <BoardCol key={node.nodeFigmaId} boardNode={board} node={node} />;
                                    case 'Content':
                                    // case 'Lead': // Lead больше не существует
                                        return <BoardContent key={node.nodeFigmaId} boardNode={board} node={node} />;
                                    case 'Warning':
                                        return <BoardWarning key={node.nodeFigmaId} boardNode={board} node={node} />;
                                }
                            }
                            return null;
                        })
                    }
                </div>

                <div className="board-right-col">
                    <div className="sticky-sidebar">
                        <TriplexResources boardNode={board} fileTitle={fileTitle} />

                        <BoardContents boardNode={board}/>
                    </div>
                </div>
            </div>

            <ScrollToAnchor />
        </div>
    );
};

export default observer(Board);
