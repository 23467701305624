import React from 'react';
import {observer} from 'mobx-react';
import {IBoard, INode} from '../../../types/types';
import BoardNode from './BoardNode';
import NodeUtils from '../../../utils/NodeUtils';

export enum EBoardSubHeaderType {
    LARGE,
    MEDIUM
}

interface IBoardSubHeaderProps {
    type: EBoardSubHeaderType;
    boardNode: IBoard;
    node: INode;
    children?: never;
}

const BoardSubHeader: React.FC<IBoardSubHeaderProps> = observer(({boardNode, node, type}) => (
    <div className={`board-sub-header ${type === EBoardSubHeaderType.LARGE ? 'board-sub-header-large' : 'board-sub-header-medium'}`} id={node.nodeFigmaId}>
        {NodeUtils.getChildrenNodes(boardNode, node).map((k) => (
            <BoardNode key={k.id} boardNode={boardNode} node={k} />
        ))}
    </div>
));

export default BoardSubHeader;
