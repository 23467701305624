import React from 'react';

const DemoUIIcon: React.FC = () => (
    <svg width="30.000000" height="30.000000" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath id="clip71147_88001">
                <rect id="Frame 10" rx="15.000000" width="30.000000" height="30.000000" fill="white" fillOpacity="0"/>
            </clipPath>
        </defs>
        <rect id="Frame 10" rx="15.000000" width="30.000000" height="30.000000" fill="#2E2F36" fillOpacity="1.000000"/>
        <g clipPath="url(#clip71147_88001)">
            <path id="Vector 410" d="M17.45 21L21.45 15L17.45 9" stroke="#FFFFFF" strokeOpacity="1.000000"
                  strokeWidth="1.000000" strokeLinecap="round"/>
            <path id="Vector 411" d="M12.45 9L8.45 15L12.45 21" stroke="#FFFFFF" strokeOpacity="1.000000"
                  strokeWidth="1.000000" strokeLinecap="round"/>
        </g>
    </svg>
);

export default DemoUIIcon;